import type React from "react";

import { useTranslation } from "react-i18next";

import { Tag } from ".";

/* DISPLAY_NAME */
const DISPLAY_NAME = "Nid.RequiredTag";

type RequiredTagProps = {
  on?: boolean;
};

const RequiredTag: React.FC<RequiredTagProps> = ({ on = true }) => {
  const { t } = useTranslation();
  return on ? (
    <Tag variant="error" text={t("label.required")} />
  ) : (
    <Tag variant="default" text={t("label.option")} />
  );
};

RequiredTag.displayName = DISPLAY_NAME;

const Root = RequiredTag;

export { RequiredTag, Root };
export type { RequiredTagProps };
export default RequiredTag;
