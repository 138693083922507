import * as React from "react";

import { type OptionItem, Select, Typography } from "nikkei-ui";
import { useTranslation } from "react-i18next";
import RequiredTag from "../../Tag/RequiredTag";

const DISPLAY_NAME = "Nid.Form.IncomeField";

type IncomeFieldProps = {
  testId?: string;
  option: OptionItem[];
} & React.ComponentPropsWithRef<"select">;

const IncomeField = React.forwardRef<HTMLSelectElement, IncomeFieldProps>(
  ({ name = "incomeNo", onChange, option }, ref) => {
    const { t } = useTranslation();
    return (
      <>
        <fieldset className="nid-account-edit-fieldset">
          <Typography as="legend" variant="body" bold className="mb-3">
            <label htmlFor="select-income-no">{t("attributes.income")}</label>
            <RequiredTag on={false} />
          </Typography>
          <Select
            name={name}
            options={option}
            id="select-income-no"
            data-testid="select-income-no"
            containerclassname="w-full mb-2"
            onChange={onChange}
            ref={ref}
          />
        </fieldset>
      </>
    );
  },
);

IncomeField.displayName = DISPLAY_NAME;
const Root = IncomeField;
export { Root, IncomeField };
export type { IncomeFieldProps };
export default IncomeField;
