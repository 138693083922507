import useSWRImmutable from "swr/immutable";

import { parseToNidDate } from "nid-common";
import { type UserInfoResponse, getUserInfo } from "nid-common/api/account";
import { useUserInfoLabel } from "./useUserInfoLabel";

const undefinedIfEmpty = (value: string | undefined): string | undefined => {
  if (value === "" || value === undefined) {
    return undefined;
  }
  return value;
};

const buildUserInfo = (userInfo: UserInfoResponse) => {
  return {
    email: undefinedIfEmpty(userInfo.EMAIL),
    lastName: undefinedIfEmpty(userInfo.LAST_NAME),
    firstName: undefinedIfEmpty(userInfo.FIRST_NAME),
    lastNameKana: undefinedIfEmpty(userInfo.LAST_NAME_KANA),
    firstNameKana: undefinedIfEmpty(userInfo.FIRST_NAME_KANA),
    birth: userInfo.BIRTH ? parseToNidDate(userInfo.BIRTH) : undefined,
    sex: userInfo.SEX !== "0" ? undefinedIfEmpty(userInfo.SEX) : undefined,
    residenceCountryFlag: userInfo.RESIDENCE_COUNTRY_FLAG,
    domesticAddress:
      userInfo.RESIDENCE_COUNTRY_FLAG === "0" ||
      userInfo.RESIDENCE_COUNTRY_FLAG === undefined
        ? {
            zipCode: undefinedIfEmpty(userInfo.ZIP_CODE),
            addressCode: undefinedIfEmpty(userInfo.ADDRESS_CODE),
            addressCodeMei: undefinedIfEmpty(userInfo.ADDRESS_CODE_MEI),
            address1: undefinedIfEmpty(userInfo.ADDRESS1),
            address2: undefinedIfEmpty(userInfo.ADDRESS2),
            tel: undefinedIfEmpty(userInfo.TEL_JOINT),
          }
        : undefined,
    overseasAddress:
      userInfo.RESIDENCE_COUNTRY_FLAG === "1"
        ? {
            countryCode: undefinedIfEmpty(userInfo.RESIDENCE_COUNTRY_CODE),
            countryName: undefinedIfEmpty(userInfo.RESIDENCE_COUNTRY_NAME),
            zipCode: undefinedIfEmpty(userInfo.OVERSEAS_ZIP_CODE),
            address1: undefinedIfEmpty(userInfo.OVERSEAS_ADDRESS1),
            address2: undefinedIfEmpty(userInfo.OVERSEAS_ADDRESS2),
            address3: undefinedIfEmpty(userInfo.OVERSEAS_ADDRESS3),
            tel: undefinedIfEmpty(userInfo.OVERSEAS_TEL),
          }
        : undefined,
    occupation:
      userInfo.OCCUPATION_NO !== "000"
        ? undefinedIfEmpty(userInfo.OCCUPATION_NO)
        : undefined,
    business: undefinedIfEmpty(userInfo.BUSINESS_NO),
    job: undefinedIfEmpty(userInfo.JOB_NO),
    position: undefinedIfEmpty(userInfo.POSITION_NO),
    employees: undefinedIfEmpty(userInfo.EMPLOYEES_NO),
    income: undefinedIfEmpty(userInfo.INCOME_NO),
    companyCountryFlag:
      userInfo.COMPANY_COUNTRY_FLAG !== "9"
        ? undefinedIfEmpty(userInfo.COMPANY_COUNTRY_FLAG)
        : undefined,
    domesticCompany:
      userInfo.COMPANY_COUNTRY_FLAG === "0" ||
      userInfo.COMPANY_COUNTRY_FLAG === "8"
        ? {
            name: undefinedIfEmpty(userInfo.COMPANY_NAME),
            number: undefinedIfEmpty(userInfo.COMPANY_NUMBER),
            typeCode: undefinedIfEmpty(userInfo.COMPANY_TYPE_CODE),
            typeLocation: undefinedIfEmpty(userInfo.COMPANY_TYPE_LOCATION),
            zipCode: undefinedIfEmpty(userInfo.COMPANY_ZIP_CODE),
            address: undefinedIfEmpty(userInfo.COMPANY_ADDRESS),
            businessUnit: undefinedIfEmpty(userInfo.COMPANY_BUSINESS_UNIT),
            tel: undefinedIfEmpty(userInfo.COMPANY_TEL_JOINT),
          }
        : undefined,
    overseasCompany:
      userInfo.COMPANY_COUNTRY_FLAG === "1" ||
      userInfo.COMPANY_COUNTRY_FLAG === "8"
        ? {
            name: undefinedIfEmpty(userInfo.COMPANY_OVERSEAS_NAME),
            address: undefinedIfEmpty(userInfo.COMPANY_OVERSEAS_ADDRESS),
            tel: undefinedIfEmpty(userInfo.COMPANY_OVERSEAS_TEL),
          }
        : undefined,

    receiveNikkeiMail: userInfo.NIKKEI_MAIL_FLAG === "1",
    receiveThirdPartyMail: userInfo.THIRDPARTY_MAIL_FLAG === "1",
    registerNikkeiMonitor: userInfo.NIKKEI_MONITOR_FLAG === "1",
    newsSubscriptions: {
      newsSubscription1: userInfo.NEWS_SUBSCRIPTION?.NEWS_SUBSCRIPTION1 === "1",
      newsSubscription3: userInfo.NEWS_SUBSCRIPTION?.NEWS_SUBSCRIPTION3 === "1",
      newsSubscription4: userInfo.NEWS_SUBSCRIPTION?.NEWS_SUBSCRIPTION4 === "1",
    },
    interest: {
      interest1: userInfo.INTEREST?.INTEREST1 === "1",
      interest2: userInfo.INTEREST?.INTEREST2 === "1",
      interest3: userInfo.INTEREST?.INTEREST3 === "1",
      interest4: userInfo.INTEREST?.INTEREST4 === "1",
      interest5: userInfo.INTEREST?.INTEREST5 === "1",
      interest6: userInfo.INTEREST?.INTEREST6 === "1",
      interest7: userInfo.INTEREST?.INTEREST7 === "1",
      interest8: userInfo.INTEREST?.INTEREST8 === "1",
      interest9: userInfo.INTEREST?.INTEREST9 === "1",
      interest10: userInfo.INTEREST?.INTEREST10 === "1",
      interest11: userInfo.INTEREST?.INTEREST11 === "1",
      interest12: userInfo.INTEREST?.INTEREST12 === "1",
      interest13: userInfo.INTEREST?.INTEREST13 === "1",
    },
  };
};

export type UserInfo = ReturnType<typeof buildUserInfo>;

export type LabeledUserInfo = ReturnType<typeof addLabel>;

const addLabel = (
  userInfo: UserInfo,
  f: ReturnType<typeof useUserInfoLabel>,
) => {
  return {
    ...userInfo,
    displayName:
      userInfo.lastName && userInfo.firstName
        ? `${userInfo.lastName}　${userInfo.firstName}`
        : userInfo.lastName || userInfo.firstName,
    displayNameKana:
      userInfo.lastNameKana && userInfo.firstNameKana
        ? `${userInfo.lastNameKana}　${userInfo.firstNameKana}`
        : userInfo.lastNameKana || userInfo.firstNameKana,
    displayBirth: userInfo.birth ? f.getBirthLabel(userInfo.birth) : undefined,
    displayDomesticCompanyName: (() => {
      const companyName = userInfo.domesticCompany?.name || "";
      if (
        userInfo.domesticCompany?.typeCode &&
        userInfo.domesticCompany?.typeLocation === "1"
      ) {
        return `${f.getCompanyTypeLabel(userInfo.domesticCompany?.typeCode)}${companyName}`;
      }
      if (
        userInfo.domesticCompany?.typeCode &&
        userInfo.domesticCompany?.typeLocation === "2"
      ) {
        return `${companyName}${f.getCompanyTypeLabel(userInfo.domesticCompany?.typeCode)}`;
      }
      return companyName;
    })(),
    sexLabel: userInfo.sex ? f.getSexLabel(userInfo.sex) : undefined,
    occupationLabel: userInfo.occupation
      ? f.getOccupationLabel(userInfo.occupation)
      : undefined,
    businessLabel: userInfo.business
      ? f.getBusinessLabel(userInfo.business)
      : undefined,
    jobLabel: userInfo.job ? f.getJobLabel(userInfo.job) : undefined,
    positionLabel: userInfo.position
      ? f.getPositionLabel(userInfo.position)
      : undefined,
    employeesLabel: userInfo.employees
      ? f.getEmployeesLabel(userInfo.employees)
      : undefined,
    incomeLabel: userInfo.income
      ? f.getIncomeLabel(userInfo.income)
      : undefined,
  };
};

export const useUserInfo = () => {
  const f = useUserInfoLabel();
  const { data, error, mutate } = useSWRImmutable(
    "/account/userinfo",
    getUserInfo,
  );

  if (error) return { status: "error", error, mutate } as const;
  if (!data) return { status: "loading", error, mutate } as const;
  const historyId = data.headers.etag as string;

  return {
    status: "ok",
    userInfo: addLabel(buildUserInfo(data.data), f),
    mutate,
    historyId,
  } as const;
};
