import type * as React from "react";

import { SecurityMfaTopFeature } from "@/features/Security/Mfa/Top/SecurityMfaTopFeature";
import { LoginWallProvider } from "nid-common";

const SecurityMfaTopPage: React.FC = () => {
  return (
    <LoginWallProvider maxAge={600}>
      <SecurityMfaTopFeature />
    </LoginWallProvider>
  );
};

export default SecurityMfaTopPage;
