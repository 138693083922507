import noImage from "@/components/icon/NoImage.svg";
import { Box, Typography } from "nikkei-ui";
import { useTranslation } from "react-i18next";
import styles from "./Profile.module.css";

interface ProfileProps {
  userName?: string;
  loginId?: string;
}

export const Profile: React.FC<ProfileProps> = ({ userName, loginId }) => {
  const { t } = useTranslation();
  return (
    <Box className={styles.profile} data-testid="profile">
      <img
        src={noImage}
        height={"100%"}
        alt={"Profile Icon"}
        data-testid={"profile-avatar"}
        className={styles.avatar}
      />
      <Box className={styles.textContainer}>
        {userName && loginId ? (
          <>
            <Typography
              variant="h3"
              bold
              className={styles.userName}
              data-testid="profile-user-name"
            >
              {userName} {t("account_top.profile.honorific_title")}
            </Typography>
            <Typography
              variant="body2"
              className={styles.loginId}
              data-testid="profile-loginid"
            >
              {loginId}
            </Typography>
          </>
        ) : loginId ? (
          <Typography
            variant="h3"
            bold
            className={styles.userName}
            data-testid="profile-loginid"
          >
            {loginId}
          </Typography>
        ) : undefined}
      </Box>
    </Box>
  );
};
