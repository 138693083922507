import type React from "react";

import { useTranslation } from "react-i18next";

import ArticleTitle from "@/components/ui/ArticleTitle/ArticleTitle";
import { SecurityMfaAuthenticatorFeature } from "@/features/Security/Mfa/Authenticator/SecurityMfaAuthenticatorFeature";
import { Typography } from "nikkei-ui";

const SecurityMfaAuthenticatorPage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <ArticleTitle
        title={t("security.mfa.authenticator.title")}
        testId="article-security-mfa-authenticator"
      >
        <Typography as="p" className="nid-article-description">
          {t("security.mfa.authenticator.description")}
        </Typography>
      </ArticleTitle>
      <SecurityMfaAuthenticatorFeature />
    </>
  );
};

export default SecurityMfaAuthenticatorPage;
