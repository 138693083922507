import type React from "react";

import {
  Box,
  ButtonBase as Button,
  FormControl,
  Link,
  Typography,
} from "nikkei-ui";

import { useTranslation } from "react-i18next";

import { CompanyCountryField } from "@/components/ui/Form/CompanyCountryField/CompanyCountryField";
import { OverseasCompanyField } from "@/components/ui/Form/CompanyField";
import DomesticCompanyField from "@/components/ui/Form/CompanyField/DomesticCompanyField";
import { usePersonalOfficeInputFeature } from "@/features/Personal/Office/hooks/usePersonalOfficeInputFeature";
import { path } from "@/routes";
import { ContentSkeletonLoader } from "nid-common";

export const PersonalOfficeInputFeature: React.FC = () => {
  const { t } = useTranslation();
  const {
    status,
    registers,
    handleSubmit,
    errors,
    buttonEnabled,
    companyCountryFlag,
    companyTypes,
    companyAutoCompleteList,
    onCompanyClick,
    onCompanyNameAutoCompleteSelect,
  } = usePersonalOfficeInputFeature();
  if (status !== "ok") return <ContentSkeletonLoader />;

  return (
    <Box as="section" className="nid-section-without-background">
      <Box className="nid-section-inner">
        <form onSubmit={handleSubmit} noValidate>
          <CompanyCountryField {...registers.companyCountryFlag} />
          {companyCountryFlag === "domestic" && (
            <DomesticCompanyField
              companyTypes={companyTypes.companyTypes}
              errors={errors.domesticCompany}
              registers={registers.domesticCompany}
              companyAutoCompleteList={companyAutoCompleteList}
              onCompanyClick={onCompanyClick}
              onCompanyNameAutoCompleteSelect={onCompanyNameAutoCompleteSelect}
            />
          )}
          {companyCountryFlag === "overseas" && (
            <OverseasCompanyField
              errors={errors.overseasCompany}
              registers={registers.overseasCompany}
            />
          )}

          <FormControl.Validation
            className="nid-error-field"
            status={errors?.api ? "error" : undefined}
            data-testid="error-api"
          >
            {errors?.api?.message}
          </FormControl.Validation>

          <Button size="full" data-testid="submit" disabled={!buttonEnabled}>
            {t("personal.edit.button.confirm")}
          </Button>
        </form>
        <Box className="nid-item-center">
          <Link to={path.personal.root} className="nid-button-back">
            <Typography color="link">
              {t("personal.edit.button.cancel")}
            </Typography>
          </Link>
        </Box>
      </Box>
    </Box>
  );
};
