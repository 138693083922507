import {
  convertCC_EXP,
  convertFullWidthDigits,
  convertHtml5CC_EXP,
  extractDigitsAndSlash,
  formatMonth,
  formatMonthWithSlash,
  hasSlash,
  isNotDigitsAndSlash,
  removeSlashes,
  removeSpace,
  splitMonthYear,
} from "./common";

/**
 * removeSpaceAndSplitMonthYear
 * 空白を削除 + スラッシュを基準に月と年に分ける
 * @param value string
 * @returns { month: string, year: string }
 */
export const removeSpaceAndSplitMonthYear = (
  value: string,
): { month: string; year: string } => {
  const cleanedValue = removeSpace(value);
  return splitMonthYear(cleanedValue);
};
/**
 * addLeadingZeroAndRemoveSlash
 * @param value
 * @returns string
 */
export const addLeadingZeroAndRemoveSlash = (value: string): string => {
  if (value.endsWith("/") && value.startsWith("0")) {
    // このケースは受けつけない
    return "0";
  }
  if (value.endsWith("/")) {
    const strippedValue = value.slice(0, -1);
    return `0${strippedValue} / `;
  }
  return value;
};

/**
 * 年がある場合のフォーマット
 * @param month string
 * @param year string
 * @returns string
 */
export const formatWithYear = (month: string, year: string): string => {
  if (hasSlash(year)) return `${month} / ${removeSlashes(year)}`;

  if (month.length === 1 && year.length === 2 && !["0", "1"].includes(month)) {
    return `${formatMonth(month)} / ${year}`;
  }

  if (month.length === 1 && year.length === 2) {
    // monthが1桁,yearが2桁の場合は、monthのみを返す
    return `${month}`;
  }

  if (year.length > 2) {
    // yearが2桁より大、後ろ2文字をYY形式
    return `${month} / ${year.slice(-2)}`;
  }

  const monthNumber = Number.parseInt(month, 10);

  // monthが10, 11, 12の場合はそのまま返す
  if (monthNumber >= 10 && monthNumber <= 12) {
    return `${monthNumber} / ${year}`;
  }
  if (Number.parseInt(month, 10) > 12) {
    // monthが12より大, 0 + monthの後ろ1文字
    return `${formatMonth(month.slice(-1))} / ${year}`;
  }

  return `${month} / ${year}`;
};

/**
 * 年がない場合のフォーマット
 * @param month string
 * @param digits string
 * @returns string
 */
export const formatWithoutYear = (month: string, digits: string): string => {
  if (month.length === 2) {
    if (Number.parseInt(month, 10) > 12) {
      // monthが12より大, 0 + monthの後ろ1文字
      return `${formatMonth(month.slice(-1))} / `;
    }
    if (month === "00") {
      return "0";
    }
    if (hasSlash(digits)) {
      return addLeadingZeroAndRemoveSlash(month);
    }
    return `${month} / `;
  }

  return convertCC_EXP(digits);
};

/**
 * formatExpire
 * @param value string
 * @returns string
 */
export const formatExpire = (value: string): string => {
  // このケースはうけつけない
  if (value.length === 1 && hasSlash(value)) return "";
  let digits = convertFullWidthDigits(value);
  if (isNotDigitsAndSlash(digits)) {
    // HTML5のCC_EXP形式から変換
    digits = convertHtml5CC_EXP(digits);
    digits = extractDigitsAndSlash(digits);
  }

  if (digits.length === 1) {
    if (!["0", "1"].includes(digits)) {
      return formatMonthWithSlash(digits);
    }
    return digits;
  }

  if (digits.length === 2) {
    if (digits === "00") {
      return "0";
    }
    if (hasSlash(digits)) {
      return addLeadingZeroAndRemoveSlash(digits);
    }
  }

  // スラッシュを基準に月と年に分割
  const { month, year } = removeSpaceAndSplitMonthYear(digits);

  if (!month && !year) {
    return "";
  }

  if (year) {
    return formatWithYear(month, year);
  }

  return formatWithoutYear(month, digits);
};

/**
 * formatAutoFillExpire
 * @param value
 * @returns MM/YY 形式に変換した値
 */
export const formatAutoFillExpire = (value: string): string => {
  const formattedHtml5Value = convertHtml5CC_EXP(value);
  // MM-YYYY、MM/YYYY、MM-YY 形式を MM/YY 形式に変換
  return convertCC_EXP(formattedHtml5Value);
};

/**
 * formatValidateExpire
 * @param value
 * @returns
 */
export const formatValidateExpire = (value: string) => {
  let digits = convertFullWidthDigits(value);

  if (isNotDigitsAndSlash(digits)) {
    digits = extractDigitsAndSlash(digits);
  }
  const { month, year } = removeSpaceAndSplitMonthYear(digits);
  return { month, year };
};
