import ArticleTitle from "@/components/ui/ArticleTitle/ArticleTitle";
import { Layout } from "@/components/ui/Layout";
import styles from "@/pages/Payments/index.module.css";
import { path } from "@/routes";
import { Box, Link, LinkButton, Typography } from "nikkei-ui";
import type React from "react";
import { useTranslation } from "react-i18next";

export const NkdkMaintenancePage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Layout
      title={t("breadcrumbs.payment_maintenance")}
      breadcrumbs={
        <>
          <Link to={path.root} variant="h6">
            {t("breadcrumbs.top")}
          </Link>
          <Typography as="span" variant="h6">
            {t("breadcrumbs.payment_maintenance")}
          </Typography>
        </>
      }
    >
      <ArticleTitle
        title={t("payments.maintenance.title")}
        testId="article-payments-maintenance"
      >
        <Typography as="p" className="nid-article-description">
          {t("payments.maintenance.description")}
        </Typography>
      </ArticleTitle>

      <Box
        as="section"
        className="nid-section nid-section-overflow"
        data-testid="no-registered-card"
      >
        <Box className="nid-section-inner">
          <Box className="nid-column-item-start nid-item-gap4 my-3">
            <Typography as="h3" bold>
              {t("payments.maintenance.date.label")}
            </Typography>
            <Typography as="p">
              {t("payments.maintenance.date.text")}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box
        as="section"
        className="nid-section nid-section-overflow"
        data-testid="no-registered-card"
      >
        <Box className="nid-section-inner">
          <Box className="nid-column-item-start nid-item-gap4 my-3">
            <Typography as="h3" bold>
              {t("payments.maintenance.ngprocess.label")}
            </Typography>
            <Box as="ul" className="pl-6">
              <Box as="li" className={styles.listTypeDisc}>
                <Typography as="p">
                  {t("payments.maintenance.ngprocess.list1")}
                </Typography>
              </Box>
              <Box as="li" className={styles.listTypeDisc}>
                <Typography as="p">
                  {t("payments.maintenance.ngprocess.list2")}
                </Typography>
              </Box>
              <Box as="li" className={styles.listTypeDisc}>
                <Typography as="p">
                  {t("payments.maintenance.ngprocess.list3")}
                </Typography>
              </Box>
              <Box as="li" className={styles.listTypeDisc}>
                <Typography as="p">
                  {t("payments.maintenance.ngprocess.list4")}
                </Typography>
              </Box>
              <Box as="li" className={styles.listTypeDisc}>
                <Typography as="p">
                  {t("payments.maintenance.ngprocess.list5")}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box className="nid-section-inner mt-6">
        <LinkButton to={path.root} size="full" data-testid="link-top">
          {t("payments.maintenance.button.back")}
        </LinkButton>
      </Box>
    </Layout>
  );
};
