import type React from "react";
import { type ReactElement, useEffect } from "react";

import { FormProvider, useForm } from "react-hook-form";

import { type UserInfoPatch, usePatchUserInfo } from "@/hooks/usePatchUserInfo";
import { PageSkeletonLoader } from "nid-common";

type PersonalInfoFormProviderProps = {
  children: ReactElement;
};

export type InputUserInfoValues = {
  name: {
    first: string;
    last: string;
  };
  nameKana: {
    first: string;
    last: string;
  };
  birth?: {
    year: string;
    month: string;
    date: string;
  };
  sex?: string;
  inputDone: boolean;
};

export const buildUserInfoPatch = (
  input: InputUserInfoValues,
): UserInfoPatch => {
  let birthPatch: UserInfoPatch["birth"];
  if (input.birth) {
    birthPatch = {
      year: Number(input.birth.year),
      month: Number(input.birth.month),
      date: Number(input.birth.date),
    };
  }
  return {
    firstName: input.name.first,
    lastName: input.name.last,
    firstNameKana: input.nameKana.first,
    lastNameKana: input.nameKana.last,
    birth: birthPatch,
    sex: input.sex ?? undefined,
  } as UserInfoPatch;
};

export const PersonalInfoFormProvider: React.FC<
  PersonalInfoFormProviderProps
> = (props) => {
  const { status, userInfo } = usePatchUserInfo();
  const methods = useForm<InputUserInfoValues>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    shouldUnregister: false,
    defaultValues: {
      name: {
        first: "",
        last: "",
      },
      nameKana: {
        first: "",
        last: "",
      },
      birth: {
        year: "0",
        month: "0",
        date: "0",
      },
      sex: undefined,
      inputDone: false,
    },
  });

  useEffect(() => {
    if (status !== "ok") return;
    methods.setValue("name", {
      first: userInfo.firstName || "",
      last: userInfo.lastName || "",
    });
    methods.setValue("nameKana", {
      first: userInfo.firstNameKana || "",
      last: userInfo.lastNameKana || "",
    });
    methods.setValue("birth", {
      year: String(userInfo.birth?.year || "0"),
      month: String(userInfo.birth?.month || "0"),
      date: String(userInfo.birth?.date || "0"),
    });
    methods.setValue("sex", userInfo.sex || undefined);
  }, [status]);

  if (status !== "ok") return <PageSkeletonLoader />;
  return <FormProvider {...methods}>{props.children}</FormProvider>;
};
