import { useParams } from "react-router-dom";

import { getRegisteredRelyingParty } from "nid-common/api/account";
import useSWR from "swr";

export const useServicePage = () => {
  const { clientId } = useParams();

  const status = useSWR(
    `/account/registered-relying-parties/${clientId}`,
    () => (clientId ? getRegisteredRelyingParty(clientId) : undefined),
  );
  return {
    clientId: clientId ?? "",
    serviceName: status?.data?.data?.service_name,
  };
};
