import { CreditCardIcon } from "@/components/icon/CreditCardIcon";
import type { PaymentHistory } from "@/hooks/usePaymentHistories";
import { Box, LinkButton, Typography } from "nikkei-ui";
import type React from "react";
import { useTranslation } from "react-i18next";
import styles from "./CardPaymentHistories.module.css";

type Props = {
  card: PaymentHistory["card"];
  histories: PaymentHistory[];
};

export const CardPaymentHistories: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  const prices = props.histories.reduce((acc, history) => {
    return acc + history.amount;
  }, 0);

  return (
    <>
      <Box className={styles.header}>
        <Box className={styles.headerCard}>
          {props.card.paymentCardType === "credit_card" ? (
            <Box className={styles.brandIconBox}>
              <CreditCardIcon
                brand={props.card.brand ?? "Unknown"}
                className={styles.brandIcon}
              />
            </Box>
          ) : undefined}
          {props.card.paymentCardType === "credit_card" ? (
            <Typography
              as={"h3"}
              variant={"h3"}
              className={"ml-2"}
              color={"section-title"}
              bold
            >{`•••• ${props.card.last4 || "••••"}`}</Typography>
          ) : props.card.paymentCardType === "apple_pay" ? (
            <Typography as={"h3"} variant={"h3"} color={"section-title"} bold>
              {t("payments.histories.apply_pay")}
            </Typography>
          ) : props.card.paymentCardType === "au_easy_payment" ? (
            <Typography as={"h3"} variant={"h3"} color={"section-title"} bold>
              {t("payments.histories.au_easy_payment")}
            </Typography>
          ) : props.card.paymentCardType === "d_payment" ? (
            <Typography as={"h3"} variant={"h3"} color={"section-title"} bold>
              {t("payments.histories.d_payment")}
            </Typography>
          ) : undefined}
        </Box>
        <Typography variant={"h3"} bold>
          {t("payments.histories.yen", {
            value: prices.toLocaleString("ja-JP"),
          })}
        </Typography>
      </Box>
      {props.card.isDeleted ? (
        <Typography variant={"h5"}>
          {t("payments.histories.credit_card_deleted")}
        </Typography>
      ) : undefined}

      {props.histories.map((history, index) => {
        return (
          <HistoryItem
            key={`${history.encodedPaymentNo}:${index}`}
            history={history}
          />
        );
      })}
    </>
  );
};

const HistoryItem: React.FC<{ history: PaymentHistory }> = ({ history }) => {
  const { t } = useTranslation();

  const salesDate =
    history.paymentType === "refund" && history.refundTargetSalesDate
      ? history.refundTargetSalesDate
      : history.salesDate;
  return (
    <Box data-testid={history.encodedPaymentNo}>
      <hr className="nid-separator mb-4" />
      <Box className={`${styles.service} mb-2`}>
        <Typography
          as={"h4"}
          variant={"subtitle1"}
          color={"section-title"}
          bold
        >
          {history.serviceName}
        </Typography>
        {history.paymentHistoryUrl ? (
          <LinkButton
            variant={"border"}
            className={styles.linkButton}
            href={
              history.paymentHistoryUrl.external
                ? history.paymentHistoryUrl.href
                : undefined
            }
            to={
              !history.paymentHistoryUrl.external
                ? history.paymentHistoryUrl.to
                : undefined
            }
            newTab={history.paymentHistoryUrl.external}
          >
            {t("payments.histories.details")}
          </LinkButton>
        ) : undefined}
      </Box>
      {history.paymentHistoryDisplayString ? (
        <Typography color={"disabled"} variant={"h5"} className={"mb-1"}>
          {history.paymentHistoryDisplayString}
          {history.paymentType === "refund" && `（${history.paymentTypeName}）`}
        </Typography>
      ) : undefined}
      <Typography color={"disabled"} variant={"h5"} className={"mb-2"}>
        {t("payments.histories.sales_date", {
          year: salesDate.getFullYear(),
          month: salesDate.getMonth() + 1,
          date: salesDate.getDate(),
        })}
      </Typography>
      <Typography bold>
        {t("payments.histories.yen", {
          value: history.amount.toLocaleString("ja-JP"),
        })}
      </Typography>
    </Box>
  );
};
