import { getRegisteredRelyingParties } from "nid-common/api/account";
import useSWR from "swr";

export const useServicesListFeature = () => {
  const relyingParties = useSWR(
    "/account/service-in-use",
    getRegisteredRelyingParties,
  );

  if (relyingParties.isLoading) {
    return { status: "loading" } as const;
  }
  if (relyingParties.error || !relyingParties.data) {
    return { status: "error", error: relyingParties.error } as const;
  }

  return {
    status: "ok",
    relyingParties: relyingParties.data.data.map((relyingParty) => {
      return {
        clientId: relyingParty.client_id,
        serviceName: relyingParty.service_name,
        serviceLink: relyingParty.service_link,
        settingLink: relyingParty.setting_link,
        hasSettingPage: relyingParty.has_setting_page,
        b2bFlag: relyingParty.b2b_flag,
        bpServiceDetail: relyingParty?.bp_service_detail?.map((bpService) => {
          return {
            serviceName: bpService.service_name,
            serviceTopUrl: bpService.service_top_url,
          };
        }),
        caution: relyingParty.caution,
      };
    }),
  };
};
