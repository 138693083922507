import { Box } from "nikkei-ui";

import { useTranslation } from "react-i18next";

import { BusinessServiceList } from "@/components/ui/ServiceList/BusinessServiceList";
import { NikkeiBpServiceList } from "@/components/ui/ServiceList/NikkeiBpServiceList";
import { NikkeiServiceList } from "@/components/ui/ServiceList/NikkeiServiceList";
import { useServicesListFeature } from "@/features/Services/List/hooks/useServicesListFeature";
import { path } from "@/routes";
import { ContentSkeletonLoader } from "nid-common";

export const ServicesListFeature = (): JSX.Element => {
  const { t } = useTranslation();
  const useServicesListFeatureReturn = useServicesListFeature();

  if (useServicesListFeatureReturn.status !== "ok")
    return <ContentSkeletonLoader />;

  const services = useServicesListFeatureReturn.relyingParties;

  const bpServices = services.find(
    (service) => service.bpServiceDetail !== undefined,
  );
  const nikkeiServices = services.filter(
    (service) => service.bpServiceDetail === undefined && !service.b2bFlag,
  );
  const businessServices = services.filter(
    (service) => service.bpServiceDetail === undefined && service.b2bFlag,
  );

  return (
    <>
      {bpServices ||
      nikkeiServices.length > 0 ||
      businessServices.length > 0 ? (
        <>
          {nikkeiServices.length > 0 && (
            <NikkeiServiceList
              services={nikkeiServices.map((service) => {
                return {
                  clientId: service.clientId,
                  serviceName: service.serviceName,
                  serviceLink: service.serviceLink,
                  settingLink: service.hasSettingPage
                    ? path.services.service(service.clientId)
                    : service.settingLink,
                  hasSettingPage: service.hasSettingPage,
                };
              })}
            />
          )}
          {bpServices?.bpServiceDetail && (
            <NikkeiBpServiceList
              settingLink={bpServices.settingLink}
              services={bpServices.bpServiceDetail.map((service) => {
                return {
                  serviceName: service.serviceName,
                  serviceLink: service.serviceTopUrl,
                };
              })}
            />
          )}
          {businessServices.length > 0 && (
            <BusinessServiceList
              services={businessServices.map((service) => {
                return {
                  clientId: service.clientId,
                  serviceName: service.serviceName,
                  serviceLink: service.serviceLink,
                };
              })}
            />
          )}
        </>
      ) : (
        <Box className="nid-section nid-section-overflow">
          <Box className="nid-section-inner-gap0">
            {t("services.list.no_service_in_use")}
          </Box>
        </Box>
      )}
    </>
  );
};

export default ServicesListFeature;
