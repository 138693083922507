import { Link, Typography } from "nikkei-ui";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";

import { PersonalInfoFormProvider } from "@/features/Personal/Info/PersonalInfoFormProvider";
import { path } from "@/routes";
import { LoginWallProvider } from "nid-common";

import { Layout } from "../../../components/ui/Layout";

export const PersonalInfoPageWrapper = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Layout
      title={t("breadcrumbs.personal_info")}
      breadcrumbs={
        <>
          <Link to={path.root} variant="h6">
            {t("breadcrumbs.top")}
          </Link>
          <Link to={path.personal.root} variant="h6">
            {t("breadcrumbs.personal")}
          </Link>
          <Typography as="span" variant="h6">
            {t("breadcrumbs.personal_info")}
          </Typography>
        </>
      }
    >
      <LoginWallProvider>
        <PersonalInfoFormProvider>
          <Outlet />
        </PersonalInfoFormProvider>
      </LoginWallProvider>
    </Layout>
  );
};

export default PersonalInfoPageWrapper;
