import type React from "react";

import { PersonalEmailChangeConfirmFeature } from "@/features/Personal/EmailChange/PersonalEmailChangeConfirmFeature";

const PersonalEmailChangeConfirmPage: React.FC = () => {
  return (
    <>
      <PersonalEmailChangeConfirmFeature />
    </>
  );
};

export default PersonalEmailChangeConfirmPage;
