import ArticleTitle from "@/components/ui/ArticleTitle/ArticleTitle";
import { AlertMessage } from "@/components/ui/Message/AlertMessage";
import { ServiceUnavailableHoursModal } from "@/components/ui/Modal/ServiceUnavailableHoursModal";
import { PaymentsAlertFeature } from "@/components/ui/Payments/PaymentsAlertFeature";
import { CurrentCreditCard } from "@/components/ui/Payments/Update/CurrentCreditCard";
import { SelectCreditCard } from "@/components/ui/Payments/Update/SelectCreditCard";
import { usePaymentsUpdateServiceFeature } from "@/features/Payments/Update/usePaymentsUpdateServiceFeature";
import { path } from "@/routes";
import { ContentSkeletonLoader } from "nid-common";
import { Box, Link, Typography } from "nikkei-ui";
import type React from "react";
import { useTranslation } from "react-i18next";
import styles from "./PaymentsUpdateServiceFeature.module.css";

export const PaymentsUpdateServiceFeature: React.FC = () => {
  const { t } = useTranslation();

  const usePaymentsUpdateFeatureResult = usePaymentsUpdateServiceFeature();

  if (usePaymentsUpdateFeatureResult.status === "loading") {
    return (
      <>
        <ArticleTitle
          title={t("payments.update.title")}
          testId="article-payments-update-card"
        >
          <Typography as="p" className="nid-article-description">
            {t("payments.update.description")}
          </Typography>
        </ArticleTitle>
        <ContentSkeletonLoader />
      </>
    );
  }

  const {
    currentCard,
    selectableCards,
    registers,
    onSubmit,
    onComposition,
    showNewCardForm,
    currentCardBrand,
    service,
    otherServices,
    formErrors,
    errorCardType,
    disableButton,
  } = usePaymentsUpdateFeatureResult;

  return (
    <>
      {currentCard.status === "valid" && <PaymentsAlertFeature />}

      <ArticleTitle
        title={t("payments.update.title")}
        testId="article-payments-update-card"
      >
        <Box className={styles.description}>
          <Typography as="p" className="nid-article-description">
            {t("payments.update.description")}
          </Typography>
          <ServiceUnavailableHoursModal />
        </Box>
      </ArticleTitle>

      <Box as="section" className="nid-section nid-section-overflow">
        <Box className="nid-section-inner">
          {currentCard.status === "invalid" && (
            <Box className="mb-4">
              <AlertMessage
                testId="current-card-alert"
                title={t("payments.top.title.invalidCard")}
                withBorder={false}
              />
            </Box>
          )}
          {service.unpaid && (
            <Box className="mb-4">
              <AlertMessage
                testId="current-card-alert"
                title={t("payments.top.title.unpaidServices")}
                withBorder={false}
              />
            </Box>
          )}
          <CurrentCreditCard
            last4Number={currentCard.last4Number}
            brand={currentCard.brand}
            expire={currentCard.expire}
            servicesInUse={[
              {
                name: service.name,
                planName: service.planName,
              },
            ]}
            cardStatus={service.unpaid ? "unpaid" : "valid"}
          />
        </Box>
      </Box>

      <Box as="section" className="nid-section nid-section-overflow">
        <Box className="nid-section-inner">
          <SelectCreditCard
            cards={selectableCards.map((card) => {
              return {
                id: card.id,
                brand: card.brand,
                last4Number: card.last4Number,
                expire: card.expire,
              };
            })}
            registers={registers}
            onSubmit={onSubmit}
            showNewCardForm={showNewCardForm}
            currentCardBrand={currentCardBrand}
            otherServices={otherServices}
            disableButton={disableButton}
            formErrors={formErrors}
            errorCardType={errorCardType}
            onComposition={onComposition}
          />
        </Box>
      </Box>

      <Link to={path.payments.root} className="nid-button-back">
        <Typography color="link" variant="subtitle1">
          {t("payments.update.link.back")}
        </Typography>
      </Link>
    </>
  );
};
