import type { PaymentsCard, PaymentsService } from "@/hooks/usePaymentCards";

import { AlertMessage } from "@/components/ui/Message/AlertMessage";
import { CreditCard } from "@/components/ui/Payments/Top/PaymentsItem";
import { getServicesLinkedCard } from "@/features/Payments/getServicesLinkedCard";
import { path } from "@/routes";
import { Box, LinkButton, Typography } from "nikkei-ui";
import { useTranslation } from "react-i18next";
import styles from "./PaymentsFeature.module.css";

export type Service = {
  card: PaymentsCard;
} & PaymentsService;

const CreditCardPayments: React.FC<{ service: Service }> = ({ service }) => {
  const { t } = useTranslation();
  const cardStyle =
    service.card.status === "invalid" || service.unpaid
      ? "nid-typography--color-error"
      : "";

  return (
    <>
      <Box className="nid-section-header">
        <Typography
          as="h2"
          className="nid-section-title"
          data-testid="service-name"
        >
          {service.name} {service.planId ? `（${service.planName}）` : ""}
        </Typography>
        <LinkButton
          to={path.payments.update.service(service.clientId)}
          variant="border"
          data-testid="button-update"
          className={styles.button}
        >
          {t("payments.top.button.update")}
        </LinkButton>
      </Box>
      <CreditCard brand={service.card.brand}>
        <Typography data-testid="credit-card-number">
          <Box className={cardStyle}>•••• {service.card.last4Number}</Box>
        </Typography>
      </CreditCard>
      <Typography data-testid="expire">
        {`${t("payments.top.text.expire")} ${service.card.expire.formatString}`}
      </Typography>
    </>
  );
};

const PaymentsServiceTab: React.FC<{ service: Service }> = ({ service }) => {
  const { t } = useTranslation();

  return (
    <Box
      as="section"
      data-testid={`payments-service-${service.serviceId}`}
      className="nid-section nid-section-overflow"
    >
      {service.card.status === "invalid" && (
        <Box className="nid-section-inner mb-4">
          <AlertMessage
            testId="alert"
            title={t("payments.top.title.invalidCard")}
            withBorder={false}
          />
        </Box>
      )}
      {service.unpaid && (
        <Box className="nid-section-inner mb-4">
          <AlertMessage
            testId="alert"
            title={t("payments.top.title.unpaidServices")}
            withBorder={false}
          />
        </Box>
      )}
      <Box className="nid-section-inner nid-section-inner-gap8">
        <CreditCardPayments service={service} />
      </Box>
    </Box>
  );
};

export const PaymentsServicesFeature: React.FC<{ cards: PaymentsCard[] }> = ({
  cards,
}) => {
  const { t } = useTranslation();
  const services: Service[] = getServicesLinkedCard(cards);

  const servicesLinkedUnpaidCards = services.filter(
    (service) => service.unpaid,
  );

  const servicesLinkedNotUnpaidCards = services.filter(
    (service) => !service.unpaid,
  );

  return (
    <>
      {services.length > 0 ? (
        <>
          {servicesLinkedUnpaidCards.map((service) => (
            <PaymentsServiceTab key={service.serviceId} service={service} />
          ))}

          {servicesLinkedNotUnpaidCards.map((service) => (
            <PaymentsServiceTab key={service.serviceId} service={service} />
          ))}
        </>
      ) : (
        <Box as="section" className="nid-section nid-section-overflow">
          <Box className="nid-section-inner">
            <Box className="nid-section-body">
              <Typography as="span" data-testid="text-no-cards-for-service">
                {t("payments.top.text.no_cards_for_service")}
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};
