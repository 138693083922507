import type React from "react";

export const CardIcon: React.FC<React.ComponentProps<"svg">> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="27"
    height="23"
    viewBox="0 0 27 23"
    fill="none"
    aria-label="Icon of Card"
    aria-hidden="true"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.83341 3.54594V19.5459H24.1667V3.54594H2.83341ZM2.16675 0.879272C1.06218 0.879272 0.166748 1.7747 0.166748 2.87927V20.2126C0.166748 21.3172 1.06218 22.2126 2.16675 22.2126H24.8334C25.938 22.2126 26.8334 21.3172 26.8334 20.2126V2.87927C26.8334 1.7747 25.938 0.879272 24.8334 0.879272H2.16675Z"
      fill="#0068BC"
    />
    <path d="M1.5 6.21265H25.5V11.546H1.5V6.21265Z" fill="#0068BC" />
  </svg>
);
