import { CreditCardIcon } from "@/components/icon/CreditCardIcon";
import type { CreditCardBrand } from "@/hooks/usePaymentCards";
import { Box } from "nikkei-ui";
import type React from "react";
import styles from "./PaymentsItem.module.css";

export const CreditCard: React.FC<{
  brand: CreditCardBrand;
  children?: React.ReactNode;
}> = ({ brand, children }) => {
  return (
    <Box className={styles.paymentsItem}>
      <Box className={styles.paymentsIconContainer}>
        <CreditCardIcon brand={brand} className={styles.paymentsIcon} />
      </Box>
      {children}
    </Box>
  );
};
