import i18n from "i18next";

import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import AccountTop from "./accountTop.json";
import Common from "./common.json";
import ErrorPage from "./error.json";
import NewsLetters from "./newsletters.json";
import Payments from "./payments.json";
import Personal from "./personal.json";
import Security from "./security.json";
import Services from "./services.json";
import Withdraw from "./withdraw.json";

export const Locale = {
  ...Common,
  ...AccountTop,
  ...Personal,
  ...Payments,
  ...Security,
  ...Services,
  ...Withdraw,
  ...NewsLetters,
  ...ErrorPage,
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      ja: {
        locale: Locale,
      },
    },
    fallbackLng: "ja",
    defaultNS: "locale",
    react: {
      useSuspense: false,
    },
  });

export default i18n;
