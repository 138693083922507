import type * as React from "react";

import { Box, Typography } from "nikkei-ui";

import { useTranslation } from "react-i18next";
import { ScrollRestoration } from "react-router-dom";

import ArticleTitle from "@/components/ui/ArticleTitle/ArticleTitle";
import { SecurityMfaBackupCodeFeature } from "@/features/Security/Mfa/BackupCode/SecurityMfaBackupCodeFeature";

const SecurityMfaBackupCodePage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <ScrollRestoration />
      <Box className="print-page">
        <Box className="print-section">
          <ArticleTitle
            title={t("security.mfa.backup_code.title")}
            testId="article-security-mfa-backup-code"
          >
            <Typography as="p" className="nid-article-description">
              {t("security.mfa.backup_code.description")}
            </Typography>
          </ArticleTitle>
        </Box>
        <Box className="nid-section-inner-gap24">
          <SecurityMfaBackupCodeFeature />
        </Box>
      </Box>
    </>
  );
};

export default SecurityMfaBackupCodePage;
