import { useUserInfo } from "@/hooks/useUserInfo";
import useSWR from "swr";

export const usePersonalFeature = () => {
  const useUserInfoReturn = useUserInfo();
  const { mutate } = useSWR("/account/userinfo:edit", null);

  if (useUserInfoReturn.status === "loading") {
    return { status: "loading" } as const;
  }
  if (useUserInfoReturn.status === "error") {
    const error = useUserInfoReturn.error;
    return { status: "error", error } as const;
  }

  const userInfo = useUserInfoReturn.userInfo;

  return {
    status: "ok",
    userInfo,
    editHandler: () => {
      useUserInfoReturn.mutate();
      mutate(undefined);
    },
  } as const;
};
