import { useUserStatus } from "nid-common";
import type { StatusResponse } from "nid-common/api/account";

export type AccountTopFeatureType = {
  status: "ok" | "loading";
  data?: StatusResponse;
  isRelyingPartyRegistered?: boolean;
  lastPasswordChanged?: {
    year: number;
    month: number;
    day: number;
  };
};

export const useAccountTopFeature = (): AccountTopFeatureType => {
  const { data } = useUserStatus();

  if (!data) {
    return { status: "loading" };
  }

  const isRelyingPartyRegistered = data.is_relying_party_registered;
  const passwordLastUpdateDateTime = data.last_password_changed
    ? new Date(data.last_password_changed * 1000)
    : undefined;
  const lastPasswordChanged = passwordLastUpdateDateTime
    ? {
        year: passwordLastUpdateDateTime.getFullYear(),
        month: passwordLastUpdateDateTime.getMonth() + 1,
        day: passwordLastUpdateDateTime.getDate(),
      }
    : undefined;

  return {
    status: "ok",
    data,
    isRelyingPartyRegistered,
    lastPasswordChanged,
  };
};
