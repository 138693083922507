import * as React from "react";

import { Box, Checkbox, Link, QuestionCircleIcon, Typography } from "nikkei-ui";

import { useTranslation } from "react-i18next";
import RequiredTag from "../../Tag/RequiredTag";

type InputWithRefProps = React.ComponentPropsWithRef<"input">;

type Props = {
  testId?: string;
} & InputWithRefProps;

export const NikkeiResearchMonitorField = React.forwardRef<
  HTMLInputElement,
  Props
>(({ testId, ...props }, ref) => {
  const { t } = useTranslation();

  return (
    <>
      <Typography as="legend" variant="body1" bold className="mb-2">
        {t("attributes.nikkei_research_monitor")}
        <RequiredTag on={false} />
      </Typography>
      <Box className="nid-checkbox-area mb-1">
        <Checkbox
          data-testid={`${testId}-nikkei-research-monitor`}
          label={t("attribute_values.nikkei_research_monitor.1")}
          id="nikkei-research-monitor"
          ref={ref}
          {...props}
        />
      </Box>
      <Box className="nid-checkbox-area mb-9">
        <Link
          href={import.meta.env.VITE_URL_NIKKEI_RESEARCH_MONITOR}
          className="nid-row"
          variant="h4"
          newTab
        >
          <QuestionCircleIcon /> 日経IDリサーチモニターとは
        </Link>
      </Box>
    </>
  );
});

NikkeiResearchMonitorField.displayName = "Nid.Form.NikkeiResearchMonitorField";
