import { useScript } from "@/hooks/useScript";

export const useSonyPayment = (
  handleToken: (token: string) => Promise<void>,
) => {
  window.setToken = async (token: string, _: string) => {
    await handleToken(token);
  };

  const { scriptLoaded } = useScript(
    `${import.meta.env.VITE_SONY_PAYMENT_SCRIPT_SOURCE}?k_TokenNinsyoCode=${
      import.meta.env.VITE_SONY_PAYMENT_API_KEY
    }`,
    (script) => {
      script.async = true;
      script.className = "spsvToken";
      script.setAttribute("callBackFunc", "setToken");
    },
    import.meta.env.MODE === "test",
  );

  return {
    scriptLoaded,
  };
};
