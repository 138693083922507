import type * as React from "react";

import { Box, Link, LinkButton, Typography } from "nikkei-ui";

import { useTranslation } from "react-i18next";

import ArticleTitle from "@/components/ui/ArticleTitle/ArticleTitle";
import { path } from "@/routes";

const EMAILINPUT_STORAGE_KEY = "security-mfa-email-input";
const MFASETUPTOKEN_STORAGE_KEY = "security-mfa-email-mfasetup-token";
const BACKUPCODE_STORAGE_KEY = "security-mfa-email-backup-code";

const SecurityMfaCompletePage: React.FC = () => {
  const { t } = useTranslation();

  const handleClickRemoveStorage = () => {
    const storageList = [
      EMAILINPUT_STORAGE_KEY,
      MFASETUPTOKEN_STORAGE_KEY,
      BACKUPCODE_STORAGE_KEY,
    ];
    for (const key of storageList) {
      sessionStorage.removeItem(key);
    }
  };

  return (
    <>
      <ArticleTitle
        title={t("security.mfa.complete.title")}
        testId="article-security-mfa-complete"
      >
        <Typography as="p" className="nid-article-description">
          <span>{t("security.mfa.complete.description")}</span>
          <br />
          <span>
            {t("security.mfa.complete.description_backup_code_confirmable")}
          </span>
        </Typography>
      </ArticleTitle>
      <Box className="nid-article-header-inner">
        <LinkButton
          size="full"
          variant="border"
          data-testid="submit"
          to={path.security.mfa.backupCode}
        >
          {t("security.mfa.complete.button.backup_code")}
        </LinkButton>
      </Box>
      <Box className="nid-article-header-inner mt-4">
        <LinkButton
          to={path.security.mfa.root}
          onClick={handleClickRemoveStorage}
          size="full"
          data-testid="confirm-setting"
        >
          {t("security.mfa.complete.button.confirm_setting")}
        </LinkButton>
      </Box>
      <Box className="nid-article-header-inner nid-item-center mt-6 mb-6">
        <Link
          to={path.root}
          data-testid="button-complete"
          onClick={handleClickRemoveStorage}
        >
          {t("security.mfa.complete.button.complete")}
        </Link>
      </Box>
    </>
  );
};

export default SecurityMfaCompletePage;
