import type React from "react";
import { useState } from "react";

import {
  Box,
  ButtonBase as Button,
  Link,
  LinkButton,
  RadioTile,
  Typography,
} from "nikkei-ui";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import ArticleTitle from "@/components/ui/ArticleTitle/ArticleTitle";
import { path } from "@/routes";

import { useSecurityMfaTopFeature } from "./hooks/useSecurityMfaTopFeature";

import { PageSkeletonLoader } from "nid-common";
import styles from "./SecurityMfaTopFeature.module.css";

interface MfaTypeItemProps {
  title: string;
  changePageLink: string;
  value: string | React.ReactElement;
  testId: string;
  buttonLabel: string;
}

export const MfaTypeItem: React.FC<MfaTypeItemProps> = (props) => {
  return (
    <Box
      as="section"
      className="nid-section nid-section-overflow"
      data-testid={props.testId}
    >
      <Box className="nid-section-inner">
        <Box className="nid-section-header">
          <Typography
            as="h2"
            variant="body1"
            className="nid-section-title mr-4"
          >
            {props.title}
          </Typography>
          <LinkButton
            to={props.changePageLink}
            data-testid={`${props.testId}-button`}
            variant="border"
            size="sm"
          >
            {props.buttonLabel}
          </LinkButton>
        </Box>
        <Box className="nid-section-body">
          <Box className="nid-account-item--noborder">
            <Typography
              as="div"
              variant="body1"
              className="nid-account-item-text"
            >
              {props.value}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const MfaNotEnabledComponent = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [pageLink, setPageLink] = useState(path.security.mfa.root);

  const handleClickHref = () => {
    navigate(pageLink);
  };
  return (
    <>
      <ArticleTitle
        title={t("security.mfa.top.title")}
        testId="article-security-mfa-top"
      >
        <Typography as="p" className="nid-article-description">
          {t("security.mfa.top.description")}
        </Typography>
      </ArticleTitle>
      <Box className="nid-section-inner-gap24">
        <Box className="nid-column-item-start nid-item-gap8">
          <Typography bold variant="subtitle2">
            {t("security.mfa.top.select.label")}
          </Typography>
          <RadioTile
            value="1"
            name="mfa"
            id="mfa-app"
            checked={pageLink === path.security.mfa.authenticator.root}
            label={t("security.mfa.top.select.authenticator.label")}
            subText={t("security.mfa.top.select.authenticator.subtext")}
            onChange={() => setPageLink(path.security.mfa.authenticator.root)}
          />

          <RadioTile
            value="2"
            name="mfa"
            id="mfa-email"
            checked={pageLink === path.security.mfa.email.root}
            label={t("security.mfa.top.select.email.label")}
            subText={t("security.mfa.top.select.email.subtext")}
            onChange={() => setPageLink(path.security.mfa.email.root)}
          />
        </Box>

        <Box className="nid-item-center nid-item-gap16 my-4">
          <Button
            size="full"
            type="button"
            data-testid="submit"
            onClick={handleClickHref}
          >
            {t("security.edit.button.submit")}
          </Button>

          <Link to={path.root} className="nid-button-back">
            <Typography color="link" variant="subtitle1">
              {t("security.edit.button.complete")}
            </Typography>
          </Link>
        </Box>
      </Box>
    </>
  );
};

export const SecurityMfaTopFeature: React.FC = () => {
  const { t } = useTranslation();
  const { status, data } = useSecurityMfaTopFeature();

  if (status !== "ok") return <PageSkeletonLoader />;

  if (data.mfa_status === "none") {
    return <MfaNotEnabledComponent />;
  }

  return (
    <>
      <ArticleTitle
        title={t("security.mfa.top.title")}
        testId="article-security-mfa-top"
      >
        <Typography as="p" className="nid-article-description">
          {t("security.mfa.top.description_mfa_enabled")}
        </Typography>
      </ArticleTitle>

      {data.mfa_status === "authenticator_app_code" && (
        <MfaTypeItem
          title={t("security.mfa.top.select.label")}
          changePageLink={path.security.mfa.method}
          testId="mfa-item-authenticator-app-code"
          buttonLabel={t("security.mfa.top.button.change")}
          value={t("security.mfa.top.select.authenticator.label")}
        />
      )}

      {data.mfa_status === "email_code" && (
        <MfaTypeItem
          title={t("security.mfa.top.select.label")}
          changePageLink={path.security.mfa.method}
          testId="mfa-item-email-code"
          buttonLabel={t("security.mfa.top.button.change")}
          value={
            <Box className={styles.mfaTypeItemValue}>
              <Trans
                t={t}
                ns="locale"
                i18nKey="security.mfa.top.select.email.label_with_email"
                shouldUnescape={true}
                values={{ email: data.email }}
                components={[
                  <Typography key="email" as="span" data-dd-privacy="mask" />,
                ]}
              />
            </Box>
          }
        />
      )}

      <MfaTypeItem
        title={t("security.mfa.top.select.backup_code.label")}
        changePageLink={path.security.mfa.backupCode}
        testId="mfa-item-backup-code"
        buttonLabel={t("security.mfa.top.button.confirm")}
        value={t("security.mfa.top.select.backup_code.subtext")}
      />

      <Box className="nid-section-inner">
        <LinkButton
          size="full"
          variant="border"
          type="button"
          data-testid="button-disable"
          to={path.security.mfa.disable.root}
        >
          {t("security.mfa.top.button.disable_mfa")}
        </LinkButton>
      </Box>
    </>
  );
};
