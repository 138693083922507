import type * as React from "react";

import { Link, Typography } from "nikkei-ui";
import { useTranslation } from "react-i18next";
import { Outlet, ScrollRestoration } from "react-router-dom";

import { Layout } from "@/components/ui/Layout";
import { SecurityPasskeyProvider } from "@/features/Security/Passkey/SecurityPasskeyProvider";
import { path } from "@/routes";
import { LoginWallProvider } from "nid-common";

export const SecurityPasskeyPageWrapper: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Layout
      title={t("breadcrumbs.security_passkey")}
      breadcrumbs={
        <>
          <Link to={path.root} variant="h6">
            {t("breadcrumbs.top")}
          </Link>
          <Link to={path.security.root} variant="h6">
            {t("breadcrumbs.security")}
          </Link>
          <Typography as="span" variant="h6">
            {t("breadcrumbs.security_passkey")}
          </Typography>
        </>
      }
    >
      <ScrollRestoration />
      <LoginWallProvider maxAge={1800}>
        <SecurityPasskeyProvider>
          <Outlet />
        </SecurityPasskeyProvider>
      </LoginWallProvider>
    </Layout>
  );
};

export default SecurityPasskeyPageWrapper;
