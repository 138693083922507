import type React from "react";

import { Link, Typography } from "nikkei-ui";
import { useTranslation } from "react-i18next";

import ArticleTitle from "@/components/ui/ArticleTitle/ArticleTitle";
import { Layout } from "@/components/ui/Layout";
import { NewsletterCard } from "@/components/ui/NewsletterCard/NewsletterCard";
import { newsletterConfig } from "@/pages/Services/Newsletters/config";
import { path } from "@/routes";

export const NewslettersPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Layout
      title={t("breadcrumbs.newsletters")}
      breadcrumbs={
        <>
          <Link to={path.root} variant="h6">
            {t("breadcrumbs.top")}
          </Link>
          <Typography as="span" variant="h6">
            {t("breadcrumbs.newsletters")}
          </Typography>
        </>
      }
    >
      <ArticleTitle
        title={t("newsletters.index.title")}
        testId="article-service-newsletters"
      >
        <Typography as="p" className="nid-article-description">
          {t("newsletters.index.description")}
        </Typography>
      </ArticleTitle>
      {newsletterConfig.map((service) => {
        return (
          <NewsletterCard
            serviceName={service.service_name}
            contents={service.contents.map((content) => {
              return {
                title: content.title,
                target: content.target,
                description: content.description,
                time: content.time,
                link: content.link,
                linkType: content.link_type,
              };
            })}
            key={service.service_name}
          />
        );
      })}
    </Layout>
  );
};
