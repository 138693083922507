import {
  Box,
  ButtonBase as Button,
  Link,
  TextField,
  Typography,
} from "nikkei-ui";
import { useTranslation } from "react-i18next";

import { usePersonalEmailChangeInputFeature } from "@/features/Personal/EmailChange/hooks/usePersonalEmailChangeInputFeature";
import { path } from "@/routes";
import { ContentSkeletonLoader } from "nid-common";

export const PersonalEmailChangeInputFeature = (): JSX.Element => {
  const { t } = useTranslation();

  const { email, errors, handleSubmit, registers, status, buttonEnabled } =
    usePersonalEmailChangeInputFeature();

  if (status !== "ok") return <ContentSkeletonLoader />;

  const errorMessage = errors?.email?.message;

  return (
    <Box as="section" className="nid-section nid-section-without-background">
      <Box className="nid-section-inner">
        <Box className="nid-section-header">
          <TextField
            labelVariant="head"
            variant="readOnlyText"
            leadingIcon="Mail"
            readOnly={true}
            label={t("attributes.current_email")}
            value={email}
            data-testid="input-current-email"
            className="nid-background-transparent mb-2"
          />
        </Box>
        <Box className="nid-section-body">
          <form onSubmit={handleSubmit} noValidate>
            <TextField
              labelVariant="head"
              label={t("attributes.new_email")}
              variant="email"
              autoFocus={true}
              className="mb-2"
              testId="input-new-email"
              status={errorMessage ? "error" : undefined}
              validationMessage={errorMessage}
              placeholder="example@example.com"
              required={true}
              {...registers.email}
            />
            <Button size="full" data-testid="submit" disabled={!buttonEnabled}>
              {t("personal.edit.button.send_email")}
            </Button>
          </form>
        </Box>
        <Box className="nid-item-center mt-6 mb-1">
          <Link to={path.personal.root} className="nid-button-back">
            <Typography color="link">
              {t("personal.edit.button.cancel")}
            </Typography>
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default PersonalEmailChangeInputFeature;
