import type React from "react";
import { useEffect, useState } from "react";

import {
  ArrowRightIcon,
  Box,
  Button,
  Link,
  LinkButton,
  Typography,
} from "nikkei-ui";

import { QRCodeSVG } from "qrcode.react";
import { useTranslation } from "react-i18next";

import { MfaAuthenticatorSettingCard } from "@/components/ui/MfaAuthenticatorSettingCard/MfaAuthenticatorSettingCard";

import { useSecurityMfaAuthenticatorFeature } from "@/features/Security/Mfa/Authenticator/hooks/useSecurityMfaAuthenticatorFeature";
import { path } from "@/routes";

import { AppStoreIcon } from "nid-common/components/Icon/AppStoreIcon";
import googleAuthenticatorIcon from "nid-common/components/Icon/GoogleAuthenticatorIcon.png";
import googlePlayStoreIcon from "nid-common/components/Icon/GooglePlayStoreIcon.png";

import styles from "./SecurityMfaAuthenticatorFeature.module.css";

export const SecurityMfaAuthenticatorFeature: React.FC = () => {
  const { t } = useTranslation();

  const [toggleOpened, setToggleOpened] = useState(false);

  const [coping, setCoping] = useState(false);

  const { token } = useSecurityMfaAuthenticatorFeature();

  const displayAuthenticatorSecret =
    token.authenticator_secret.match(/.{1,5}/g)?.join(" ") || "";

  const onCopy = async () => {
    setCoping(true);
    await navigator.clipboard.writeText(displayAuthenticatorSecret);
  };

  useEffect(() => {
    if (coping) {
      (async () => {
        const timeout = new Promise((resolve) => setTimeout(resolve, 1000));
        await timeout;
        setCoping(false);
      })();
    }
  }, [coping]);

  return (
    <Box as="section" className="nid-section-inner">
      <MfaAuthenticatorSettingCard
        title={t("security.mfa.authenticator.steps.1.title")}
      >
        <Box>{t("security.mfa.authenticator.steps.1.description")}</Box>
        <hr className="my-4 nid-separator" />
        <Box className={styles.googleAuthenticator}>
          <img
            src={googleAuthenticatorIcon}
            width="40px"
            className="mr-2"
            alt="Google Authenticator icon"
          />
          Google Authenticator
        </Box>
        <Box className={styles.appStoreBox}>
          <Link
            href={import.meta.env.VITE_URL_APP_STORE_GOOGLE_AUTHENTICATOR}
            newTab
          >
            <AppStoreIcon />
          </Link>
          <Link
            href={
              import.meta.env.VITE_URL_GOOGLE_PLAY_STORE_GOOGLE_AUTHENTICATOR
            }
            newTab
          >
            <img src={googlePlayStoreIcon} alt="Google play store icon" />
          </Link>
        </Box>
        <Box className={styles.supplement}>
          {t("security.mfa.authenticator.steps.1.supplement")}
        </Box>
      </MfaAuthenticatorSettingCard>

      <MfaAuthenticatorSettingCard
        title={t("security.mfa.authenticator.steps.2.title")}
      >
        {t("security.mfa.authenticator.steps.2.description")}
        <Box className="nid-item-center my-4">
          <QRCodeSVG height="123px" data-testid="qr-code" value={token.uri} />
        </Box>
        <hr className="nid-separator my-4" />
        <Box className="nid-item-center">
          <Button
            variant="link"
            data-testid="open-toggle-button"
            onClick={() => setToggleOpened(!toggleOpened)}
          >
            {t("security.mfa.authenticator.steps.2.toggle.title")}
            <ArrowRightIcon
              transform={toggleOpened ? "rotate(-90)" : "rotate(90)"}
            />
          </Button>
        </Box>
        {toggleOpened && (
          <>
            <Box className="mt-4 mb-2">
              {t("security.mfa.authenticator.steps.2.toggle.description")}
            </Box>
            <Box className={styles.supplement}>
              {t("security.mfa.authenticator.steps.2.toggle.support")}
            </Box>
            <Box
              data-testid="mfa-authenticator-secret-text"
              className={styles.authenticatorToken}
            >
              {displayAuthenticatorSecret}
            </Box>
            <Button
              size="full"
              data-testid="button-copy-authenticator-secret-text"
              disabled={coping}
              onClick={onCopy}
            >
              {coping
                ? t("security.mfa.authenticator.steps.2.toggle.button.copied")
                : t("security.mfa.authenticator.steps.2.toggle.button.copy")}
            </Button>
          </>
        )}
      </MfaAuthenticatorSettingCard>

      <MfaAuthenticatorSettingCard
        title={t("security.mfa.authenticator.steps.3.title")}
      >
        {t("security.mfa.authenticator.steps.3.description")}
      </MfaAuthenticatorSettingCard>

      <Box className="my-10">
        <LinkButton
          to={path.security.mfa.authenticator.confirm}
          size="full"
          data-testid="button-next"
        >
          {t("security.edit.button.submit")}
        </LinkButton>

        <Link to={path.security.mfa.root} className="nid-button-back my-4">
          <Typography color="link" variant="subtitle1">
            {t("security.edit.button.back")}
          </Typography>
        </Link>
      </Box>
    </Box>
  );
};
