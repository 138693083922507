import type * as React from "react";

import { Box, Link, LinkButton, Typography } from "nikkei-ui";

import { Trans, useTranslation } from "react-i18next";

import ArticleTitle from "@/components/ui/ArticleTitle/ArticleTitle";
import { path } from "@/routes";

import { Layout } from "../../components/ui/Layout";

const SUPPORT_URL =
  "https://support.nikkei.com/app/answers/list/st/5/p/1/page/1/search/1";

export const ErrorPage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Layout
      title={t("breadcrumbs.error")}
      breadcrumbs={
        <>
          <Link to={path.root} variant="h6">
            {t("breadcrumbs.top")}
          </Link>
          <Typography as="span" variant="h6">
            {t("breadcrumbs.error")}
          </Typography>
        </>
      }
    >
      <Box className="mb-20">
        <ArticleTitle testId="article-error" title={t("error.title")} />
        <Box className="nid-section-inner">
          <Typography as="p">
            <Trans
              t={t}
              i18nKey="error.text"
              components={[
                <a key="support-url" href={SUPPORT_URL}>
                  Support
                </a>,
              ]}
            />
          </Typography>
        </Box>
      </Box>

      <Box className="nid-article-header-inner">
        <LinkButton to={path.root} size="full">
          {t("error.button.back")}
        </LinkButton>
      </Box>
    </Layout>
  );
};
