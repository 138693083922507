import type React from "react";

import { Box } from "nikkei-ui";

/* DISPLAY_NAME */
const DISPLAY_NAME = "Nid.Row";

type RowProps = {
  reverse?: boolean;
  className?: string;
  children: React.ReactNode;
};

const Row: React.FC<RowProps> = ({ reverse, className = "", children }) => {
  const classnameReverse = reverse ? "nid-row-reverse" : "";
  const nidClasses = `nid-row ${classnameReverse} ${className}`;
  return <Box className={nidClasses}>{children}</Box>;
};

Row.displayName = DISPLAY_NAME;
const Root = Row;
export { Row, Root };
export type { RowProps };
export default Row;
