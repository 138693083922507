import { Layout } from "@/components/ui/Layout";
import { PaymentsUpdateServiceFeature } from "@/features/Payments/Update/PaymentsUpdateServiceFeature";
import { path } from "@/routes";
import { LoginWallProvider } from "nid-common";
import { Link, Typography } from "nikkei-ui";
import type React from "react";
import { useTranslation } from "react-i18next";

const PaymentsUpdateServicePage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Layout
      title={t("breadcrumbs.payments_card_update")}
      breadcrumbs={
        <>
          <Link to={path.root} variant="h6">
            {t("breadcrumbs.top")}
          </Link>
          <Link to={path.payments.root} variant="h6">
            {t("breadcrumbs.payments")}
          </Link>
          <Typography as="span" variant="h6" data-testid="breadcrumb-last-item">
            {t("breadcrumbs.payments_card_update")}
          </Typography>
        </>
      }
    >
      <LoginWallProvider maxAge={1800}>
        <PaymentsUpdateServiceFeature />
      </LoginWallProvider>
    </Layout>
  );
};

export default PaymentsUpdateServicePage;
