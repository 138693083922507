import { useEffect, useState } from "react";

import { type SubmitHandler, useFormContext } from "react-hook-form";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useAddressMaster, useCountriesMaster } from "nid-common";

import { path } from "@/routes";

import {
  type PatchUserInfoError,
  type UserInfoPatch,
  usePatchUserInfo,
} from "@/hooks/usePatchUserInfo";
import { useUserInfo } from "@/hooks/useUserInfo";
import { useUserInfoLabel } from "@/hooks/useUserInfoLabel";
import { datadogRum } from "@datadog/browser-rum";
import {
  COUNTRY_JAPAN,
  COUNTRY_UNKNOWN,
  type InputUserInfoValues,
  buildUserInfoPatch,
} from "../PersonalAddressFormProvider";

export const usePersonalAddressConfirmFeature = () => {
  const { t } = useTranslation();
  const { handleSubmit, getValues, formState } =
    useFormContext<InputUserInfoValues>();
  const { getZipCodeLabel } = useUserInfoLabel();

  const { patch, userInfo: confirmUserInfo } = usePatchUserInfo();
  const useUserInfoReturn = useUserInfo();
  const { address } = useAddressMaster(getValues("domestic.zipCode"));
  const countries = useCountriesMaster();
  const navigate = useNavigate();
  const [apiError, setApiError] = useState<string>();
  const inputDone = getValues("inputDone");

  useEffect(() => {
    if (!inputDone || !confirmUserInfo) {
      navigate(path.personal.address.root, { replace: true });
    }
  }, [inputDone, confirmUserInfo]);

  if (
    countries.status !== "ok" ||
    !inputDone ||
    useUserInfoReturn.status !== "ok" ||
    !confirmUserInfo
  ) {
    return { status: "loading" } as const;
  }

  // note: inputでsubmitされた値を利用してInputUserInfoValuesを作成
  // ブラウザバックで戻ってきた場合に、inputでsubmitされた値を再度表示するため
  const confirmAddressInfoValues = (
    patchUserInfo: UserInfoPatch,
  ): InputUserInfoValues => {
    const domesticAddress = patchUserInfo.domesticAddress;
    const overseasAddress = patchUserInfo.overseasAddress;
    const countryCode =
      patchUserInfo.residenceCountryFlag === "0"
        ? COUNTRY_JAPAN
        : overseasAddress?.countryCode;
    return {
      countryCode: countryCode ?? COUNTRY_JAPAN,
      domestic: {
        zipCode: domesticAddress?.zipCode ?? "",
        addressCode: domesticAddress?.addressCode ?? "",
        address1: domesticAddress?.address1 ?? "",
        address2: domesticAddress?.address2 ?? "",
        tel: domesticAddress?.tel ?? "",
      },
      overseas: {
        zipCode: overseasAddress?.zipCode ?? "",
        address1: overseasAddress?.address1 ?? "",
        address2: overseasAddress?.address2 ?? "",
        address3: overseasAddress?.address3 ?? "",
        tel: overseasAddress?.tel ?? "",
      },
      inputDone,
    };
  };

  const isDomesticCode = (code: string) =>
    code === COUNTRY_JAPAN || code === COUNTRY_UNKNOWN;
  const isDomestic = isDomesticCode(getValues("countryCode"));

  const getDisplayValues = () => {
    const values = confirmAddressInfoValues(confirmUserInfo);
    const addressName = address.find(
      (v) => values.domestic.addressCode === v.code,
    )?.name;
    const countryName = countries.countries.find(
      (v) => values.countryCode === v.code,
    )?.name;
    return values.countryCode === COUNTRY_JAPAN
      ? {
          country: countryName,
          zipCode: getZipCodeLabel(values.domestic.zipCode),
          address: [
            (addressName ?? "") + values.domestic.address1,
            values.domestic.address2,
          ],
          tel: values.domestic.tel,
        }
      : {
          country: countryName,
          zipCode: values.overseas.zipCode,
          address: [
            values.overseas.address1,
            values.overseas.address2,
            values.overseas.address3,
          ],
          tel: values.overseas.tel,
        };
  };

  const handleConfirmSubmit: SubmitHandler<InputUserInfoValues> = async (
    _data: InputUserInfoValues,
  ) => {
    const confirmData = confirmAddressInfoValues(confirmUserInfo);
    import.meta.env.DEV;
    try {
      await patch(
        buildUserInfoPatch(confirmData, useUserInfoReturn.userInfo),
        false,
      );
      navigate(path.personal.address.complete);
    } catch (e) {
      if ((e as PatchUserInfoError).error === "optimistic_locked") {
        setApiError(t("personal.edit.errors.optimistic_locked"));
      } else {
        datadogRum.addError(e);
        navigate(path.error.root);
      }
    }
  };

  return {
    status: "ok",
    getDisplayValues,
    handleSubmit: handleSubmit(handleConfirmSubmit),
    apiError,
    formState,
    inputDone,
    isDomestic,
  } as const;
};
