import React from "react";

import ReactDOM from "react-dom/client";
import { createBrowserRouter } from "react-router-dom";

import App from "./App";
import { publicRoutes } from "./routes/public";
import "nid-common/styles/print.css";
import "./index.css";

const options = { basename: import.meta.env.BASE_URL };
export const router = createBrowserRouter([...publicRoutes], options);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <App router={router} />
  </React.StrictMode>,
);
