import { useEffect, useState } from "react";

import { type SubmitHandler, useFormContext } from "react-hook-form";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import {
  type PersonalSurveyFormValues,
  buildUserInfoPatch,
} from "@/features/Personal/Survey/PersonalSurveyFormProvider";
import {
  type PatchUserInfoError,
  type UserInfoPatch,
  usePatchUserInfo,
} from "@/hooks/usePatchUserInfo";
import { path } from "@/routes";
import { datadogRum } from "@datadog/browser-rum";

export const usePersonalSurveyConfirmFeature = () => {
  const { handleSubmit, getValues } =
    useFormContext<PersonalSurveyFormValues>();

  const { t } = useTranslation();
  const { patch, userInfo: confirmUserInfo } = usePatchUserInfo();
  const navigate = useNavigate();
  const [apiError, setApiError] = useState<string>();

  useEffect(() => {
    if (!confirmUserInfo) {
      navigate(path.personal.survey.root, { replace: true });
    }
  }, [confirmUserInfo, navigate]);

  if (!confirmUserInfo) {
    return { status: "loading" } as const;
  }

  const confirmPersonalSurveyInfoValues = (
    patchUserInfo: UserInfoPatch,
  ): PersonalSurveyFormValues => {
    const { newsSubscriptions, interest } = patchUserInfo;
    const notificationMail = {
      nikkeiMail: patchUserInfo.receiveNikkeiMail ?? false,
      thirdPartyMail: patchUserInfo.receiveThirdPartyMail ?? false,
    };
    const newsSubscription = {
      newsSubscription1: newsSubscriptions?.newsSubscription1 ?? false,
      newsSubscription3: newsSubscriptions?.newsSubscription3 ?? false,
      newsSubscription4: newsSubscriptions?.newsSubscription4 ?? false,
    };
    const interestData = {
      interest1: interest?.interest1 ?? false,
      interest2: interest?.interest2 ?? false,
      interest3: interest?.interest3 ?? false,
      interest4: interest?.interest4 ?? false,
      interest5: interest?.interest5 ?? false,
      interest6: interest?.interest6 ?? false,
      interest7: interest?.interest7 ?? false,
      interest8: interest?.interest8 ?? false,
      interest9: interest?.interest9 ?? false,
      interest10: interest?.interest10 ?? false,
      interest11: interest?.interest11 ?? false,
      interest12: interest?.interest12 ?? false,
      interest13: interest?.interest13 ?? false,
    };

    return {
      notificationMail,
      newsSubscription,
      nikkeiResearchMonitor: patchUserInfo.registerNikkeiMonitor ?? false,
      interest: interestData,
      inputDone: getValues("inputDone"),
    };
  };

  const handleConfirmSubmit: SubmitHandler<PersonalSurveyFormValues> = async (
    _input: PersonalSurveyFormValues,
  ): Promise<void> => {
    const confirmInput = confirmPersonalSurveyInfoValues(confirmUserInfo);
    try {
      await patch(buildUserInfoPatch(confirmInput), false);
      navigate(path.personal.survey.complete);
    } catch (e) {
      if ((e as PatchUserInfoError).error === "optimistic_locked") {
        setApiError(t("personal.edit.errors.optimistic_locked"));
      } else {
        datadogRum.addError(e);
        navigate(path.error.root);
      }
    }
  };

  const getDisplayValues = () => {
    const values = confirmPersonalSurveyInfoValues(confirmUserInfo);
    const notificationMail: string[] = [
      ...(values.notificationMail.nikkeiMail
        ? [t("attributes.nikkei_mail")]
        : []),
      ...(values.notificationMail.thirdPartyMail
        ? [t("attributes.third_party_mail")]
        : []),
    ];
    const newsSubscription: string[] = [
      ...(values.newsSubscription.newsSubscription1
        ? [t("attribute_values.news_subscription.1")]
        : []),
      ...(values.newsSubscription.newsSubscription3
        ? [t("attribute_values.news_subscription.3")]
        : []),
      ...(values.newsSubscription.newsSubscription4
        ? [t("attribute_values.news_subscription.4")]
        : []),
    ];
    const nikkeiResearchMonitor: string | undefined =
      values.nikkeiResearchMonitor
        ? t("attribute_values.nikkei_research_monitor.1")
        : undefined;
    const interest: string = [
      ...(values.interest.interest1 ? [t("attribute_values.interest.1")] : []),
      ...(values.interest.interest2 ? [t("attribute_values.interest.2")] : []),
      ...(values.interest.interest3 ? [t("attribute_values.interest.3")] : []),
      ...(values.interest.interest4 ? [t("attribute_values.interest.4")] : []),
      ...(values.interest.interest5 ? [t("attribute_values.interest.5")] : []),
      ...(values.interest.interest6 ? [t("attribute_values.interest.6")] : []),
      ...(values.interest.interest7 ? [t("attribute_values.interest.7")] : []),
      ...(values.interest.interest8 ? [t("attribute_values.interest.8")] : []),
      ...(values.interest.interest9 ? [t("attribute_values.interest.9")] : []),
      ...(values.interest.interest10
        ? [t("attribute_values.interest.10")]
        : []),
      ...(values.interest.interest11
        ? [t("attribute_values.interest.11")]
        : []),
      ...(values.interest.interest12
        ? [t("attribute_values.interest.12")]
        : []),
      ...(values.interest.interest13
        ? [t("attribute_values.interest.13")]
        : []),
    ].join(t("attribute_values.interest.separator"));

    return {
      notificationMail,
      newsSubscription,
      nikkeiResearchMonitor,
      interest,
    };
  };

  const errors = apiError ? { api: { message: apiError } } : undefined;
  const inputDone = getValues("inputDone");
  useEffect(() => {
    if (!inputDone) navigate(path.personal.survey.root, { replace: true });
  }, [inputDone]);

  return {
    displayValues: getDisplayValues(),
    handleSubmit: handleSubmit(handleConfirmSubmit),
    errors,
    buttonEnabled: errors === undefined,
    inputDone,
  };
};
