import type React from "react";
import { useEffect } from "react";

import { FormProvider, useForm } from "react-hook-form";

import { type UserInfoPatch, usePatchUserInfo } from "@/hooks/usePatchUserInfo";
import { useCompanyTypeMaster } from "nid-common";

export type PersonalOfficeFormValues = {
  companyCountryFlag: string;
  domesticCompany: {
    companyName: string;
    companyNumber: string;
    companyTypeCodeFront: string;
    companyTypeCodeBack: string;
    companyBusinessUnit: string;
    companyZipCode: string;
    companyAddress: string;
    companyTel: string;
  };
  overseasCompany: {
    companyName: string;
    companyAddress: string;
    companyTel: string;
  };
  inputDone: boolean;
};

export const buildUserInfoPatch = (
  input: PersonalOfficeFormValues,
): UserInfoPatch => {
  return {
    companyCountryFlag: input.companyCountryFlag,
    domesticCompany:
      input.companyCountryFlag === "0"
        ? {
            name:
              input.domesticCompany.companyName !== ""
                ? input.domesticCompany.companyName
                : null,
            number:
              input.domesticCompany.companyNumber !== ""
                ? input.domesticCompany.companyNumber
                : null,
            typeCode:
              input.domesticCompany.companyTypeCodeFront !== "0"
                ? input.domesticCompany.companyTypeCodeFront
                : input.domesticCompany.companyTypeCodeBack !== "0"
                  ? input.domesticCompany.companyTypeCodeBack
                  : "00",
            typeLocation:
              input.domesticCompany.companyTypeCodeFront !== "0"
                ? "1"
                : input.domesticCompany.companyTypeCodeBack !== "0"
                  ? "2"
                  : "0",
            businessUnit:
              input.domesticCompany.companyBusinessUnit !== ""
                ? input.domesticCompany.companyBusinessUnit
                : null,
            zipCode:
              input.domesticCompany.companyZipCode !== ""
                ? input.domesticCompany.companyZipCode
                : null,
            address:
              input.domesticCompany.companyAddress !== ""
                ? input.domesticCompany.companyAddress.replace(/\r?\n/g, "　")
                : null,
            tel:
              input.domesticCompany.companyTel !== ""
                ? input.domesticCompany.companyTel
                : null,
          }
        : undefined,
    overseasCompany:
      input.companyCountryFlag === "1"
        ? {
            name:
              input.overseasCompany.companyName !== ""
                ? input.overseasCompany.companyName
                : null,
            address:
              input.overseasCompany.companyAddress !== ""
                ? input.overseasCompany.companyAddress.replace(/\r?\n/g, " ")
                : null,
            tel:
              input.overseasCompany.companyTel !== ""
                ? input.overseasCompany.companyTel
                : null,
          }
        : undefined,
  };
};

export const PersonalOfficeFormProvider: React.FC<{
  children: React.ReactElement;
}> = ({ children }) => {
  const patchUserInfo = usePatchUserInfo();
  const companyTypes = useCompanyTypeMaster();
  const methods = useForm<PersonalOfficeFormValues>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    shouldUnregister: false,
    defaultValues: {
      companyCountryFlag: "0",
      domesticCompany: {
        companyName: "",
        companyNumber: "",
        companyTypeCodeFront: "0",
        companyTypeCodeBack: "0",
        companyBusinessUnit: "",
        companyZipCode: "",
        companyAddress: "",
        companyTel: "",
      },
      overseasCompany: {
        companyName: "",
        companyAddress: "",
        companyTel: "",
      },
      inputDone: false,
    },
  });

  useEffect(() => {
    if (patchUserInfo.status !== "ok" || companyTypes.status !== "ok") return;
    const userInfo = patchUserInfo.userInfo;
    methods.setValue("companyCountryFlag", userInfo.companyCountryFlag ?? "0");
    methods.setValue("domesticCompany", {
      companyName: userInfo?.domesticCompany?.name ?? "",
      companyNumber: userInfo?.domesticCompany?.number ?? "",
      companyTypeCodeFront:
        userInfo?.domesticCompany?.typeLocation === "1" &&
        userInfo?.domesticCompany?.typeCode
          ? userInfo?.domesticCompany?.typeCode
          : "0",
      companyTypeCodeBack:
        userInfo?.domesticCompany?.typeLocation === "2" &&
        userInfo?.domesticCompany?.typeCode
          ? userInfo?.domesticCompany?.typeCode
          : "0",
      companyBusinessUnit: userInfo?.domesticCompany?.businessUnit ?? "",
      companyZipCode: userInfo?.domesticCompany?.zipCode ?? "",
      companyAddress: userInfo?.domesticCompany?.address ?? "",
      companyTel: userInfo?.domesticCompany?.tel ?? "",
    });
    methods.setValue("overseasCompany", {
      companyName: userInfo?.overseasCompany?.name ?? "",
      companyAddress: userInfo?.overseasCompany?.address ?? "",
      companyTel: userInfo?.overseasCompany?.tel ?? "",
    });
  }, [patchUserInfo.status, companyTypes.status]);

  return <FormProvider {...methods}>{children}</FormProvider>;
};
