import type React from "react";

import { useTranslation } from "react-i18next";

import ArticleTitle from "@/components/ui/ArticleTitle/ArticleTitle";
import { PersonalOfficeConfirmFeature } from "@/features/Personal/Office/PersonalOfficeConfirmFeature";
import { Typography } from "nikkei-ui";

export const PersonalOfficeConfirmPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <ArticleTitle
        testId="article-personal-office-confirm"
        title={t("personal.edit.office.confirm.title")}
      >
        <Typography as="p" className="nid-article-description">
          {t("personal.edit.office.confirm.description")}
        </Typography>
      </ArticleTitle>

      <PersonalOfficeConfirmFeature />
    </>
  );
};
