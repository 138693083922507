import type React from "react";
import type { ReactElement } from "react";

import { FormProvider, useForm } from "react-hook-form";

type SecurityPasswordChangeFormProviderProps = {
  children: ReactElement;
};

export type SecurityPasswordChangeValues = {
  password: string;
  passwordConfirm: string;
};

export const SecurityPasswordChangeFormProvider: React.FC<
  SecurityPasswordChangeFormProviderProps
> = (props) => {
  const methods = useForm<SecurityPasswordChangeValues>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    shouldUnregister: false,
    defaultValues: {
      password: "",
      passwordConfirm: "",
    },
  });
  return <FormProvider {...methods}>{props.children}</FormProvider>;
};
