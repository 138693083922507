import type React from "react";

import { Box, LinkButton, Typography } from "nikkei-ui";

import { useTranslation } from "react-i18next";

import ArticleTitle from "@/components/ui/ArticleTitle/ArticleTitle";
import { path } from "@/routes";

export const PersonalSurveyCompletePage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Box className="nid-article-header mb-10">
      <Box className="mb-20">
        <ArticleTitle
          testId="article-personal-survey-complete"
          title={t("personal.edit.survey.complete.title")}
        >
          <Typography as="p" className="nid-article-description">
            {t("personal.edit.survey.complete.description")}
          </Typography>
        </ArticleTitle>
      </Box>

      <Box className="nid-article-header-inner">
        <LinkButton to={path.personal.root} data-testid="submit" size="full">
          {t("personal.edit.button.complete")}
        </LinkButton>
      </Box>
    </Box>
  );
};
