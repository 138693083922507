import { useEffect } from "react";

import { Box, LinkButton, Typography } from "nikkei-ui";
import { useFormContext } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import ArticleTitle from "@/components/ui/ArticleTitle/ArticleTitle";
import type { PersonalEmailChangeValues } from "@/features/Personal/EmailChange/PersonalEmailChangeFormProvider";
import { path } from "@/routes";

const PersonalEmailChangeCompletePage = (): JSX.Element => {
  const { t } = useTranslation();
  const { getValues } = useFormContext<PersonalEmailChangeValues>();
  const email = getValues().email;
  const navigate = useNavigate();
  useEffect(() => {
    if (!email) navigate(path.personal.root, { replace: true });
  }, [email]);

  if (!email) return <></>;

  return (
    <Box className="nid-article-header mb-10">
      <Box className="mb-20">
        <ArticleTitle
          testId="article-personal-email-change-complete"
          title={t("personal.edit.email_change.complete.title")}
        >
          <Typography as="p" className="nid-article-description">
            <Trans
              t={t}
              i18nKey="personal.edit.email_change.complete.description"
              values={{ email: getValues().email }}
              shouldUnescape={true}
              components={[
                <Typography
                  key="email"
                  as="span"
                  bold
                  data-dd-privacy="mask"
                />,
              ]}
            />
          </Typography>
        </ArticleTitle>
      </Box>

      <Box className="nid-article-header-inner">
        <LinkButton to={path.personal.root} data-testid="submit" size="full">
          {t("personal.edit.button.complete")}
        </LinkButton>
      </Box>
    </Box>
  );
};

export default PersonalEmailChangeCompletePage;
