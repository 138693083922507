import { path } from "@/routes";
import { Box, Typography } from "nikkei-ui";
import type React from "react";
import { useTranslation } from "react-i18next";
import { NavigationItem } from "../Navigation/NavigationItem";
import styles from "./OtherProcedures.module.css";

export const OtherProcedures: React.FC = () => {
  const { t } = useTranslation();

  const navList = [
    {
      title: t("account_top.link.payment_histories"),
      to: path.payments.histories.root,
      testId: "link-payment-histories",
    },
    {
      title: t("account_top.link.newsletters"),
      to: path.newsletters.root,
      testId: "link-newsletters",
    },
    {
      title: t("account_top.link.withdraw"),
      to: path.withdraw.root,
      testId: "link-withdraw",
    },
  ];

  return (
    <Box className={styles.otherProcedures}>
      <Box>
        <Typography variant="h4" bold>
          {t("account_top.title.other_rocedures")}
        </Typography>
      </Box>
      <Box>
        {navList.map((item, index) => {
          return (
            <NavigationItem
              key={item.title}
              label={item.title}
              to={item.to}
              noBorder={index === navList.length - 1}
              testId={item.testId}
            />
          );
        })}
      </Box>
    </Box>
  );
};
