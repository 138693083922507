import type React from "react";

import { Box } from "nikkei-ui";

import type { ClickableTag } from "./ClickableTag";

/* DISPLAY_NAME */
const DISPLAY_NAME = "Nid.Tag";

type TagProps = React.HTMLAttributes<HTMLSpanElement> & {
  variant?: "default" | "primary" | "success" | "warning" | "error";
  size?: "small" | "medium" | "large";
  bold?: boolean;
  text: string;
  className?: string;
  icon?: React.ReactNode;
};

type TagType = React.ForwardRefExoticComponent<
  TagProps & React.RefAttributes<HTMLElement>
> & {
  Clickable: typeof ClickableTag;
};

const Tag: React.FC<TagProps> = (tagProps) => {
  const {
    variant = "default",
    size = "small",
    bold,
    className = "",
    text,
    icon,
    ...props
  } = tagProps;
  const nidClasses = `nid-tag nid-tag-${variant} nid-tag-${size} ${
    bold ? "nid-tag-bold" : ""
  } ${className}`;

  const iconNode = icon || null;

  return (
    <Box as={iconNode ? "div" : "span"} className={nidClasses} {...props}>
      {iconNode ? (
        <>
          {iconNode}
          <span>{text}</span>
        </>
      ) : (
        text
      )}
    </Box>
  );
};

Tag.displayName = DISPLAY_NAME;

const Root = Tag;

export { Tag, Root };
export type { TagProps, TagType };
export default Tag;
