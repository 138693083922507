import amexCard from "@/components/icon/AmexCardIcon.png";
import dinersClubCard from "@/components/icon/DinersClubCardIcon.png";
import jcbCard from "@/components/icon/JcbCardIcon.png";
import masterCard from "@/components/icon/MasterCardIcon.png";
import noBrandCard from "@/components/icon/NoBrandCreditCardIcon.svg";
import visaCard from "@/components/icon/VisaCardIcon.png";
import type { CreditCardBrand } from "@/hooks/usePaymentCards";
import type React from "react";

export const CreditCardIcon: React.FC<{
  brand: CreditCardBrand;
  className?: string;
}> = (props) => {
  const cards = {
    AmericanExpress: amexCard,
    DinersClub: dinersClubCard,
    JCB: jcbCard,
    Mastercard: masterCard,
    Visa: visaCard,
    Unknown: noBrandCard,
  };
  return (
    <img
      src={cards[props.brand]}
      height={"100%"}
      alt={`Credit Card Brand Logo - ${props.brand}`}
      className={props.className}
    />
  );
};
