import { Box } from "nikkei-ui";
import { useTranslation } from "react-i18next";

import type React from "react";
import styles from "../CardPaymentHistories.module.css";
import { TotalAmount } from "./PaymentInvoiceItem";
import type { TotalAmountProps } from "./PaymentInvoiceItem";
import { PaymentUsageDateDisplay } from "./PaymentUsageDateDisplay";

type PaymentInvoiceProps = TotalAmountProps & {
  year: number;
  month: number;
  date: number;
  children: React.ReactNode;
};

export const PaymentInvoice = ({
  year,
  month,
  date,
  totalAmount,
  tax,
  taxAmount,
  children,
}: PaymentInvoiceProps) => {
  const { t } = useTranslation();
  return (
    <Box className={`nid-column-item-initial ${styles.sectionInvoiceInnerGap}`}>
      {/* 利用日 PC View */}
      <Box className="nid-hidden-mobile">
        <PaymentUsageDateDisplay
          billingDate={t("payments.histories.detail.billing_date", {
            year,
            month,
            date,
          })}
        />
      </Box>
      {/* 利用サービス, 値引き・クーポン, セット割引, 補正 */}
      {children}
      {/* 合計 */}
      <TotalAmount
        totalAmount={totalAmount}
        tax={tax}
        taxAmount={taxAmount}
        isDomesticPayment={!!tax}
      />
    </Box>
  );
};
