import {
  Box,
  Button,
  ExternalLinkIcon,
  InfoCircleFillIcon,
  Link,
  Modal,
  Typography,
} from "nikkei-ui";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import styles from "./HelperTextWithModal.module.css";

const helpGooglePlayStore = import.meta.env.VITE_URL_NIKKEI_ID_HELP_GOOGLE_PLAY;
const helpAppStore = import.meta.env.VITE_URL_NIKKEI_ID_HELP_APP_STORE;

export const HelperTextWithModal = () => {
  const { t } = useTranslation();
  const [isModalOpen, setModalOpen] = useState(false);
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);
  return (
    <>
      <Box className="nid-row mt-4">
        <InfoCircleFillIcon width={16} height={16} role="img" />
        <Typography as="p" variant="caption">
          <Trans
            t={t}
            i18nKey="services.list.helper_text"
            components={[
              <Link
                key="services-helper-text"
                onClick={openModal}
                newTab={false}
                variant="caption"
                data-testid="services-helper-text"
              />,
            ]}
          />
        </Typography>
      </Box>
      <Modal
        title={t("services.list.modal.title")}
        isOpen={isModalOpen}
        onClose={closeModal}
        testId="services-list-help-modal"
      >
        <Box className="nid-column-item-start nid-item-gap24">
          <Typography as="span">
            {t("services.list.modal.description")}
          </Typography>
          <Box className="nid-column-item-start nid-item-gap16">
            <Link
              href={helpGooglePlayStore}
              className={`${styles.textLink} nid-item-gap4`}
              newTab
            >
              {t("services.list.modal.help_google_play")}
              <ExternalLinkIcon />
            </Link>
            <Link
              href={helpAppStore}
              className={`${styles.textLink} nid-item-gap4`}
              newTab
            >
              {t("services.list.modal.help_apple_store")}
              <ExternalLinkIcon />
            </Link>
          </Box>
          <Button
            size={"full"}
            data-testid={"close-services-list-help-modal"}
            type={"button"}
            onClick={closeModal}
          >
            {t("services.list.modal.close")}
          </Button>
        </Box>
      </Modal>
    </>
  );
};
