import type React from "react";

import { Box, ButtonBase as Button, Link, Typography } from "nikkei-ui";
import { useTranslation } from "react-i18next";

import { path } from "@/routes";

import { ContentSkeletonLoader } from "nid-common";
import { useSecurityMfaDisableFeature } from "./hooks/useSecurityMfaDisableFeature";

export const SecurityMfaDisableFeature: React.FC = () => {
  const { t } = useTranslation();
  const { handleDisableMfa, isDisabling, loading } =
    useSecurityMfaDisableFeature();

  if (loading) {
    return <ContentSkeletonLoader />;
  }

  return (
    <Box className="nid-section-inner">
      <Button
        size="full"
        data-testid="button-disable"
        onClick={handleDisableMfa}
        disabled={isDisabling}
      >
        {t("security.mfa.disable.button.disable")}
      </Button>
      <Link
        to={path.security.mfa.root}
        className="nid-button-back"
        data-testid="button-back"
      >
        <Typography color="link" variant="subtitle1">
          {t("security.mfa.disable.button.back")}
        </Typography>
      </Link>
    </Box>
  );
};
