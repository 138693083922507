import { Suspense, lazy } from "react";
import { Outlet, type RouteObject } from "react-router-dom";

import { AppLayout } from "@/components/ui/Layout/AppLayout";
import { ErrorPage } from "@/pages/Error";
import { NotFoundPage } from "@/pages/Error/notFound";

import { PersonalPage } from "@/pages/Personal";
import PersonalAddressPage from "@/pages/Personal/Address";
import PersonalAddressCompletePage from "@/pages/Personal/Address/complete";
import PersonalAddressConfirmPage from "@/pages/Personal/Address/confirm";
import PersonalAddressPageWrapper from "@/pages/Personal/Address/wrapper";
import PersonalEmailChangePage from "@/pages/Personal/Email/Change";
import PersonalEmailChangeCompletePage from "@/pages/Personal/Email/Change/complete";
import PersonalChangeEmailConfirmPage from "@/pages/Personal/Email/Change/confirm";
import PersonalEmailChangePageWrapper from "@/pages/Personal/Email/Change/wrapper";
import PersonalInfoPage from "@/pages/Personal/Info";
import PersonalInfoCompletePage from "@/pages/Personal/Info/complete";
import PersonalInfoConfirmPage from "@/pages/Personal/Info/confirm";
import PersonalInfoPageWrapper from "@/pages/Personal/Info/wrapper";
import PersonalJobInfoInputPage from "@/pages/Personal/Job";
import PersonalJobInfoCompletePage from "@/pages/Personal/Job/complete";
import PersonalJobInfoConfirmPage from "@/pages/Personal/Job/confirm";
import PersonalJobInfoPageWrapper from "@/pages/Personal/Job/wrapper";
import { PersonalOfficePage } from "@/pages/Personal/Office";
import { PersonalOfficeCompletePage } from "@/pages/Personal/Office/complete";
import { PersonalOfficeConfirmPage } from "@/pages/Personal/Office/confirm";
import { PersonalOfficePageWrapper } from "@/pages/Personal/Office/wrapper";
import { PersonalSurveyPage } from "@/pages/Personal/Survey";
import { PersonalSurveyCompletePage } from "@/pages/Personal/Survey/complete";
import { PersonalSurveyConfirmPage } from "@/pages/Personal/Survey/confirm";
import { PersonalSurveyPageWrapper } from "@/pages/Personal/Survey/wrapper";
import { SecurityPage } from "@/pages/Security";
import SecurityMfaTopPage from "@/pages/Security/Mfa";
import SecurityMfaAuthenticatorPage from "@/pages/Security/Mfa/Authenticator";
import SecurityMfaAuthenticatorConfirmPage from "@/pages/Security/Mfa/Authenticator/confirm";
import SecurityMfaAuthenticatorPageWrapper from "@/pages/Security/Mfa/Authenticator/wrapper";
import SecurityMfaDisableCompletePage from "@/pages/Security/Mfa/Disable/complete";
import SecurityMfaDisablePage from "@/pages/Security/Mfa/Disable/index";
import SecurityMfaBackupCodePage from "@/pages/Security/Mfa/backupCode";
import SecurityMfaCompletePage from "@/pages/Security/Mfa/complete";
import AccountTopPage from "@/pages/index";

import SecurityMfaEmailPage from "@/pages/Security/Mfa/Email";
import SecurityMfaEmailConfirmPage from "@/pages/Security/Mfa/Email/confirm";
import SecurityMfaEmailPageWrapper from "@/pages/Security/Mfa/Email/wrapper";
import SecurityMfaMethodPage from "@/pages/Security/Mfa/method";
import { SecurityMfaPageWrapper } from "@/pages/Security/Mfa/wrapper";
import SecurityPasswordChangeInputPage from "@/pages/Security/Password/Change";
import SecurityPasswordChangeCompletePage from "@/pages/Security/Password/Change/complete";
import SecurityPasswordChangePageWrapper from "@/pages/Security/Password/Change/wrapper";

import ServicesTopPage from "@/pages/Services/Top/index";

import { NewslettersPage } from "@/pages/Services/Newsletters";
import ServicePage from "@/pages/Services/Service/index";

import { NkdkMaintenancePage } from "@/pages/Error/nkdkMaintenance";
import PaymentsPage from "@/pages/Payments";
import PaymentsDeletePage from "@/pages/Payments/Delete";
import PaymentsDeleteCompletePage from "@/pages/Payments/Delete/complete";
import PaymentsDeletePageWrapper from "@/pages/Payments/Delete/wrapper";
import { PaymentHistoriesPage } from "@/pages/Payments/Histories";
import { PaymentHistoryDetailPage } from "@/pages/Payments/Histories/detail";
import PaymentsUpdateCardPage from "@/pages/Payments/Update/Card";
import PaymentsUpdateServicePage from "@/pages/Payments/Update/Service";
import PaymentsUpdateCompletePage from "@/pages/Payments/Update/complete";
import { SecurityPasskeyPageWrapper } from "@/pages/Security/Passkeys/wrapper";
import { WithdrawPage } from "@/pages/Withdraw";
import { WithdrawCompletePage } from "@/pages/Withdraw/complete";
import { WithdrawPageWrapper } from "@/pages/Withdraw/wrapper";
import { path, enableSecurityPasskey, enableServiceContract } from ".";
import { ErrorBoundary } from "./ErrorBoundary";

// featureFlagが外れたらlazyを使わなくてもいい
const ServiceContractPage = lazy(
  () => import("@/pages/Services/Service/Contract"),
);
const ServiceContractCancelPage = lazy(
  () => import("@/pages/Services/Service/Contract/Cancel"),
);
const ServiceContractCancelConfirmPage = lazy(
  () => import("@/pages/Services/Service/Contract/Cancel/confirm"),
);
const ServiceContractCancelCompletePage = lazy(
  () => import("@/pages/Services/Service/Contract/Cancel/complete"),
);
const showFeatureRoutes = () => {
  if (enableServiceContract && path.services.contract) {
    return [
      {
        path: path.services.contract.root(":clientId"),
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <Outlet />
          </Suspense>
        ),
        children: [
          {
            index: true,
            element: <ServiceContractPage />,
          },
          {
            path: path.services.contract.cancel.root(":clientId"),
            children: [
              {
                index: true,
                element: <ServiceContractCancelPage />,
              },
              {
                path: path.services.contract.cancel.confirm(":clientId"),
                element: <ServiceContractCancelConfirmPage />,
              },
              {
                path: path.services.contract.cancel.complete(":clientId"),
                element: <ServiceContractCancelCompletePage />,
              },
            ],
          },
          {
            path: path.services.contract.maintenance(":clientId"),
            element: <NkdkMaintenancePage />,
          },
        ],
      },
    ];
  }
  return [];
};

const SecurityPasskeysPage = lazy(() => import("@/pages/Security/Passkeys"));
const SecurityPasskeyCreatedPage = lazy(
  () => import("@/pages/Security/Passkeys/created"),
);
const showFeaturePassKeyRoutes = () => {
  if (enableSecurityPasskey && path.security.passkeys) {
    return [
      {
        path: path.security.passkeys.root,
        element: (
          <Suspense fallback={<div />}>
            <SecurityPasskeyPageWrapper />
          </Suspense>
        ),
        children: [
          { index: true, element: <SecurityPasskeysPage /> },
          {
            path: path.security.passkeys.created,
            element: <SecurityPasskeyCreatedPage />,
          },
        ],
      },
    ];
  }
  return [];
};

export const publicRoutes: RouteObject[] = [
  {
    errorElement: <ErrorBoundary />,
    path: "/",
    element: <AppLayout />,
    children: [
      {
        index: true,
        element: <AccountTopPage />,
      },
      {
        path: path.personal.root,
        children: [
          { index: true, element: <PersonalPage /> },
          {
            path: path.personal.emailChange.root,
            element: <PersonalEmailChangePageWrapper />,
            children: [
              { index: true, element: <PersonalEmailChangePage /> },
              {
                path: path.personal.emailChange.confirm,
                element: <PersonalChangeEmailConfirmPage />,
              },
              {
                path: path.personal.emailChange.complete,
                element: <PersonalEmailChangeCompletePage />,
              },
            ],
          },
          {
            path: path.personal.info.root,
            element: <PersonalInfoPageWrapper />,
            children: [
              { index: true, element: <PersonalInfoPage /> },
              {
                path: path.personal.info.confirm,
                element: <PersonalInfoConfirmPage />,
              },
              {
                path: path.personal.info.complete,
                element: <PersonalInfoCompletePage />,
              },
            ],
          },
          {
            path: path.personal.address.root,
            element: <PersonalAddressPageWrapper />,
            children: [
              { index: true, element: <PersonalAddressPage /> },
              {
                path: path.personal.address.confirm,
                element: <PersonalAddressConfirmPage />,
              },
              {
                path: path.personal.address.complete,
                element: <PersonalAddressCompletePage />,
              },
            ],
          },
          {
            path: path.personal.office.root,
            element: <PersonalOfficePageWrapper />,
            children: [
              { index: true, element: <PersonalOfficePage /> },
              {
                path: path.personal.office.confirm,
                element: <PersonalOfficeConfirmPage />,
              },
              {
                path: path.personal.office.complete,
                element: <PersonalOfficeCompletePage />,
              },
            ],
          },
          {
            path: path.personal.survey.root,
            element: <PersonalSurveyPageWrapper />,
            children: [
              { index: true, element: <PersonalSurveyPage /> },
              {
                path: path.personal.survey.confirm,
                element: <PersonalSurveyConfirmPage />,
              },
              {
                path: path.personal.survey.complete,
                element: <PersonalSurveyCompletePage />,
              },
            ],
          },
          {
            path: path.personal.jobInfo.root,
            element: <PersonalJobInfoPageWrapper />,
            children: [
              { index: true, element: <PersonalJobInfoInputPage /> },
              {
                path: path.personal.jobInfo.confirm,
                element: <PersonalJobInfoConfirmPage />,
              },
              {
                path: path.personal.jobInfo.complete,
                element: <PersonalJobInfoCompletePage />,
              },
            ],
          },
        ],
      },
      {
        path: path.payments.root,
        children: [
          { index: true, element: <PaymentsPage /> },
          {
            children: [
              {
                path: path.payments.update.card(":cardId"),
                element: <PaymentsUpdateCardPage />,
              },
              {
                path: path.payments.update.service(":clientId"),
                element: <PaymentsUpdateServicePage />,
              },
              {
                path: path.payments.update.complete,
                element: <PaymentsUpdateCompletePage />,
              },
            ],
          },
          {
            element: <PaymentsDeletePageWrapper />,
            children: [
              {
                path: path.payments.delete.root(":cardId"),
                element: <PaymentsDeletePage />,
              },
              {
                path: path.payments.delete.complete,
                element: <PaymentsDeleteCompletePage />,
              },
            ],
          },
          {
            path: path.payments.histories.root,
            children: [
              { index: true, element: <PaymentHistoriesPage /> },
              {
                path: path.payments.histories.detail(":encodedPaymentNo"),
                element: <PaymentHistoryDetailPage />,
              },
            ],
          },
          {
            path: path.payments.maintenance,
            element: <NkdkMaintenancePage />,
          },
        ],
      },
      {
        path: path.security.root,
        children: [
          { index: true, element: <SecurityPage /> },
          ...showFeaturePassKeyRoutes(),
          {
            path: path.security.passwordChange.root,
            element: <SecurityPasswordChangePageWrapper />,
            children: [
              {
                index: true,
                element: <SecurityPasswordChangeInputPage />,
              },
              {
                path: path.security.passwordChange.complete,
                element: <SecurityPasswordChangeCompletePage />,
              },
            ],
          },
          {
            path: path.security.mfa.root,
            element: <SecurityMfaPageWrapper />,
            children: [
              { index: true, element: <SecurityMfaTopPage /> },
              {
                path: path.security.mfa.method,
                element: <SecurityMfaMethodPage />,
              },
              {
                path: path.security.mfa.email.root,
                element: <SecurityMfaEmailPageWrapper />,
                children: [
                  { index: true, element: <SecurityMfaEmailPage /> },
                  {
                    path: path.security.mfa.email.confirm,
                    element: <SecurityMfaEmailConfirmPage />,
                  },
                ],
              },
              {
                path: path.security.mfa.authenticator.root,
                element: <SecurityMfaAuthenticatorPageWrapper />,
                children: [
                  {
                    index: true,
                    element: <SecurityMfaAuthenticatorPage />,
                  },
                  {
                    path: path.security.mfa.authenticator.confirm,
                    element: <SecurityMfaAuthenticatorConfirmPage />,
                  },
                ],
              },
              {
                path: path.security.mfa.disable.root,
                children: [
                  { index: true, element: <SecurityMfaDisablePage /> },
                  {
                    path: path.security.mfa.disable.complete,
                    element: <SecurityMfaDisableCompletePage />,
                  },
                ],
              },
              {
                path: path.security.mfa.complete,
                element: <SecurityMfaCompletePage />,
              },
              {
                path: path.security.mfa.backupCode,
                element: <SecurityMfaBackupCodePage />,
              },
            ],
          },
        ],
      },
      {
        path: path.services.root,
        children: [
          {
            index: true,
            element: <ServicesTopPage />,
          },
          {
            path: path.services.service(":clientId"),
            children: [
              { index: true, element: <ServicePage /> },
              ...showFeatureRoutes(),
            ],
          },
        ],
      },
      {
        path: path.newsletters.root,
        element: <NewslettersPage />,
      },
      {
        path: path.withdraw.root,
        element: <WithdrawPageWrapper />,
        children: [
          {
            index: true,
            element: <WithdrawPage />,
          },
          {
            path: path.withdraw.complete,
            element: <WithdrawCompletePage />,
          },
        ],
      },
      {
        path: path.error.root,
        element: <ErrorPage />,
      },
      {
        path: path.error.notFound,
        element: <NotFoundPage />,
      },
      {
        path: "*",
        element: <NotFoundPage />,
      },
    ],
  },
];
