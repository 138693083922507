import { CreditCardIcon } from "@/components/icon/CreditCardIcon";
import type {
  CreditCardBrand,
  PaymentsCardStatus,
} from "@/hooks/usePaymentCards";
import { Box, Typography } from "nikkei-ui";
import type React from "react";
import { useTranslation } from "react-i18next";
import styles from "./CurrentCreditCard.module.css";

type Props = {
  last4Number: string;
  brand: CreditCardBrand;
  expire: {
    month: string;
    year: string;
  };
  servicesInUse: { name: string; planName?: string }[];
  cardStatus: PaymentsCardStatus;
};

export const CurrentCreditCard: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  return (
    <Box>
      <Box className={"pb-3"}>
        <Typography as="h3" variant={"h5"} bold className={"mb-1"}>
          {t("payments.update.credit_card.number")}
        </Typography>
        <Typography
          variant={"h4"}
          data-testid={"credit-card-number"}
          color={props.cardStatus !== "valid" ? "error" : undefined}
        >{`•••• ${props.last4Number}`}</Typography>
      </Box>
      <hr className="nid-separator" />
      <Box className={"my-3"}>
        <Typography as="h3" variant={"h5"} bold className={"mb-1"}>
          {t("payments.update.credit_card.brand")}
        </Typography>
        <Box className={styles.brand}>
          <Box className={`${styles.brandIconBox} mr-2`}>
            <CreditCardIcon brand={props.brand} className={styles.brandIcon} />
          </Box>
          <Typography variant={"h4"} data-testid={"credit-card-brand"}>
            {props.brand}
          </Typography>
        </Box>
      </Box>
      <hr className="nid-separator" />
      <Box className={"my-3"}>
        <Typography as="h3" variant={"h5"} bold className={"mb-1"}>
          {t("payments.update.credit_card.expire")}
        </Typography>
        <Typography
          variant={"h4"}
          data-testid={"credit-card-expire"}
        >{`${props.expire.month}/${props.expire.year}`}</Typography>
      </Box>
      <hr className="nid-separator" />
      <Box className={"mt-3"}>
        <Typography as="h3" variant={"h5"} bold className={"mb-1"}>
          {t("payments.update.credit_card.services_in_use")}
        </Typography>
        <ul>
          {props.servicesInUse.map((service, index) => {
            const listClass =
              props.cardStatus === "unpaid"
                ? `${styles.li} ${styles.errorList}`
                : styles.li;
            return (
              <li key={service.name} className={listClass}>
                <Typography
                  as="span"
                  variant={"h4"}
                  data-testid={`credit-card-service-${index}`}
                >
                  {service.name}
                  {service.planName && <>（{service.planName}）</>}
                </Typography>
              </li>
            );
          })}
        </ul>
      </Box>
    </Box>
  );
};
