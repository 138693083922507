import type React from "react";

import { useTranslation } from "react-i18next";

import ArticleTitle from "@/components/ui/ArticleTitle/ArticleTitle";
import { PersonalSurveyConfirmFeature } from "@/features/Personal/Survey/PersonalSurveyConfirmFeature";
import { Typography } from "nikkei-ui";

export const PersonalSurveyConfirmPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <ArticleTitle
        testId="article-personal-survey-confirm"
        title={t("personal.edit.survey.confirm.title")}
      >
        <Typography as="p" className="nid-article-description">
          {t("personal.edit.survey.confirm.description")}
        </Typography>
      </ArticleTitle>

      <PersonalSurveyConfirmFeature />
    </>
  );
};
