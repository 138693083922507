import axios, { type AxiosResponse } from "axios";
import { ACCOUNT_API_CONTRACT_DETAIL } from "./apiPaths";

export type ContractDetailResponse = {
  contract_info_group: {
    service_id: string;
    service_name: string;
    plan_id?: string;
    plan_name?: string;
    service_free_type?: "1" | "2" | "3" | "4";
    plan_charge_type?: "1" | "2";
    plan_start_date?: string;
    charged_plan_start_date?: string;
    next_charge_date?: string;
    trial_info: {
      trial_plan_id: string;
      trial_end_datetime: string;
    };
    first_free_info: {
      first_free_end_date: string;
      first_free_end_datetime: string;
    };
    after_free_payment?: {
      payment_date: string;
      price_type: "1" | "2";
      set_discount_price_type: "1" | "2";
      unit_price?: number;
      set_discounted_unit_price?: number;
      days?: number;
      price: number;
      set_discounted_price?: number;
      set_discount_flg?: "0" | "1";
    };
    plan_price_info?: {
      price: number;
      set_discounted_price?: number;
      set_discount_flg?: "0" | "1";
      monthly_payment_day?: number;
    };
    applied_free_coupon_info: {
      free_coupon_name: string;
      free_months: number;
      free_coupon_end_date: string;
    };
    applied_coupon_info?: {
      coupon_name: string;
      discount_price: number;
      discount_months: number;
      discount_end_date: string;
      discount_penalty_flg: "0" | "1";
      discount_penalty_months?: number;
      discount_penalty_end_date?: string;
      discount_penalty_calc_type?: "01" | "02";
      discount_penalty_price?: number;
    };
    latest_charged_plan_info?: {
      charged_plan_end_date?: string;
      charged_plan_end_date_nikkei_id_payment_commit?: string;
    };
    reservation_info?: {
      reservation_type: "1" | "2";
      alteration_info: {
        service_id: string;
        service_name: string;
        plan_id: string;
        plan_name: string;
        service_free_type: "1" | "2" | "3" | "4";
        plan_charge_type: "1" | "2";
        apply_date?: string;
        apply_datetime?: string;
        first_free_info: {
          first_free_end_date: string;
          first_free_end_datetime: string;
        };
        after_free_payment?: {
          payment_date: string;
          price_type: "1" | "2";
          set_discount_price_type: "1" | "2";
          unit_price?: number;
          set_discounted_unit_price?: number;
          days: number;
          price: number;
          set_discounted_price: number;
          set_discount_flg: "0" | "1";
        };
        plan_price_info?: {
          price: number;
          set_discounted_price: number;
          set_discount_flg: "0" | "1";
          price_date?: number;
        };
        applied_free_coupon_info: {
          free_coupon_name: string;
          free_months: number;
          free_coupon_end_date: string;
        };
        applied_coupon_info: {
          coupon_name: string;
          discount_price: number;
          discount_months: number;
          discount_end_date: string;
          discount_penalty_flg: "0" | "1";
          discount_penalty_months?: number;
          discount_penalty_end_date?: string;
          discount_penalty_calc_type?: "01" | "02";
          discount_penalty_price?: number;
        };
      };
      termination_info?: {
        service_id: string;
        service_name: string;
        plan_id: string;
        plan_name: string;
        service_free_type: "1" | "2" | "3" | "4";
        plan_charge_type: "1" | "2";
        apply_date: string;
        apply_datetime: string;
        first_month_penalty_info?: {
          payment_date: string;
          price: number;
          set_discounted_price: number;
          set_discount_flg: "0" | "1";
        };
      };
      discount_penalty_info?: {
        payment_date: string;
        price: number;
      };
    };
    contract_exclusive_id: string;
  }[];
};

const getUrl = (serviceId: string): string => {
  return ACCOUNT_API_CONTRACT_DETAIL.replace("<client_id>", serviceId);
};

export const getContractDetail = async (
  serviceId: string,
): Promise<AxiosResponse<ContractDetailResponse>> => {
  return axios.get(getUrl(serviceId), {
    withCredentials: true,
  });
};
