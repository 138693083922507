import {
  Box,
  ExternalLinkIcon,
  InfoCircleFillIcon,
  Link,
  Typography,
} from "nikkei-ui";

import { useTranslation } from "react-i18next";
import styles from "./PersonalAddressNotice.module.css";

export const PersonalAddressNotice = () => {
  const { t } = useTranslation();
  const noticeCard = `${styles.noticeCard} nid-column-item-start nid-item-gap16`;

  return (
    <Box className={styles.noticeContainer}>
      <Box className={noticeCard}>
        <Box className="nid-row">
          <InfoCircleFillIcon />
          <Typography as="p" bold className="ml-1">
            {t("personal.edit.address.notice.caution_title")}
          </Typography>
        </Box>
        <Typography as="p" variant="caption">
          {t("personal.edit.address.notice.newspaper_delivery_notice")}
        </Typography>

        <Link
          href={import.meta.env.VITE_URL_NIKKEI_NEWSPAPER_DELIVERY_CHANGE}
          className={styles.noticeLink}
          newTab
          data-testid="notice-link-newspaper-delivery"
        >
          <Typography as="span" variant="subtitle1">
            {t("personal.edit.address.notice.newspaper_delivery_link")}
          </Typography>
          <ExternalLinkIcon />
        </Link>

        <hr className="w-full nid-separator" />

        <Typography as="p" variant="caption">
          {t("personal.edit.address.notice.nikkei_bp_notice")}
        </Typography>

        <Link
          href={import.meta.env.VITE_URL_NIKKEI_BP_ADDRESS_CHANGE}
          className={styles.noticeLink}
          newTab
          data-testid="notice-link-nikkei-bp"
        >
          <Typography as="span" variant="subtitle1">
            {t("personal.edit.address.notice.nikkei_bp_link")}
          </Typography>
          <ExternalLinkIcon />
        </Link>
      </Box>
    </Box>
  );
};
