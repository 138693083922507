import { getRegisteredRelyingParty } from "nid-common/api/account";
import useSWR from "swr";

export const useRelyingPartyDisplaySettings = (rpid: string) => {
  const r = useSWR(`/account/registered-relying-parties/${rpid}`, () =>
    getRegisteredRelyingParty(rpid),
  );

  if (r.error) {
    return { status: "error" } as const;
  }
  if (r.isLoading) {
    return { status: "loading" } as const;
  }
  if (!r.data) {
    return { status: "not_found" } as const;
  }

  return {
    status: "ok",
    relyingParty: {
      logo: r.data.data.logo,
      name: r.data.data.service_name,
      description: r.data.data.description,
      clientId: r.data.data.client_id,
      links: r.data.data.links,
    },
  } as const;
};
