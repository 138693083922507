import { useCallback } from "react";

type DataInput = string | string[];

export const useFileOperations = () => {
  const handleClickDownloadTextFile = useCallback(
    (data: DataInput, fileName: string, mimeType = "text/plain") => {
      const blobContent = Array.isArray(data)
        ? `${data.join("\n")}\n`
        : `${data}\n`;
      const blob = new Blob([blobContent], { type: mimeType });
      const url = URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();

      // cleanup
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    },
    [],
  );

  const handleClickPrintPage = useCallback(() => {
    window.print();
  }, []);

  return {
    handleClickDownloadTextFile,
    handleClickPrintPage,
  };
};
