import type React from "react";

import { Box, ExternalLinkIcon, Link, LinkButton, Typography } from "nikkei-ui";

import { useTranslation } from "react-i18next";

import styles from "./NewsletterCard.module.css";

interface Props {
  serviceName: string;
  contents: {
    title: string;
    target?: string;
    description: string;
    time?: string;
    link: string;
    linkType: "configuration" | "service_top";
  }[];
}

export const NewsletterCard: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  return (
    <Box as="section" className="nid-section nid-section-overflow mb-6">
      <Box className="nid-section-inner">
        <Typography as="h2" variant="h3" bold>
          {props.serviceName}
        </Typography>
        {props.contents.map((content) => {
          return (
            <Box key={content.title}>
              <hr className="nid-separator" />
              <Box className={styles.header}>
                <Box>
                  <Typography
                    variant="h3"
                    color="accent"
                    bold
                    className="nid-section-title mb-1"
                  >
                    {content.title}
                  </Typography>
                  <Typography
                    className="nid-typography-support-text"
                    color="disabled"
                  >
                    {content.target}
                  </Typography>
                </Box>
                {content.linkType === "configuration" && (
                  <LinkButton
                    href={content.link}
                    variant="border"
                    className={styles.changeButton}
                    newTab
                  >
                    {t("newsletters.index.button.configure")}
                  </LinkButton>
                )}
              </Box>
              <Typography variant="body1">{content.description}</Typography>
              {content.time && (
                <Typography variant="body1">{content.time}</Typography>
              )}
              {content.linkType === "service_top" && (
                <Link
                  href={content.link}
                  className={`${styles.textLink} mt-4`}
                  newTab
                >
                  {t("newsletters.index.button.detail")}
                  <ExternalLinkIcon />
                </Link>
              )}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};
