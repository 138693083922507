import type React from "react";
import { createContext, useContext, useState } from "react";

type PasskeyType = {
  id: string;
  backupEligible: boolean;
  createdAt: Date;
  aaguid: string;
};

type SecurityPasskeyContextType = {
  passkeyData: PasskeyType | undefined;
  setPasskeyData: (data: PasskeyType) => void;
};

export const SecurityPasskeyContext = createContext<
  SecurityPasskeyContextType | undefined
>(undefined);

/**
 * useSecurityPasskeyProvider
 * @returns Context
 */
export const useSecurityPasskeyProvider = () => {
  const context = useContext(SecurityPasskeyContext);
  if (context === undefined) {
    throw new Error(
      "useSecurityPasskeyProvider must be used within a SecurityPasskeyProvider",
    );
  }
  return context;
};

type SecurityPasskeyProviderProps = {
  children: React.ReactNode;
};

/**
 * SecurityPasskeyProvider
 * @param SecurityPasskeyProviderProps
 * @returns
 */
export const SecurityPasskeyProvider: React.FC<
  SecurityPasskeyProviderProps
> = ({ children }) => {
  const [passkeyData, setPasskeyData] =
    useState<SecurityPasskeyContextType["passkeyData"]>(undefined);

  return (
    <SecurityPasskeyContext.Provider value={{ passkeyData, setPasskeyData }}>
      {children}
    </SecurityPasskeyContext.Provider>
  );
};
