import type { NidDate } from "nid-common";
import { useTranslation } from "react-i18next";

export const useUserInfoLabel = () => {
  const { t } = useTranslation();

  const getBirthLabel = (value: NidDate): string => {
    return t("attribute_values.birth", value);
  };

  const getSexLabel = (value: string): string => {
    switch (value) {
      case "1":
        return t("attribute_values.sex.1");
      case "2":
        return t("attribute_values.sex.2");
      case "3":
        return t("attribute_values.sex.3");
      case "4":
        return t("attribute_values.sex.4");
      default:
        return "";
    }
  };

  const getOccupationLabel = (value: string): string => {
    switch (value) {
      case "001":
        return t("attribute_values.occupation.1");
      case "002":
        return t("attribute_values.occupation.2");
      case "003":
        return t("attribute_values.occupation.3");
      case "004":
        return t("attribute_values.occupation.4");
      case "005":
        return t("attribute_values.occupation.5");
      default:
        return "";
    }
  };

  const getBusinessLabel = (value: string): string => {
    switch (value) {
      case "006":
        return t("attribute_values.business.6");
      case "007":
        return t("attribute_values.business.7");
      case "008":
        return t("attribute_values.business.8");
      case "009":
        return t("attribute_values.business.9");
      case "010":
        return t("attribute_values.business.10");
      case "011":
        return t("attribute_values.business.11");
      case "012":
        return t("attribute_values.business.12");
      case "013":
        return t("attribute_values.business.13");
      case "014":
        return t("attribute_values.business.14");
      case "015":
        return t("attribute_values.business.15");
      case "016":
        return t("attribute_values.business.16");
      case "017":
        return t("attribute_values.business.17");
      case "018":
        return t("attribute_values.business.18");
      case "019":
        return t("attribute_values.business.19");
      case "020":
        return t("attribute_values.business.20");
      case "021":
        return t("attribute_values.business.21");
      case "022":
        return t("attribute_values.business.22");
      case "023":
        return t("attribute_values.business.23");
      case "024":
        return t("attribute_values.business.24");
      case "025":
        return t("attribute_values.business.25");
      case "026":
        return t("attribute_values.business.26");
      case "027":
        return t("attribute_values.business.27");
      case "028":
        return t("attribute_values.business.28");
      case "029":
        return t("attribute_values.business.29");
      case "030":
        return t("attribute_values.business.30");
      default:
        return "";
    }
  };

  const getJobLabel = (value: string): string => {
    switch (value) {
      case "004":
        return t("attribute_values.job.4");
      case "005":
        return t("attribute_values.job.5");
      case "006":
        return t("attribute_values.job.6");
      case "007":
        return t("attribute_values.job.7");
      case "008":
        return t("attribute_values.job.8");
      case "009":
        return t("attribute_values.job.9");
      case "010":
        return t("attribute_values.job.10");
      case "011":
        return t("attribute_values.job.11");
      case "012":
        return t("attribute_values.job.12");
      case "013":
        return t("attribute_values.job.13");
      case "014":
        return t("attribute_values.job.14");
      case "015":
        return t("attribute_values.job.15");
      case "016":
        return t("attribute_values.job.16");
      case "017":
        return t("attribute_values.job.17");
      case "018":
        return t("attribute_values.job.18");
      case "019":
        return t("attribute_values.job.19");
      case "020":
        return t("attribute_values.job.20");
      case "021":
        return t("attribute_values.job.21");
      case "022":
        return t("attribute_values.job.22");
      case "023":
        return t("attribute_values.job.23");
      case "024":
        return t("attribute_values.job.24");
      default:
        return "";
    }
  };

  const getPositionLabel = (value: string): string => {
    switch (value) {
      case "001":
        return t("attribute_values.position.1");
      case "002":
        return t("attribute_values.position.2");
      case "003":
        return t("attribute_values.position.3");
      case "004":
        return t("attribute_values.position.4");
      case "005":
        return t("attribute_values.position.5");
      case "006":
        return t("attribute_values.position.6");
      case "007":
        return t("attribute_values.position.7");
      case "008":
        return t("attribute_values.position.8");
      case "009":
        return t("attribute_values.position.9");
      case "010":
        return t("attribute_values.position.10");
      default:
        return "";
    }
  };

  const getEmployeesLabel = (value: string): string => {
    switch (value) {
      case "001":
        return t("attribute_values.employees.1");
      case "002":
        return t("attribute_values.employees.2");
      case "003":
        return t("attribute_values.employees.3");
      case "004":
        return t("attribute_values.employees.4");
      case "005":
        return t("attribute_values.employees.5");
      case "006":
        return t("attribute_values.employees.6");
      case "007":
        return t("attribute_values.employees.7");
      case "008":
        return t("attribute_values.employees.8");
      case "009":
        return t("attribute_values.employees.9");
      case "010":
        return t("attribute_values.employees.10");
      case "011":
        return t("attribute_values.employees.11");
      default:
        return "";
    }
  };

  const getIncomeLabel = (value: string): string => {
    switch (value) {
      case "001":
        return t("attribute_values.income.1");
      case "002":
        return t("attribute_values.income.2");
      case "003":
        return t("attribute_values.income.3");
      case "004":
        return t("attribute_values.income.4");
      case "005":
        return t("attribute_values.income.5");
      case "006":
        return t("attribute_values.income.6");
      case "007":
        return t("attribute_values.income.7");
      case "008":
        return t("attribute_values.income.8");
      default:
        return "";
    }
  };

  const getZipCodeLabel = (value: string) => {
    if (value.length === 7) {
      return `〒${value.substring(0, 3)}-${value.substring(3, 7)}`;
    }
    return value;
  };

  const getCompanyTypeLabel = (value: string): string => {
    switch (value) {
      case "01":
        return t("attribute_values.company_type.1");
      case "02":
        return t("attribute_values.company_type.2");
      case "03":
        return t("attribute_values.company_type.3");
      case "04":
        return t("attribute_values.company_type.4");
      case "05":
        return t("attribute_values.company_type.5");
      case "06":
        return t("attribute_values.company_type.6");
      case "07":
        return t("attribute_values.company_type.7");
      default:
        return "";
    }
  };

  return {
    getBirthLabel,
    getSexLabel,
    getOccupationLabel,
    getBusinessLabel,
    getJobLabel,
    getPositionLabel,
    getEmployeesLabel,
    getIncomeLabel,
    getZipCodeLabel,
    getCompanyTypeLabel,
  };
};
