import type React from "react";

export const SecurityIcon: React.FC<React.ComponentProps<"svg">> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="27"
    height="27"
    viewBox="0 0 27 27"
    fill="none"
    aria-label="Icon of Security"
    aria-hidden="true"
    {...props}
  >
    <g clipPath="url(#clip0_16898_2710)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.776 11.5287L12.8332 18.4715L7.89038 13.5287L9.776 11.6431L12.8332 14.7003L17.8904 9.64307L19.776 11.5287Z"
        fill="#0068BC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4999 0.505371L26.2331 4.99947L26.1634 6.01105L24.8332 5.91931C26.1634 6.01105 26.1634 6.01057 26.1634 6.01105L26.1632 6.01327L26.1629 6.01727L26.162 6.03049L26.1584 6.07754C26.1553 6.11791 26.1507 6.17611 26.1442 6.25095C26.1313 6.40061 26.1113 6.61695 26.0823 6.89047C26.0244 7.43724 25.9306 8.21405 25.7862 9.1446C25.4983 11.0002 25.0053 13.4929 24.1836 16.0011C23.3671 18.4936 22.1968 21.0932 20.508 23.0923C18.7961 25.1186 16.4927 26.586 13.4999 26.586C10.5071 26.586 8.20363 25.1186 6.49178 23.0923C4.80293 21.0932 3.63266 18.4936 2.81613 16.0011C1.99449 13.4929 1.50149 11.0002 1.21356 9.1446C1.06916 8.21405 0.975355 7.43724 0.917445 6.89047C0.888476 6.61695 0.86845 6.40061 0.855549 6.25095C0.849097 6.17611 0.844424 6.11791 0.841303 6.07754L0.837756 6.03049L0.836802 6.01727L0.836428 6.01194C0.836394 6.01146 0.836366 6.01105 2.16654 5.91931L0.836428 6.01194L0.766602 4.99947L13.4999 0.505371ZM3.59324 6.82971C3.64827 7.32208 3.73041 7.97349 3.84869 8.7357C4.12325 10.5051 4.58859 12.8457 5.35029 15.1709C6.11709 17.5117 7.15515 19.7454 8.5288 21.3713C9.87945 22.9701 11.4927 23.9193 13.4999 23.9193C15.5071 23.9193 17.1203 22.9701 18.4709 21.3713C19.8446 19.7454 20.8827 17.5117 21.6495 15.1709C22.4112 12.8457 22.8765 10.5051 23.1511 8.7357C23.2693 7.97349 23.3515 7.32208 23.4065 6.82971L13.4999 3.33325L3.59324 6.82971Z"
        fill="#0068BC"
      />
    </g>
    <defs>
      <clipPath id="clip0_16898_2710">
        <rect
          width="25.4667"
          height="26.08"
          fill="white"
          transform="translate(0.766602 0.505859)"
        />
      </clipPath>
    </defs>
  </svg>
);
