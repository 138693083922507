import type React from "react";

import { WithdrawFeature } from "@/features/Withdraw/WithdrawFeature";
import { LoginWallProvider } from "nid-common";

export const WithdrawPage: React.FC = () => {
  return (
    <LoginWallProvider maxAge={600}>
      <WithdrawFeature />
    </LoginWallProvider>
  );
};
