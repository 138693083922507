import { path } from "@/routes";
import { useNavigate } from "react-router-dom";

export type CardUpdateAppliedService = {
  clientId: string;
  serviceId: string;
  name: string;
  planName?: string;
};

export const usePaymentsUpdateNavigate = () => {
  const navigate = useNavigate();
  return {
    navigate: (
      services: CardUpdateAppliedService[],
      destinationAfterPaymentsUpdate: string | undefined = undefined,
    ) => {
      navigate(path.payments.update.complete, {
        state: {
          destinationAfterPaymentsUpdate,
          services: services.map((service) => ({
            clientId: service.clientId,
            serviceId: service.serviceId,
            name: service.name,
            planName: service.planName,
          })),
        },
      });
    },
  };
};
