import type { CreditCardBrand } from "@/hooks/usePaymentCards";

export type BlocksType = number[];
export type CreditCardBlocksType = Record<
  CreditCardBrand,
  { format: BlocksType; length: number[] }
>;

/**
 * Credit card number blocks
 * @type {CreditCardBlocksType}
 *
 * クレジットカードの本来の仕様では番号は最長で 19 文字だが、
 * NKDK が利用しているソニーペイメントが 16 文字までしか受け付けていない。
 */
export const CreditCardBlocks: CreditCardBlocksType = {
  Visa: {
    format: [4, 4, 4, 4],
    length: [16],
  },
  Mastercard: {
    format: [4, 4, 4, 4],
    length: [16],
  },
  AmericanExpress: {
    format: [4, 6, 5],
    length: [15],
  },
  JCB: {
    format: [4, 4, 4, 4],
    length: [16],
  },
  DinersClub: {
    format: [4, 6, 4],
    length: [14, 16],
  },
  Unknown: {
    format: [4, 4, 4, 4],
    length: [16],
  },
};
