import { datadogRum } from "@datadog/browser-rum";
import { useEffect } from "react";
import { useNavigate, useRouteError } from "react-router-dom";
import { path } from ".";

export const ErrorBoundary = () => {
  const error = useRouteError();
  const navigate = useNavigate();

  useEffect(() => {
    console.error(error);
    datadogRum.addError(error);
    navigate(`${path.error.root}?code=system_error`);
  }, []);
  return null;
};
