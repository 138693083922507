import { useState } from "react";

import type { AxiosError } from "axios";
import { usePasswordStrengthChecker, validatePassword } from "nid-common";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { path } from "@/routes";
import { datadogRum } from "@datadog/browser-rum";
import { useLeaveApp } from "nid-common";
import { useClearCache } from "nid-common";
import {
  type PasswordErrorResponse,
  postChangePassword,
} from "nid-common/api/account";

type FormValues = {
  password: string;
  passwordRepeat: string;
};

export const useSecurityPasswordChangeInputFeature = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { redirect } = useLeaveApp();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { clearAllSwrCache } = useClearCache();

  const errorMessages = t("security.edit.password_change.error", {
    returnObjects: true,
  });

  const {
    register,
    handleSubmit,
    setError,
    watch,
    formState: { errors },
  } = useForm<FormValues>({
    mode: "onSubmit",
    reValidateMode: "onBlur",
  });

  const { score: passwordStrengthScore, message: passwordStrengthMessage } =
    usePasswordStrengthChecker(watch("password") || "", {
      veryWeak: t("password_strength_checker.very_weak"),
      weak: t("password_strength_checker.weak"),
      normal: t("password_strength_checker.normal"),
      strong: t("password_strength_checker.strong"),
    });

  const onSubmit = async (data: FormValues) => {
    try {
      setIsSubmitting(true);
      const response = await postChangePassword({
        new_password: data.password,
      });
      clearAllSwrCache();
      redirect(response.data.delete_sso_cookies_url);
    } catch (e) {
      const response = (e as AxiosError<PasswordErrorResponse>).response;
      if (response?.data?.error === "invalid_request") {
        setError("password", { message: errorMessages.invalid_request });
      } else {
        datadogRum.addError(e);
        navigate(path.error.root);
      }
      setIsSubmitting(false);
    }
  };

  return {
    register: {
      password: register("password", {
        validate: (value: string) => {
          switch (validatePassword(value)) {
            case "empty":
              return errorMessages.required_password;
            case "too_short_password":
              return errorMessages.min_length;
            case "too_long_password":
              return errorMessages.max_length;
            case "invalid_format":
              return errorMessages.regex_password;
            case "ok":
              return undefined;
          }
        },
      }),
      passwordRepeat: register("passwordRepeat", {
        validate: (value: string) => {
          if (value.length === 0) {
            return errorMessages.required_password;
          }
          if (value.length !== 0 && watch("password") !== value) {
            return errorMessages.password_repeat;
          }
        },
      }),
    },
    errorMessage: {
      password: errors.password?.message,
      passwordRepeat: errors.passwordRepeat?.message,
    },
    disableButton:
      isSubmitting ||
      errors.password?.message !== undefined ||
      errors.passwordRepeat?.message !== undefined,
    handleSubmit: handleSubmit(onSubmit),
    passwordStrengthScore,
    passwordStrengthMessage,
  };
};
