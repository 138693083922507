import { useTranslation } from "react-i18next";

import ArticleTitle from "@/components/ui/ArticleTitle/ArticleTitle";
import { PersonalAddressNotice } from "@/components/ui/PersonalAddressNotice/PersonalAddressNotice";
import PersonalAddressInputFeature from "@/features/Personal/Address/PersonalAddressInputFeature";

export const PersonalAddressPage = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      <ArticleTitle
        testId="article-personal-address-input"
        title={t("personal.edit.address.title")}
      />
      <PersonalAddressNotice />
      <PersonalAddressInputFeature />
    </>
  );
};

export default PersonalAddressPage;
