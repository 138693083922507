import type { CreditCardBrand } from "@/hooks/usePaymentCards";

export const getCardBrand = (number: string): CreditCardBrand => {
  if (number.startsWith("4")) {
    return "Visa";
  }
  if (number.match(/^5[1-5].*/)) {
    return "Mastercard";
  }
  if (number.startsWith("34") || number.startsWith("37")) {
    return "AmericanExpress";
  }
  if (number.startsWith("35")) {
    return "JCB";
  }
  if (number.match(/^(30[1-5,9]|36|38|39).*/)) {
    return "DinersClub";
  }

  return "Unknown";
};
