import * as React from "react";

import { Box } from "nikkei-ui";

import { BreadcrumbItem } from "./BreadcrumbItem";

import type { BreadcrumbItemProps } from "./BreadcrumbItem";

import "./breadcrumbs.css";

const DISPLAY_NAME = "Nid.Breadcrumbs";

type BreadcrumbsProps = {
  className?: string;
  itemClassName?: string;
  separator?: string;
  children: React.ReactNode;
};

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({
  separator,
  className = "",
  itemClassName,
  children,
}) => {
  if (!children) {
    return null;
  }

  // @ts-ignore
  const childArray = children.props.children;
  const size = React.Children.toArray(childArray).length;
  const maxSizes = size - 1;

  const allItems = React.Children.toArray(childArray)
    .filter((child) => {
      return React.isValidElement(child);
    })
    .map((child, index) => (
      <BreadcrumbItem
        // biome-ignore lint/suspicious/noArrayIndexKey: TODO 適切なキーを付ける
        key={index}
        separator={separator}
        isLast={index === maxSizes}
        className={itemClassName}
      >
        {child}
      </BreadcrumbItem>
    ));

  return (
    <Box as="nav" className="nid-breadcrumb">
      <Box as="ol" className={`nid-breadcrumbs ${className}`}>
        {allItems}
      </Box>
    </Box>
  );
};

Breadcrumbs.displayName = DISPLAY_NAME;
const Root = Breadcrumbs;
export { Breadcrumbs, Root, BreadcrumbItem };
export type { BreadcrumbsProps, BreadcrumbItemProps };
export default Breadcrumbs;
