import { useEffect } from "react";

import type { AxiosError } from "axios";
import useSWR from "swr";

import { type NidDate, nidDateToString } from "nid-common";
import {
  type PatchError,
  type PatchUserInfoErrorResponse,
  type PatchErrorCode as ResponsePatchErrorCode,
  type UserInfoPatchRequest,
  patchUserInfo,
} from "nid-common/api/account";

import { type UserInfo, useUserInfo } from "./useUserInfo";

type PatchType<T> = T extends object
  ? { [key in keyof T]?: PatchType<T[key]> }
  : T | null | undefined;

type NotDeletableUserInfoPatch = {
  receiveNikkeiMail?: boolean | undefined;
  receiveThirdPartyMail?: boolean | undefined;
  registerNikkeiMonitor?: boolean | undefined;
  companyCountryFlag?: string | undefined;
  birth?: NidDate | null | undefined;
  domesticAddress?: PatchType<UserInfo["domesticAddress"]> | undefined;
  overseasAddress?: PatchType<UserInfo["overseasAddress"]> | undefined;
  domesticCompany?: PatchType<UserInfo["domesticCompany"]> | undefined;
  overseasCompany?: PatchType<UserInfo["overseasAddress"]> | undefined;
  newsSubscriptions?: UserInfo["newsSubscriptions"] | undefined;
  interest?: UserInfo["interest"] | undefined;
};

export type UserInfoPatch = PatchType<UserInfo> & NotDeletableUserInfoPatch;

const buildUserInfoPatchRequestAttributes = (
  patch: UserInfoPatch,
): UserInfoPatchRequest["attributes"] => {
  function apply<T, U>(
    x: T | null | undefined,
    f: (arg: T) => U,
  ): U | null | undefined {
    return x === null ? null : x === undefined ? undefined : f(x);
  }

  const bool2str = (v: boolean | undefined) =>
    v !== undefined ? (v ? "1" : "0") : undefined;

  const domesticAddress = patch.domesticAddress
    ? {
        ZIP_CODE: patch.domesticAddress.zipCode,
        ADDRESS_CODE: patch.domesticAddress.addressCode,
        ADDRESS1: patch.domesticAddress.address1,
        ADDRESS2: patch.domesticAddress.address2,
        TEL_JOINT: patch.domesticAddress.tel,
      }
    : undefined;

  const overseasAddress = patch.overseasAddress
    ? {
        RESIDENCE_COUNTRY_CODE: patch.overseasAddress.countryCode,
        OVERSEAS_ZIP_CODE: patch.overseasAddress.zipCode,
        OVERSEAS_ADDRESS1: patch.overseasAddress.address1,
        OVERSEAS_ADDRESS2: patch.overseasAddress.address2,
        OVERSEAS_ADDRESS3: patch.overseasAddress.address3,
        OVERSEAS_TEL: patch.overseasAddress.tel,
      }
    : undefined;

  const newsSubscription: UserInfoPatchRequest["attributes"]["NEWS_SUBSCRIPTION"] =
    patch.newsSubscriptions
      ? {
          NEWS_SUBSCRIPTION1: bool2str(
            patch.newsSubscriptions.newsSubscription1,
          ),
          NEWS_SUBSCRIPTION3: bool2str(
            patch.newsSubscriptions.newsSubscription3,
          ),
          NEWS_SUBSCRIPTION4: bool2str(
            patch.newsSubscriptions.newsSubscription4,
          ),
        }
      : undefined;

  const interest: UserInfoPatchRequest["attributes"]["INTEREST"] =
    patch.interest
      ? {
          INTEREST1: bool2str(patch.interest.interest1),
          INTEREST2: bool2str(patch.interest.interest2),
          INTEREST3: bool2str(patch.interest.interest3),
          INTEREST4: bool2str(patch.interest.interest4),
          INTEREST5: bool2str(patch.interest.interest5),
          INTEREST6: bool2str(patch.interest.interest6),
          INTEREST7: bool2str(patch.interest.interest7),
          INTEREST8: bool2str(patch.interest.interest8),
          INTEREST9: bool2str(patch.interest.interest9),
          INTEREST10: bool2str(patch.interest.interest10),
          INTEREST11: bool2str(patch.interest.interest11),
          INTEREST12: bool2str(patch.interest.interest12),
          INTEREST13: bool2str(patch.interest.interest13),
        }
      : undefined;

  const domesticCompany = patch.domesticCompany
    ? {
        COMPANY_NAME: patch.domesticCompany.name,
        COMPANY_NUMBER: patch.domesticCompany.number,
        COMPANY_TYPE_CODE: patch.domesticCompany.typeCode,
        COMPANY_TYPE_LOCATION: patch.domesticCompany.typeLocation,
        COMPANY_ZIP_CODE: patch.domesticCompany.zipCode,
        COMPANY_ADDRESS: patch.domesticCompany.address,
        COMPANY_BUSINESS_UNIT: patch.domesticCompany.businessUnit,
        COMPANY_TEL_JOINT: patch.domesticCompany.tel,
      }
    : undefined;

  const overseasCompany = patch.overseasCompany
    ? {
        COMPANY_OVERSEAS_NAME: patch.overseasCompany.name,
        COMPANY_OVERSEAS_ADDRESS: patch.overseasCompany.address,
        COMPANY_OVERSEAS_TEL: patch.overseasCompany.tel,
      }
    : undefined;

  return {
    LAST_NAME: patch.lastName,
    FIRST_NAME: patch.firstName,
    LAST_NAME_KANA: patch.lastNameKana,
    FIRST_NAME_KANA: patch.firstNameKana,
    RESIDENCE_COUNTRY_FLAG: patch.residenceCountryFlag,
    ...domesticAddress,
    ...overseasAddress,

    SEX: patch.sex,
    BIRTH: patch.birth ? apply(patch.birth, nidDateToString) : undefined,
    OCCUPATION_NO: patch.occupation,
    BUSINESS_NO: patch.business,
    JOB_NO: patch.job,
    POSITION_NO: patch.position,
    EMPLOYEES_NO: patch.employees,
    INCOME_NO: patch.income,
    NEWS_SUBSCRIPTION: newsSubscription,
    INTEREST: interest,

    NIKKEI_MAIL_FLAG: bool2str(patch.receiveNikkeiMail),
    THIRDPARTY_MAIL_FLAG: bool2str(patch.receiveThirdPartyMail),
    NIKKEI_MONITOR_FLAG: bool2str(patch.registerNikkeiMonitor),

    COMPANY_COUNTRY_FLAG: patch.companyCountryFlag,
    ...domesticCompany,
    ...overseasCompany,
  };
};

type UserInfoNestKeys =
  | "domesticAddress"
  | "overseasAddress"
  | "newsSubscriptions"
  | "interest"
  | "domesticCompany"
  | "overseasCompany";
type PatchErrorObject<T> = {
  [key in keyof T]?: key extends UserInfoNestKeys
    ? PatchErrorObject<T[key]>
    : PatchError | undefined;
};

export type PatchErrorCode = ResponsePatchErrorCode;
export type PatchUserInfoError = {
  error: PatchErrorCode;
  status: number;
  attributes: PatchErrorObject<UserInfo>;
};

const buildUserInfoPatchError = (
  error: PatchUserInfoErrorResponse,
  status: number,
): PatchUserInfoError => {
  const errorDetail = error.error_detail;
  return {
    error: error.error,
    status,
    attributes: {
      lastName: errorDetail?.LAST_NAME,
      firstName: errorDetail?.FIRST_NAME,
      lastNameKana: errorDetail?.LAST_NAME_KANA,
      firstNameKana: errorDetail?.FIRST_NAME_KANA,
      residenceCountryFlag: errorDetail?.RESIDENCE_COUNTRY_FLAG,
      domesticAddress: {
        zipCode: errorDetail?.ZIP_CODE,
        addressCode: errorDetail?.ADDRESS_CODE,
        address1: errorDetail?.ADDRESS1,
        address2: errorDetail?.ADDRESS2,
        tel: errorDetail?.TEL_JOINT,
      },
      overseasAddress: {
        countryCode: errorDetail?.RESIDENCE_COUNTRY_CODE,
        zipCode: errorDetail?.OVERSEAS_ZIP_CODE,
        address1: errorDetail?.OVERSEAS_ADDRESS1,
        address2: errorDetail?.OVERSEAS_ADDRESS2,
        address3: errorDetail?.OVERSEAS_ADDRESS3,
        tel: errorDetail?.OVERSEAS_TEL,
      },
      sex: errorDetail?.SEX,
      birth: errorDetail?.BIRTH,
      occupation: errorDetail?.OCCUPATION_NO,
      business: errorDetail?.BUSINESS_NO,
      job: errorDetail?.JOB_NO,
      position: errorDetail?.POSITION_NO,
      employees: errorDetail?.EMPLOYEES_NO,
      income: errorDetail?.INCOME_NO,
      newsSubscriptions: {
        newsSubscription1: errorDetail?.NEWS_SUBSCRIPTION1,
        newsSubscription3: errorDetail?.NEWS_SUBSCRIPTION3,
        newsSubscription4: errorDetail?.NEWS_SUBSCRIPTION4,
      },
      interest: {
        interest1: errorDetail?.INTEREST1,
        interest2: errorDetail?.INTEREST2,
        interest3: errorDetail?.INTEREST3,
        interest4: errorDetail?.INTEREST4,
        interest5: errorDetail?.INTEREST5,
        interest6: errorDetail?.INTEREST6,
        interest7: errorDetail?.INTEREST7,
        interest8: errorDetail?.INTEREST8,
        interest9: errorDetail?.INTEREST9,
        interest10: errorDetail?.INTEREST10,
        interest11: errorDetail?.INTEREST11,
        interest12: errorDetail?.INTEREST12,
        interest13: errorDetail?.INTEREST13,
      },
      receiveNikkeiMail: errorDetail?.NIKKEI_MAIL_FLAG,
      receiveThirdPartyMail: errorDetail?.THIRDPARTY_MAIL_FLAG,
      registerNikkeiMonitor: errorDetail?.NIKKEI_MONITOR_FLAG,
      companyCountryFlag: errorDetail?.COMPANY_COUNTRY_FLAG,
      domesticCompany: {
        name: errorDetail?.COMPANY_NAME,
        number: errorDetail?.COMPANY_NUMBER,
        typeCode: errorDetail?.COMPANY_TYPE_CODE,
        typeLocation: errorDetail?.COMPANY_TYPE_LOCATION,
        zipCode: errorDetail?.COMPANY_ZIP_CODE,
        address: errorDetail?.COMPANY_ADDRESS,
        businessUnit: errorDetail?.COMPANY_BUSINESS_UNIT,
        tel: errorDetail?.COMPANY_TEL_JOINT,
      },
      overseasCompany: {
        name: errorDetail?.COMPANY_OVERSEAS_NAME,
        address: errorDetail?.COMPANY_OVERSEAS_ADDRESS,
        tel: errorDetail?.COMPANY_OVERSEAS_TEL,
      },
    },
  };
};

export const usePatchUserInfo = () => {
  const data = useUserInfo();
  const { data: editData, mutate: editMutate } = useSWR<UserInfoPatch>(
    "/account/userinfo:edit",
    null,
  );
  useEffect(() => {
    if (data.status === "ok" && !editData) {
      editMutate(data.userInfo);
    }
  }, [data.status, editData]);

  const patch = async (patchData: UserInfoPatch, dryRun: boolean) => {
    if (data.status !== "ok") return;
    editMutate(patchData);
    const request: UserInfoPatchRequest = {
      attributes: buildUserInfoPatchRequestAttributes(patchData),
      dry_run: dryRun,
    };

    try {
      await patchUserInfo(request, data.historyId);
      if (!dryRun) {
        data.mutate();
      }
    } catch (e) {
      const response = (e as AxiosError<PatchUserInfoErrorResponse>).response;
      if (response?.data?.error) {
        if (response.data.error !== "invalid_attribute") data.mutate();
        throw buildUserInfoPatchError(response.data, response.status);
      }
      throw new Error(`${response?.status}:${response?.statusText}`);
    }
  };

  if (!editData) {
    return { status: "loading", patch, useInfo: undefined } as const;
  }

  return {
    status: data.status,
    userInfo: editData,
    patch,
  } as const;
};
