import type React from "react";

import { Box } from "nikkei-ui";

import styles from "./MfaAuthenticatorSettingCard.module.css";

type Props = {
  title: string;
  children: React.ReactNode;
};

export const MfaAuthenticatorSettingCard: React.FC<Props> = (props) => {
  return (
    <section className={styles.card}>
      <Box className={styles.title}>
        <h2>{props.title}</h2>
      </Box>
      <Box className={styles.content}>{props.children}</Box>
    </section>
  );
};
