import { Box, ButtonBase as Button, Link, Typography } from "nikkei-ui";

import ArticleTitle from "@/components/ui/ArticleTitle/ArticleTitle";
import { PaymentsDeleteItem } from "@/components/ui/Payments/Delete/PaymentsDeleteItem";
import { path } from "@/routes";
import { ContentSkeletonLoader } from "nid-common";
import { useTranslation } from "react-i18next";
import { usePaymentsDeleteCardFeature } from "./hooks/usePaymentsDeleteCardFeature";

export const PaymentsDeleteCardFeature: React.FC = () => {
  const { t } = useTranslation();

  const usePaymentDeleteCardFeatureResult = usePaymentsDeleteCardFeature();

  if (usePaymentDeleteCardFeatureResult.status === "loading") {
    return (
      <>
        <ArticleTitle
          title={t("payments.delete.title")}
          testId="article-payments-delete"
        >
          <Typography as="p" className="nid-article-description">
            {t("payments.delete.description")}
          </Typography>
        </ArticleTitle>
        <ContentSkeletonLoader />
      </>
    );
  }

  const { selectedCard, isSubmitting, handleSubmit, register } =
    usePaymentDeleteCardFeatureResult;

  return (
    <>
      <ArticleTitle
        title={t("payments.delete.title")}
        testId="article-payments-delete"
      >
        <Typography as="p" className="nid-article-description">
          {t("payments.delete.description")}
        </Typography>
      </ArticleTitle>
      <form onSubmit={handleSubmit} noValidate>
        <input type="hidden" data-testid="card-id" {...register} />
        <Box as="section" className="nid-column-item-start nid-item-gap16 pb-8">
          <Box className="w-full nid-section nid-section-overflow">
            <Box className="nid-section-inner-gap0">
              <PaymentsDeleteItem
                label={t("payments.delete.label.card_number")}
                text={`•••• ${selectedCard.last4Number}`}
                isFirst
                testId="card-number"
              />
              <PaymentsDeleteItem
                label={t("payments.delete.label.brand")}
                text={selectedCard.brand}
                brandIcon={selectedCard.brand}
                testId="card-brand"
              />
              <PaymentsDeleteItem
                label={t("payments.delete.label.expiry_date")}
                text={`${selectedCard.expire.month}/${selectedCard.expire.year}`}
                isLast
                testId="card-expire-date"
              />
            </Box>
          </Box>
        </Box>

        <Box className="nid-section-inner nid-item-center">
          <Button size="full" data-testid="submit" disabled={isSubmitting}>
            {t("payments.delete.button.submit")}
          </Button>
          <Link to={path.payments.root} className="nid-button-back">
            <Typography color="link" variant="subtitle1">
              {t("payments.delete.button.back")}
            </Typography>
          </Link>
        </Box>
      </form>
    </>
  );
};
