import {
  Box,
  ButtonBase as Button,
  FormControl,
  Link,
  Typography,
} from "nikkei-ui";
import { useTranslation } from "react-i18next";

import { IncomeField } from "@/components/ui/Form/IncomeField/IncomeField";
import { JobInfoField } from "@/components/ui/Form/JobInfoField/JobInfoField";

import { usePersonalJobInfoInputFeature } from "@/features/Personal/JobInfo/hooks/usePersonalJobInfoInputFeature";
import { path } from "@/routes";
import { ContentSkeletonLoader } from "nid-common";

export const PersonalJobInfoInputFeature = (): JSX.Element => {
  const { t } = useTranslation();

  const usePersonalJobInfoInputFeatureReturn = usePersonalJobInfoInputFeature();

  if (usePersonalJobInfoInputFeatureReturn.status !== "ok") {
    return <ContentSkeletonLoader />;
  }

  const {
    registers,
    options,
    handleSubmit,
    errors,
    requireOccupation,
    requireJobDetail,
    buttonEnabled,
  } = usePersonalJobInfoInputFeatureReturn;

  return (
    <>
      <Box as="section" className="nid-section-without-background">
        <Box className="nid-section-inner">
          <form onSubmit={handleSubmit} noValidate>
            <JobInfoField
              options={{
                occupationNo: options.occupationNo,
                businessNo: options.businessNo,
                jobNo: options.jobNo,
                positionNo: options.positionNo,
                employeesNo: options.employeesNo,
              }}
              errors={errors.jobInfo}
              requireOccupation={requireOccupation}
              requireJobDetail={requireJobDetail}
              registers={registers}
            />
            <IncomeField option={options.incomeNo} {...registers.incomeNo} />

            <FormControl.Validation
              className="nid-error-field"
              status={errors.api ? "error" : undefined}
              data-testid="error-api"
            >
              {errors.api?.message}
            </FormControl.Validation>

            <Button size="full" data-testid="submit" disabled={!buttonEnabled}>
              {t("personal.edit.button.confirm")}
            </Button>
          </form>

          <Box className="nid-item-center">
            <Link to={path.personal.root} className="nid-button-back">
              <Typography color="link">
                {t("personal.edit.button.cancel")}
              </Typography>
            </Link>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default PersonalJobInfoInputFeature;
