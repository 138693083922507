import { useMfaStatus } from "nid-common/hooks/useMfaStatus";

export const useSecurityMfaTopFeature = () => {
  const useMfaStatusReturn = useMfaStatus();

  if (useMfaStatusReturn.status === "loading") {
    return { status: "loading" } as const;
  }

  if (useMfaStatusReturn.status === "error") {
    const error = useMfaStatusReturn.error;
    return { status: "error", error } as const;
  }

  return {
    status: "ok",
    data: useMfaStatusReturn.data,
  };
};
