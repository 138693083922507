import type { BlocksType } from "./type";
/**
 * convertFullWidthDigits
 * @param str string
 * @returns string
 */
export const convertFullWidthDigits = (str: string): string => {
  return str.replace(/[０-９]/g, (char) => {
    return String.fromCharCode(char.charCodeAt(0) - 0xfee0);
  });
};
/**
 * extractDigits
 * @param str string
 * @returns string
 */
export const extractDigits = (str: string): string => {
  return str.replace(/\D/g, "");
};
/**
 * sanitizeCardNumber
 * @param cardNumber string
 * @returns string
 * @description
 * - Remove all non-digit characters from the card number
 * - Convert full-width digits to half-width digits
 * - Return the sanitized card number
 */
export const sanitizeCardNumber = (cardNumber: string): string => {
  return extractDigits(convertFullWidthDigits(cardNumber));
};

/**
 * getMaxLength
 * @param blocks BlocksType
 * @returns number
 * @description
 * - Get the maximum length of the card number
 */
export const getCardMaxLength = (blocks: BlocksType): number =>
  blocks.reduce((previous: number, current: number) => previous + current, 0);

/**
 * removeSpace
 * @param str string
 * @returns string
 */
export const removeSpace = (str: string): string => str.replace(/\s/g, "");

/**
 * hasSlash
 * @param str string
 * @returns boolean
 */
export const hasSlash = (str: string): boolean => str.includes("/");

/**
 * Remove slashes
 * @param str string
 * @returns string
 */
export const removeSlashes = (str: string): string => str.replace(/\//g, "");

/**
 * html5 cc-exp
 * HTML5の仕様のcc-exp は YYYY-MM の形式のため
 * MM/YY の形式に変換
 */
const html5CC_EXP_REGEXP = /^\d{4}-\d{2}$/;
export const convertHtml5CC_EXP = (value: string): string => {
  if (html5CC_EXP_REGEXP.test(value)) {
    return `${value.slice(5)} / ${value.slice(2, 4)}`;
  }
  return value;
};
/**
 * cc-expが以下の形式だった場合MM/YYに変換
 * MM-YYYY
 * MM/YYYY
 * MM-YY
 */
// MM-YYYY 形式
const MM_YYYY_REGEX = /^(0[1-9]|1[0-2])-(\d{4})$/;
// MM/YYYY 形式
const MM_SLASH_YYYY_REGEX = /^(0[1-9]|1[0-2])\/(\d{4})$/;
// MM-YY 形式
const MM_YY_REGEX = /^(0[1-9]|1[0-2])-(\d{2})$/;
export const convertCC_EXP = (value: string): string => {
  if (MM_YYYY_REGEX.test(value)) {
    const match = value.match(MM_YYYY_REGEX);
    if (match) {
      const [, month, year] = match;
      return `${month} / ${year.slice(-2)}`;
    }
  }
  if (MM_SLASH_YYYY_REGEX.test(value)) {
    const match = value.match(MM_SLASH_YYYY_REGEX);
    if (match) {
      const [, month, year] = match;
      return `${month} / ${year.slice(-2)}`;
    }
  }
  if (MM_YY_REGEX.test(value)) {
    return value.replace(/[-/]/, " / ");
  }
  return value;
};

/**
 * isNotDigitsAndSlash
 * @param str string
 * @returns boolean
 */
export const isNotDigitsAndSlash = (str: string): boolean => {
  // digits or slashes
  return /[^0-9\/]/.test(str);
};
/**
 * extractDigitsAndSlash
 * @param str string
 * @returns string
 */
export const extractDigitsAndSlash = (str: string): string => {
  return str.replace(/[^0-9\/]/g, "");
};
/**
 * formatMonth
 * @param value string
 * @returns string
 */
export const formatMonth = (value: string): string => {
  const arr = ["0", 0];
  if (value.length === 1 && !arr.includes(value)) {
    return `0${value}`;
  }
  return value;
};

/**
 * formatDay
 * @param value string
 * @returns string
 */
export const formatDay = (value: string): string => {
  const arr = ["0", 0];
  if (value.length === 1 && !arr.includes(value)) {
    return `0${value}`;
  }
  return value;
};

/**
 * formatMonthWithSlash
 */
export const formatMonthWithSlash = (value: string): string => {
  if (hasSlash(value)) return value;
  return `${formatMonth(value)} / `;
};
/**
 * splitMonthYear
 * @param value string
 * @returns { month: string, year: string }
 */
export const splitMonthYear = (
  value: string,
): { month: string; year: string } => {
  let month = "";
  let year = "";

  const firstSlashIndex = value.indexOf("/");

  if (firstSlashIndex !== -1) {
    month = value.slice(0, firstSlashIndex);
    year = value.slice(firstSlashIndex + 1);
  } else {
    if (value.length >= 3) {
      month = value.slice(0, 2);
      year = value.slice(2);
    } else {
      month = value;
      year = "";
    }
  }

  return { month, year };
};
