import type React from "react";

import { Box } from "nikkei-ui";
import { useTranslation } from "react-i18next";

import ArticleTitle from "@/components/ui/ArticleTitle/ArticleTitle";
import { PersonalSurveyInputFeature } from "@/features/Personal/Survey/PersonalSurveyInputFeature";

export const PersonalSurveyPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <ArticleTitle
        testId="article-personal-survey-input"
        title={t("personal.edit.survey.title")}
      />

      <Box as="section" className="nid-section nid-section-without-background">
        <Box className="nid-section-inner">
          <PersonalSurveyInputFeature />
        </Box>
      </Box>
    </>
  );
};
