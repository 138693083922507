import type React from "react";
import { useEffect } from "react";

import { FormProvider, useForm } from "react-hook-form";

import { type JobInfoMaster, useJobInfoMaster } from "nid-common";

import { type UserInfoPatch, usePatchUserInfo } from "@/hooks/usePatchUserInfo";
import { requireDetail } from "./hooks/usePersonalJobInfoInputFeature";

export type PersonalJobInfoFormValues = {
  jobInfo: {
    occupationNo: string;
    businessNo: string;
    jobNo: string;
    positionNo: string;
    employeesNo: string;
  };
  incomeNo: string;
  inputDone: boolean;
};

export const buildUserInfoPatch = (
  input: PersonalJobInfoFormValues,
  occupationNo: JobInfoMaster["occupationNo"],
): UserInfoPatch => {
  const required = requireDetail(occupationNo, input.jobInfo.occupationNo);
  return {
    occupation:
      input.jobInfo.occupationNo === "0" ? null : input.jobInfo.occupationNo,
    business: required ? input.jobInfo.businessNo : null,
    job: required ? input.jobInfo.jobNo : null,
    position: required ? input.jobInfo.positionNo : null,
    employees: required ? input.jobInfo.employeesNo : null,
    income: input.incomeNo === "0" ? null : input.incomeNo,
  } as UserInfoPatch;
};

export const PersonalJobInfoFormProvider: React.FC<{
  children: React.ReactElement;
}> = (props) => {
  const patchUserInfo = usePatchUserInfo();
  const jobInfoMaster = useJobInfoMaster();
  const methods = useForm<PersonalJobInfoFormValues>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    shouldUnregister: false,
    defaultValues: {
      jobInfo: {
        occupationNo: "0",
        businessNo: "0",
        jobNo: "0",
        positionNo: "0",
        employeesNo: "0",
      },
      incomeNo: "0",
      inputDone: false,
    },
  });

  useEffect(() => {
    if (patchUserInfo.status !== "ok" || jobInfoMaster.status !== "ok") return;
    const userInfo = patchUserInfo.userInfo;
    methods.setValue("jobInfo", {
      occupationNo: userInfo.occupation || "0",
      businessNo: userInfo.business || "0",
      jobNo: userInfo.job || "0",
      positionNo: userInfo.position || "0",
      employeesNo: userInfo.employees || "0",
    });
    methods.setValue("incomeNo", userInfo.income || "0");
  }, [patchUserInfo.status, jobInfoMaster.status]);

  return <FormProvider {...methods}>{props.children}</FormProvider>;
};
