import { AccountIcon } from "@/components/icon/AccountIcon";
import { useSignIn, useUserStatus } from "nid-common";
import { Box, ButtonBase as Button, Link, Typography } from "nikkei-ui";
import type React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./Header.module.css";

type HeaderAccountMenuProps = {
  handleLogout?: () => void;
  logoutText: string;
};

const supportUrl = import.meta.env.VITE_URL_NIKKEI_ID_SUPPORT;
const helpUrl = import.meta.env.VITE_URL_NIKKEI_ID_HELP;
const contactUrl = import.meta.env.VITE_URL_NIKKEI_ID_CONTACT;

export const HeaderAccountMenu: React.FC<HeaderAccountMenuProps> = ({
  handleLogout,
  logoutText,
}) => {
  const { t } = useTranslation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const data = useUserStatus().data;
  const { startSignIn } = useSignIn();

  const loginId = data?.login_id;
  const userName = data?.name;

  const handleToggleMenu = (bool: boolean) => {
    setIsMenuOpen(bool);
  };

  return (
    <Box className="account-nav">
      <Link
        title={t("account_top.link.menu")}
        aria-label={t("account_top.link.menu")}
        className={styles.accountIconContainer}
        onClick={() => handleToggleMenu(true)}
        tabIndex={0}
        data-testid="button-account-nav"
      >
        <AccountIcon />
      </Link>
      <Box className={styles.dropdownMenu}>
        <Box hidden>{data?.login_id}</Box>
        {isMenuOpen && (
          <>
            <Box
              className={styles.dropdownOverlay}
              onClick={() => handleToggleMenu(false)}
            />
            <Box className={styles.dropdownMenuContainer}>
              <Box className={styles.accountMenuBody}>
                <Box className="nid-column-item-start nid-item-gap8 w-full">
                  {data?.status === "ok" && (
                    <>
                      <Box
                        className={styles.accountContainer}
                        data-testid="header-account-container"
                      >
                        {userName ? (
                          <>
                            <Typography as="p" variant="caption" bold>
                              {userName} {t("layout.header.honorific_title")}
                            </Typography>
                            <Typography
                              as="p"
                              variant="small"
                              className={styles.accountIdText}
                            >
                              {loginId}
                            </Typography>
                          </>
                        ) : (
                          <Typography as="p" variant="caption" bold>
                            {loginId}
                          </Typography>
                        )}
                      </Box>
                      <hr className="w-full nid-separator" />
                    </>
                  )}
                  <Link as="a" href={supportUrl} newTab variant="caption">
                    {t("layout.header.support")}
                  </Link>
                  <Link as="a" href={helpUrl} newTab variant="caption">
                    {t("layout.header.help")}
                  </Link>
                  <Link as="a" href={contactUrl} newTab variant="caption">
                    {t("layout.header.contact")}
                  </Link>
                </Box>

                {data?.status === "ok" ? (
                  <Button
                    variant="border"
                    size="lg"
                    className="w-full"
                    onClick={handleLogout}
                  >
                    {logoutText}
                  </Button>
                ) : (
                  <Button
                    variant="solid"
                    size="lg"
                    className="w-full"
                    onClick={() => startSignIn({ replace: false })}
                    data-testid="button-account-nav-login"
                  >
                    {t("layout.header.login")}
                  </Button>
                )}
              </Box>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};
