import type React from "react";

import { path } from "@/routes";
import { Box } from "nikkei-ui";
import { useTranslation } from "react-i18next";
import { NavigationItem } from "../Navigation/NavigationItem";

export const PaymentsNavigation: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Box as="section" className="nid-section nid-section-overflow mb-6">
      <Box className="nid-section-inner">
        <Box className="nid-section-body">
          <NavigationItem
            label={t("payments.navigation.histories")}
            to={path.payments.histories.root}
            variant="subtitle1"
            noBorder
            testId="link-payments-histories"
          />
        </Box>
      </Box>
    </Box>
  );
};
