import { I18nextProvider } from "react-i18next";
import { RouterProvider, type RouterProviderProps } from "react-router-dom";

import { datadogRum } from "@datadog/browser-rum";
import { HelmetProvider } from "react-helmet-async";
import { SWRConfig } from "swr";
import i18n from "./i18n/i18n";

const DATADOG_APPLICATION_ID = import.meta.env.VITE_DATADOG_APPLICATION_ID;
const DATADOG_CLIENT_TOKEN = import.meta.env.VITE_DATADOG_CLIENT_TOKEN;

if (DATADOG_APPLICATION_ID && DATADOG_CLIENT_TOKEN) {
  datadogRum.init({
    applicationId: DATADOG_APPLICATION_ID,
    clientToken: DATADOG_CLIENT_TOKEN,
    site: "datadoghq.com",
    service: "nikkei-id-account",
    allowedTracingUrls: [
      /https:\/\/id.*\.nikkei\.com/,
      /https:\/\/id-devn\.id\.n8s\.jp/,
    ],
    env: import.meta.env.MODE,
    version: import.meta.env.VITE_APP_VERSION,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 0,
    defaultPrivacyLevel: "mask",
    enablePrivacyForActionName: true,
    trackResources: true,
    trackUserInteractions: true,
    beforeSend: (event) => {
      return !(
        event.type === "error" && event?.error.message.includes("csp_violation")
      );
    },
  });
  datadogRum.startSessionReplayRecording();
}

const App = (props: RouterProviderProps) => (
  <SWRConfig
    value={{
      provider: () => new Map(),
      errorRetryCount: 3,
      errorRetryInterval: 100,
    }}
  >
    <HelmetProvider>
      <I18nextProvider i18n={i18n}>
        <RouterProvider {...props} />
      </I18nextProvider>
    </HelmetProvider>
  </SWRConfig>
);

export default App;
