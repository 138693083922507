import type { PaymentsCard } from "@/hooks/usePaymentCards";
import type { Service } from "./PaymentsServicesFeature";

export const getServicesLinkedCard = (cards: PaymentsCard[]): Service[] =>
  cards.flatMap((card) =>
    card.services.map((service) => ({
      ...service,
      card,
    })),
  );
