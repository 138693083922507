import type React from "react";

import { useTranslation } from "react-i18next";

import { PersonalItem } from "@/components/ui/PersonalItem/PersonalItem";
import { usePersonalFeature } from "@/features/Personal/hooks/usePersonalFeature";
import { useUserInfoLabel } from "@/hooks/useUserInfoLabel";
import { path } from "@/routes";
import { ContentSkeletonLoader } from "nid-common";

const filterUndefined = (list: (string | undefined)[]): string[] => {
  return list.filter((item): item is string => typeof item === "string");
};

export const PersonalFeature: React.FC = () => {
  const { t } = useTranslation();
  const { getZipCodeLabel } = useUserInfoLabel();
  const usePersonalFeatureReturn = usePersonalFeature();

  if (usePersonalFeatureReturn.status !== "ok")
    return <ContentSkeletonLoader />;

  const userInfo = usePersonalFeatureReturn.userInfo;

  return (
    <>
      <PersonalItem
        title={t("attributes.email")}
        onClick={usePersonalFeatureReturn.editHandler}
        changePageLink={path.personal.emailChange.root}
        testId="personal-item-email"
        values={[{ key: undefined, values: userInfo.email }]}
      />

      <PersonalItem
        title={t("attributes.info")}
        onClick={usePersonalFeatureReturn.editHandler}
        changePageLink={path.personal.info.root}
        testId="personal-item-info"
        values={[
          { key: t("attributes.name"), values: userInfo.displayName },
          { key: t("attributes.nameKana"), values: userInfo.displayNameKana },
          { key: t("attributes.birth"), values: userInfo.displayBirth },
          { key: t("attributes.sex"), values: userInfo.sexLabel },
        ]}
      />

      {userInfo.domesticAddress && (
        <PersonalItem
          title={t("attributes.addressInfo")}
          changePageLink={path.personal.address.root}
          onClick={usePersonalFeatureReturn.editHandler}
          testId="personal-item-address"
          values={[
            { key: t("attributes.country"), values: "日本" },
            {
              key: t("attributes.zipcode"),
              values: userInfo.domesticAddress.zipCode
                ? getZipCodeLabel(userInfo.domesticAddress.zipCode)
                : undefined,
            },
            {
              key: t("attributes.address"),
              values: filterUndefined([
                userInfo.domesticAddress.addressCodeMei ||
                userInfo.domesticAddress.address1
                  ? `${userInfo.domesticAddress.addressCodeMei ?? ""}${userInfo.domesticAddress.address1 ?? ""}`
                  : undefined,
                userInfo.domesticAddress.address2,
              ]),
            },
            { key: t("attributes.tel"), values: userInfo.domesticAddress?.tel },
          ]}
        />
      )}

      {userInfo.overseasAddress && (
        <PersonalItem
          title={t("attributes.addressInfo")}
          changePageLink={path.personal.address.root}
          onClick={usePersonalFeatureReturn.editHandler}
          testId="personal-item-address"
          values={[
            {
              key: t("attributes.country"),
              values: userInfo.overseasAddress.countryName,
            },
            {
              key: t("attributes.zipcode"),
              values: userInfo.overseasAddress.zipCode,
            },
            {
              key: t("attributes.address"),
              values: filterUndefined([
                userInfo.overseasAddress.address1,
                userInfo.overseasAddress.address2,
                userInfo.overseasAddress.address3,
              ]),
            },
            { key: t("attributes.tel"), values: userInfo.overseasAddress?.tel },
          ]}
        />
      )}

      <PersonalItem
        title={t("attributes.jobInfo")}
        changePageLink={path.personal.jobInfo.root}
        onClick={usePersonalFeatureReturn.editHandler}
        testId="personal-item-job-info"
        values={[
          {
            key: t("attributes.occupation"),
            values: userInfo.occupationLabel,
          },
          {
            key: t("attributes.business"),
            values: userInfo.businessLabel,
          },
          { key: t("attributes.job"), values: userInfo.jobLabel },
          {
            key: t("attributes.position"),
            values: userInfo.positionLabel,
          },
          {
            key: t("attributes.employees"),
            values: userInfo.employeesLabel,
          },
          {
            key: t("attributes.income"),
            values: userInfo.incomeLabel,
          },
        ]}
      />

      {userInfo.domesticCompany ? (
        <PersonalItem
          title={t("attributes.officeInfo")}
          changePageLink={path.personal.office.root}
          onClick={usePersonalFeatureReturn.editHandler}
          testId="personal-item-office"
          values={[
            {
              key: t("attributes.companyCountry"),
              values: t("attribute_values.office.domestic"),
            },
            {
              key: t("attributes.companyName"),
              values: userInfo.displayDomesticCompanyName,
            },
            {
              key: t("attributes.companyBusinessUnit"),
              values: userInfo.domesticCompany?.businessUnit,
            },
            {
              key: t("attributes.companyAddress"),
              values: filterUndefined([
                userInfo.domesticCompany?.zipCode
                  ? getZipCodeLabel(userInfo.domesticCompany.zipCode)
                  : undefined,
                userInfo.domesticCompany?.address,
              ]),
            },
            {
              key: t("attributes.companyTel"),
              values: userInfo.domesticCompany?.tel,
            },
          ]}
        />
      ) : userInfo.overseasCompany ? (
        <PersonalItem
          title={t("attributes.officeInfo")}
          changePageLink={path.personal.office.root}
          onClick={usePersonalFeatureReturn.editHandler}
          testId="personal-item-office"
          values={[
            {
              key: t("attributes.companyCountry"),
              values: t("attribute_values.office.overseas"),
            },
            {
              key: t("attributes.companyName"),
              values: userInfo.overseasCompany?.name,
            },
            {
              key: t("attributes.companyAddress"),
              values: userInfo.overseasCompany?.address,
            },
            {
              key: t("attributes.companyTel"),
              values: userInfo.overseasCompany?.tel,
            },
          ]}
        />
      ) : (
        <PersonalItem
          title={t("attributes.officeInfo")}
          testId="personal-item-office"
          changePageLink={path.personal.office.root}
          onClick={usePersonalFeatureReturn.editHandler}
          values={[]}
        />
      )}

      <PersonalItem
        title={t("attributes.survey")}
        testId="personal-item-survey"
        changePageLink={path.personal.survey.root}
        onClick={usePersonalFeatureReturn.editHandler}
        values={[
          {
            key: t("attributes.notification_mail"),
            values: filterUndefined([
              userInfo.receiveNikkeiMail
                ? t("attribute_values.mail.nikkei")
                : undefined,
              userInfo.receiveThirdPartyMail
                ? t("attribute_values.mail.thirdParty")
                : undefined,
            ]),
          },
          {
            key: t("attributes.news_subscription"),
            values: filterUndefined([
              userInfo.newsSubscriptions?.newsSubscription1
                ? t("attribute_values.news_subscription.1")
                : undefined,
              userInfo.newsSubscriptions?.newsSubscription3
                ? t("attribute_values.news_subscription.3")
                : undefined,
              userInfo.newsSubscriptions?.newsSubscription4
                ? t("attribute_values.news_subscription.4")
                : undefined,
            ]),
          },
          {
            key: t("attributes.nikkei_research_monitor"),
            values: userInfo.registerNikkeiMonitor
              ? t("attribute_values.nikkei_research_monitor.1")
              : undefined,
          },
          {
            key: t("attributes.interest"),
            values:
              filterUndefined([
                userInfo.interest?.interest1
                  ? t("attribute_values.interest.1")
                  : undefined,
                userInfo.interest?.interest2
                  ? t("attribute_values.interest.2")
                  : undefined,
                userInfo.interest?.interest3
                  ? t("attribute_values.interest.3")
                  : undefined,
                userInfo.interest?.interest4
                  ? t("attribute_values.interest.4")
                  : undefined,
                userInfo.interest?.interest5
                  ? t("attribute_values.interest.5")
                  : undefined,
                userInfo.interest?.interest6
                  ? t("attribute_values.interest.6")
                  : undefined,
                userInfo.interest?.interest7
                  ? t("attribute_values.interest.7")
                  : undefined,
                userInfo.interest?.interest8
                  ? t("attribute_values.interest.8")
                  : undefined,
                userInfo.interest?.interest9
                  ? t("attribute_values.interest.9")
                  : undefined,
                userInfo.interest?.interest10
                  ? t("attribute_values.interest.10")
                  : undefined,
                userInfo.interest?.interest11
                  ? t("attribute_values.interest.11")
                  : undefined,
                userInfo.interest?.interest12
                  ? t("attribute_values.interest.12")
                  : undefined,
                userInfo.interest?.interest13
                  ? t("attribute_values.interest.13")
                  : undefined,
              ]).join(t("attribute_values.interest.separator")) || undefined,
          },
        ]}
      />
    </>
  );
};
