import * as React from "react";

import { TextField, type TextFieldProps } from "nikkei-ui";

import usePasswordField from "./usePasswordField";

const DISPLAY_NAME = "Nid.Form.PasswordField";

type PasswordFieldProps = TextFieldProps & {
  label: string;
  message?: string | undefined;
  trailingIconLabelData: {
    on: string;
    off: string;
  };
  isLeadingIcon?: boolean;
  labelVariant?: "head" | "float";
  variant?: "password" | "newPassword";
  disabled?: boolean;
  children?: React.ReactNode;
};

const PasswordField = React.forwardRef<HTMLInputElement, PasswordFieldProps>(
  (
    {
      label,
      message,
      trailingIconLabelData,
      isLeadingIcon,
      labelVariant,
      variant,
      disabled,
      ...rest
    },
    forwardedRef,
  ) => {
    const { isRevealedPassword, toggleRevealed } = usePasswordField();
    const error = !message || message === "" ? undefined : "error";
    const validationMessage = !message || message === "" ? undefined : message;
    return (
      <TextField
        variant={isRevealedPassword ? `${variant}Revealed` : variant}
        labelVariant={labelVariant}
        label={label}
        id={rest.id}
        disabled={disabled}
        placeholder={rest.placeholder}
        leadingIcon={isLeadingIcon ? "Key" : "None"}
        trailingIcon={isRevealedPassword ? "Eye" : "EyeOff"}
        trailingIconLabel={
          isRevealedPassword
            ? (trailingIconLabelData.on as string)
            : (trailingIconLabelData.off as string)
        }
        onIconClick={() => toggleRevealed()}
        status={error}
        validationMessage={validationMessage}
        ref={forwardedRef}
        {...rest}
      />
    );
  },
);

PasswordField.displayName = DISPLAY_NAME;
const Root = PasswordField;
export { Root, PasswordField };
export type { PasswordFieldProps };
export default PasswordField;
