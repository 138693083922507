import type * as React from "react";

import { useTranslation } from "react-i18next";

import ArticleTitle from "@/components/ui/ArticleTitle/ArticleTitle";
import { SecurityMfaEmailInputFeature } from "@/features/Security/Mfa/Email/SecurityMfaEmailInputFeature";
import { Typography } from "nikkei-ui";

const SecurityMfaEmailPage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <ArticleTitle
        title={t("security.mfa.email.index.title")}
        testId="article-security-mfa-email"
      >
        <Typography as="p" className="nid-article-description">
          {t("security.mfa.email.index.description")}
        </Typography>
      </ArticleTitle>
      <SecurityMfaEmailInputFeature />
    </>
  );
};

export default SecurityMfaEmailPage;
