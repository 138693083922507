import type * as React from "react";

import { Box, Link } from "nikkei-ui";
import { useTranslation } from "react-i18next";

const CharacterError: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Box className="nid-form-helper-text-container mt-1">
      <Link
        href={import.meta.env.VITE_URL_NIKKEI_HELP_CHARACTER}
        className="nid-typography-body2"
        newTab
      >
        {t("personal.form_helper_text")}
      </Link>
    </Box>
  );
};

export default CharacterError;
