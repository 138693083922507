import type React from "react";
import { useEffect, useState } from "react";

import { type SubmitHandler, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import {
  COUNTRY_JAPAN,
  COUNTRY_UNKNOWN,
  type InputUserInfoValues,
  buildUserInfoPatch,
} from "@/features/Personal/Address/PersonalAddressFormProvider";
import {
  type PatchUserInfoError,
  usePatchUserInfo,
} from "@/hooks/usePatchUserInfo";
import { useUserInfo } from "@/hooks/useUserInfo";
import { path } from "@/routes";
import { datadogRum } from "@datadog/browser-rum";
import {
  toFullWidth,
  toHalfWidth,
  useAddressMaster,
  useCountriesMaster,
} from "nid-common";

type FieldName =
  | "domestic.zipCode"
  | "domestic.addressCode"
  | "domestic.address1"
  | "domestic.address2"
  | "domestic.tel"
  | "overseas.zipCode"
  | "overseas.address1"
  | "overseas.address2"
  | "overseas.address3"
  | "overseas.tel";

const buildSelectOptions = (
  defaultLabel: string,
  data: { name: string; code: string }[],
) => {
  return [
    { label: defaultLabel, value: "0" },
    ...data.map((v) => {
      return { label: v.name, value: v.code };
    }),
  ];
};

export const usePersonalAddressInputFeature = () => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    getValues,
    watch,
    setValue,
    setError,
    setFocus,
    clearErrors,
    formState: { errors },
  } = useFormContext<InputUserInfoValues>();
  const navigate = useNavigate();
  const { patch, userInfo: userInfoPatch } = usePatchUserInfo();
  const useUserInfoReturn = useUserInfo();
  const { address, refreshAddress } = useAddressMaster(
    userInfoPatch?.domesticAddress?.zipCode ||
      useUserInfoReturn?.userInfo?.domesticAddress?.zipCode,
  );
  const countriesReturn = useCountriesMaster();
  const messages = t("personal.edit.address", { returnObjects: true });
  const isDomesticCode = (code: string) =>
    code === COUNTRY_JAPAN || code === COUNTRY_UNKNOWN;
  const isDomestic = isDomesticCode(watch("countryCode"));
  const [apiError, setApiError] = useState<string>();

  useEffect(() => {
    if (countriesReturn.status === "error") {
      navigate(path.error.root, { replace: true });
    }
  }, [countriesReturn.status]);

  if (countriesReturn.status !== "ok" || useUserInfoReturn.status !== "ok") {
    return { status: "loading" } as const;
  }

  const userInfo = useUserInfoReturn.userInfo;

  const handleChangeUserInfo: SubmitHandler<InputUserInfoValues> = async (
    data: InputUserInfoValues,
  ) => {
    try {
      await patch(buildUserInfoPatch(data, userInfo), true);
      setValue("inputDone", true);
      navigate(path.personal.address.confirm);
    } catch (e) {
      if ((e as PatchUserInfoError).error === "invalid_attribute") {
        const error = (e as PatchUserInfoError).attributes;
        const ed = error.domesticAddress;
        const eo = error.overseasAddress;
        const errorFields = [];
        const zipCodeError = {
          message: messages.errors.zip_code.single,
        };
        const domesticAddrCodeErr = {
          message: messages.errors.address.domestic,
        };
        const domesticAddrErr = {
          message: messages.errors.address.domestic,
          type: "invalid_character",
        };
        const overseasAddrErr = {
          message: messages.errors.address.overseas,
        };
        const telError = {
          message: messages.errors.tel.single,
        };
        const isValidError = (value: string | undefined) => {
          return (
            value === "single" || value === "required" || value === "relation"
          );
        };

        if (isDomestic) {
          if (isValidError(ed?.zipCode)) {
            setError("domestic.zipCode", zipCodeError);
            errorFields.push("domestic.zipCode");
          }
          if (isValidError(ed?.addressCode)) {
            setError("domestic.addressCode", domesticAddrCodeErr);
            errorFields.push("domestic.addressCode");
          }
          if (isValidError(ed?.address1)) {
            setError("domestic.address1", domesticAddrErr);
            errorFields.push("domestic.address1");
          }
          if (isValidError(ed?.address2)) {
            setError("domestic.address2", domesticAddrErr);
            errorFields.push("domestic.address2");
          }
          if (isValidError(ed?.tel)) {
            setError("domestic.tel", telError);
            errorFields.push("domestic.tel");
          }
        } else {
          if (isValidError(eo?.zipCode)) {
            setError("overseas.zipCode", zipCodeError);
            errorFields.push("overseas.zipCode");
          }
          if (isValidError(eo?.address1)) {
            setError("overseas.address1", overseasAddrErr);
            errorFields.push("overseas.address1");
          }
          if (isValidError(eo?.address2)) {
            setError("overseas.address2", overseasAddrErr);
            errorFields.push("overseas.address2");
          }
          if (isValidError(eo?.address3)) {
            setError("overseas.address3", overseasAddrErr);
            errorFields.push("overseas.address3");
          }
          if (isValidError(eo?.tel)) {
            setError("overseas.tel", telError);
            errorFields.push("overseas.tel");
          }
        }
        const errorFirstField = errorFields.at(0);
        if (errorFirstField) {
          setFocus(errorFirstField as FieldName);
        }
      } else if ((e as PatchUserInfoError).error === "optimistic_locked") {
        setApiError(t("personal.edit.errors.optimistic_locked"));
      } else {
        datadogRum.addError(e);
        navigate(path.error.root);
      }
    }
  };

  // Required 判定.
  const required = {
    domestic: {
      zipCode: isDomestic,
    },
  };

  // バリデーション.
  const domesticZipCodeValidator = (value: string) => {
    if (!isDomestic) return undefined;

    if (required.domestic.zipCode && value === "") {
      return t("personal.edit.errors.required");
    }
    if (value !== "" && !value.match(/^[0-9]{7}$/)) {
      return messages.errors.zip_code.invalid;
    }
    return undefined;
  };

  const domesticTelValidator = (value: string) => {
    if (!isDomestic) return undefined;
    if (value !== "" && !value.match(/^[0-9]+$/)) {
      return messages.errors.tel.invalid;
    }
    return undefined;
  };

  const registers = {
    country: register("countryCode", {
      onChange: (e: React.ChangeEvent<HTMLSelectElement>) => {
        if (isDomesticCode(e.target.value) !== isDomestic) {
          clearErrors();
        }
      },
    }),
    domestic: {
      zipCode: register("domestic.zipCode", {
        validate: domesticZipCodeValidator,
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
          const value = e.target.value;
          if (value.length === 7) {
            refreshAddress(toHalfWidth(value));
          } else {
            refreshAddress(undefined);
          }
          setValue("domestic.addressCode", "0");
        },
        onBlur: (e: React.ChangeEvent<HTMLInputElement>) => {
          const value = toHalfWidth(e.target.value);
          const hasError = errors.domestic?.zipCode !== undefined;
          setValue("domestic.zipCode", value, {
            shouldValidate: hasError,
          });
        },
      }),
      addressCode: register("domestic.addressCode"),
      address1: register("domestic.address1", {
        onBlur: (e: React.ChangeEvent<HTMLInputElement>) => {
          const value = toFullWidth(e.target.value);
          setValue("domestic.address1", value);
        },
      }),
      address2: register("domestic.address2", {
        onBlur: (e: React.ChangeEvent<HTMLInputElement>) => {
          const value = toFullWidth(e.target.value);
          setValue("domestic.address2", value);
        },
      }),
      tel: register("domestic.tel", {
        validate: domesticTelValidator,
        onBlur: (e: React.ChangeEvent<HTMLInputElement>) => {
          const value = toHalfWidth(e.target.value);
          const hasError = errors.domestic?.tel !== undefined;
          setValue("domestic.tel", value, {
            shouldValidate: hasError,
          });
        },
      }),
    },
    overseas: {
      address1: register("overseas.address1"),
      address2: register("overseas.address2"),
      address3: register("overseas.address3"),
      zipCode: register("overseas.zipCode"),
      tel: register("overseas.tel"),
    },
  };

  const retErrors = {
    ...errors,
    ...(apiError ? { api: { message: apiError } } : undefined),
  };

  return {
    status: "ok",
    handleSubmit: handleSubmit(handleChangeUserInfo),
    registers,
    required,
    errors: retErrors,
    refreshAddress: () => {
      refreshAddress(getValues("domestic.zipCode"));
      setValue("domestic.addressCode", "0");
    },
    addresses: buildSelectOptions(
      address.length === 0
        ? t("personal.edit.address.placeholder.domestic.address_code1")
        : t("personal.edit.address.placeholder.domestic.address_code2"),
      address,
    ),
    countries: countriesReturn.countries.map((v) => {
      return { label: v.name, value: v.code };
    }),
    buttonEnabled: Object.keys(retErrors).length === 0,
    isDomestic,
  };
};
