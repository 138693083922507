import { useTranslation } from "react-i18next";

import ArticleTitle from "@/components/ui/ArticleTitle/ArticleTitle";
import PersonalInfoInputFeature from "@/features/Personal/Info/PersonalInfoInputFeature";

export const PersonalInfoPage = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      <ArticleTitle
        testId="article-personal-info-input"
        title={t("personal.edit.info.title")}
      />

      {/* お客様に関する情報 */}
      <PersonalInfoInputFeature />
      {/* お客様に関する情報 */}
    </>
  );
};

export default PersonalInfoPage;
