import type React from "react";

import { useTranslation } from "react-i18next";

import ArticleTitle from "@/components/ui/ArticleTitle/ArticleTitle";
import { PersonalOfficeInputFeature } from "@/features/Personal/Office/PersonalOfficeInputFeature";

export const PersonalOfficePage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <ArticleTitle
        testId="article-personal-office-input"
        title={t("personal.edit.office.title")}
      />
      <PersonalOfficeInputFeature />
    </>
  );
};
