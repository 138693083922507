import { useEffect, useState } from "react";

export const useScript = (
  src: string,
  scriptSettings: (script: HTMLScriptElement) => void,
  disabled: boolean,
) => {
  const [scriptLoaded, setScriptLoaded] = useState(disabled);

  useEffect(() => {
    if (disabled) {
      return;
    }
    const script = document.createElement("script");
    script.src = src;
    scriptSettings(script);
    script.onload = () => {
      setScriptLoaded(true);
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return {
    scriptLoaded,
  };
};
