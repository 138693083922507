import { getCardBrand } from "@/features/Payments/Update/getCardBrand";
import { usePaymentsUpdateNavigate } from "@/features/Payments/Update/usePaymentsUpdateNavigate";
import {
  type PaymentsService,
  usePaymentCards,
  type usePaymentsType,
} from "@/hooks/usePaymentCards";
import { path } from "@/routes";
import { datadogRum } from "@datadog/browser-rum";
import type { AxiosError } from "axios";
import { getMonth, getYear } from "date-fns";
import {
  type PostUpdateCreditCardErrorResponse,
  type PostUpdateCreditCardRequest,
  postUpdateCreditCard,
} from "nid-common/api/account";
import type React from "react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useSonyPayment } from "./useSonyPayment";
import { formatExpire, formatValidateExpire } from "./utils/cardExpireFormat";
import {
  formatViewCreditCardNumber,
  sanitizeCardNumberForPost,
} from "./utils/cardFormat";
import { formatSecurityCode } from "./utils/cardSecurityCode";
import { formatDay, formatMonth } from "./utils/common";

// NKDK Test Card用Luhnチェックスキップ用のカード番号
// このカード番号を一回入力すると、以降の入力でLuhnチェックをスキップする
const SKIP_LUHN_CHECK_NUMBER = "0000111122223333";

const isValidLuhn = (cardNumber: string): boolean => {
  if (!/^\d+$/.test(cardNumber)) {
    return false;
  }

  const calc = (n: number): number => {
    const value = 2 * n;
    return value > 9 ? value - 9 : value;
  };

  const result = [...cardNumber].reverse().reduce((acc, digit, i) => {
    const n = Number.parseInt(digit, 10);
    const add = i % 2 === 1 ? calc(n) : n;
    return acc + add;
  }, 0);

  return result % 10 === 0;
};

type CompositionEvent = React.CompositionEvent<HTMLInputElement>;
type CompositionInputType = "expireDate" | "cardNumber" | "cvc";
export type PaymentsUpdateForm = {
  cardId: string;
  newCard: {
    number: string;
    expire: string;
    securityCode: string;
    birth: {
      year: string;
      month: string;
      date: string;
    };
  };
  applyToOtherServices: boolean;
  onComposition: (e: CompositionEvent, type: CompositionInputType) => void;
};

export const usePaymentsUpdateForm = (
  haveSelectableCards: boolean,
  paymentsLoadingState: usePaymentsType["status"],
  handleToken: (token: string) => Promise<void>,
) => {
  const [skipLuhnCheck, setSkipLuhnCheck] = useState(false);

  const { t } = useTranslation();
  const location = useLocation();
  const destinationAfterPaymentsUpdate =
    location.state?.destinationAfterPaymentsUpdate;
  const { navigate: paymentsUpdateNavigate } = usePaymentsUpdateNavigate();
  const navigate = useNavigate();
  const { mutate: getPaymentsMutate } = usePaymentCards();

  const [errorCardType, setErrorCardType] = useState<
    "invalid_card" | "script_not_loaded" | undefined
  >(undefined);
  const [isIMEMode, setIsIMEMode] = useState(false);
  const [newCardNumber, setNewCardNumber] = useState("");

  const { scriptLoaded } = useSonyPayment(handleToken);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    getValues,
  } = useForm<PaymentsUpdateForm>({
    mode: "onSubmit",
    defaultValues: {
      cardId: undefined,
      newCard: {
        number: "",
        expire: "",
        securityCode: "",
        birth: {
          year: "0",
          month: "0",
          date: "0",
        },
      },
      applyToOtherServices: false,
    },
  });

  const showNewCardForm = watch("cardId") === "0";
  const cardNumber = watch("newCard.number");
  const disableButton = !watch("cardId");

  const handleApiRequest = async (
    request: PostUpdateCreditCardRequest,
    updatedServices: PaymentsService[],
  ) => {
    try {
      await postUpdateCreditCard(request);
      getPaymentsMutate();
      paymentsUpdateNavigate(updatedServices, destinationAfterPaymentsUpdate);
    } catch (e) {
      const axiosResponse = (e as AxiosError<PostUpdateCreditCardErrorResponse>)
        .response;
      if (
        axiosResponse?.status === 400 &&
        ["invalid_card", "card_expired"].includes(axiosResponse?.data.error)
      ) {
        setErrorCardType("invalid_card");
      } else if (axiosResponse?.status === 503) {
        navigate(path.payments.maintenance);
      } else {
        datadogRum.addError(e);
        navigate(path.error.root);
      }
    }
  };

  useEffect(() => {
    if (paymentsLoadingState === "ok" && !haveSelectableCards) {
      setValue("cardId", "0");
    }
  }, [paymentsLoadingState, haveSelectableCards]);

  useEffect(() => {
    if (sanitizeCardNumberForPost(cardNumber) === SKIP_LUHN_CHECK_NUMBER) {
      setSkipLuhnCheck(true);
    }
  }, [cardNumber]);

  const handleOnChangeNewCardNumber = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = e.target.value;
    const cursor = e.target.selectionStart ?? value.length;
    const formattedValue = formatViewCreditCardNumber(value);
    setValue("newCard.number", formattedValue);
    if (
      formattedValue[cursor - 1] === " " &&
      formattedValue.length > newCardNumber.length
    ) {
      e.target.selectionStart = cursor + 1;
      e.target.selectionEnd = cursor + 1;
    } else if (
      formattedValue[cursor - 1] === " " &&
      formattedValue.length < newCardNumber.length
    ) {
      e.target.selectionStart = cursor - 1;
      e.target.selectionEnd = cursor - 1;
    } else if (cursor !== value.length) {
      e.target.selectionStart = cursor;
      e.target.selectionEnd = cursor;
    }
    setNewCardNumber(formattedValue);
  };

  const handleOnChangeNewCardExpire = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = e.target.value;
    const formattedValue = formatExpire(value);
    setValue("newCard.expire", formattedValue);
  };

  const handleOnChangeNewCardSecurityCode = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = e.target.value;
    const formattedValue = formatSecurityCode(value);
    setValue("newCard.securityCode", formattedValue);
  };

  const createSpsvToken = async (
    newCard: PaymentsUpdateForm["newCard"],
  ): Promise<"script_not_loaded" | undefined> => {
    if (
      !scriptLoaded ||
      window.SpsvApi === undefined ||
      typeof window.SpsvApi.spsvCreateToken !== "function"
    ) {
      setErrorCardType("script_not_loaded");
      return "script_not_loaded";
    }
    const expire = formatValidateExpire(newCard.expire);
    await window.SpsvApi.spsvCreateToken(
      sanitizeCardNumberForPost(newCard.number),
      expire.year,
      expire.month,
      newCard.securityCode,
      formatMonth(newCard.birth.month),
      formatDay(newCard.birth.date),
      "",
      "",
      "",
    );
  };

  const validateCardNumber = (value: string) => {
    if (getValues("cardId") !== "0") {
      return undefined;
    }
    if (!value) {
      return t("payments.update.errors.number.empty");
    }
    if (
      value.length < 14 ||
      (!skipLuhnCheck && !isValidLuhn(sanitizeCardNumberForPost(value)))
    ) {
      return t("payments.update.errors.number.invalid");
    }
  };

  const validateExpire = (month: string, year: string) => {
    if (getValues("cardId") !== "0") {
      return undefined;
    }
    if (!month || !year) {
      return t("payments.update.errors.expire.empty");
    }
    const yearNumber = Number.parseInt(year) + 2000;
    const monthNumber = Number.parseInt(month);
    const today = Date.now();
    if (yearNumber > getYear(today) + 10) {
      return t("payments.update.errors.expire.invalid");
    }
    if (yearNumber < getYear(today)) {
      return t("payments.update.errors.expire.expired");
    }
    if (yearNumber === getYear(today) && monthNumber < getMonth(today) + 1) {
      return t("payments.update.errors.expire.expired");
    }

    return undefined;
  };

  const validateSecurityCode = (value: string) => {
    if (getValues("cardId") !== "0") {
      return undefined;
    }
    if (!value || Number.isNaN(Number(value)) || value.length < 3) {
      return t("payments.update.errors.security_code");
    }
  };

  return {
    handleApiRequest,
    handleSubmit,
    showNewCardForm,
    watch,
    createSpsvToken,
    errorCardType,
    disableButton,
    currentCardBrand: getCardBrand(cardNumber),
    registers: {
      cardId: register("cardId"),
      newCard: {
        number: register("newCard.number", {
          onChange: (e) => {
            if (isIMEMode) return;
            handleOnChangeNewCardNumber(e);
          },
          validate: (value: string) => {
            return validateCardNumber(value);
          },
        }),
        expire: register("newCard.expire", {
          onChange: (e) => {
            if (isIMEMode) return;
            handleOnChangeNewCardExpire(e);
          },
          validate: (value: string) => {
            const { month, year } = formatValidateExpire(value);
            return validateExpire(month, year);
          },
        }),
        securityCode: register("newCard.securityCode", {
          onChange: (e) => {
            if (isIMEMode) return;
            handleOnChangeNewCardSecurityCode(e);
          },
          validate: (value: string) => {
            return validateSecurityCode(formatSecurityCode(value));
          },
        }),

        birth: {
          year: register("newCard.birth.year", {
            validate: (value: string) => {
              if (getValues("cardId") !== "0") {
                return undefined;
              }
              if (value === "" || value === "0") {
                return t("payments.update.errors.birth");
              }
            },
          }),
          month: register("newCard.birth.month", {
            validate: (value: string) => {
              if (getValues("cardId") !== "0") {
                return undefined;
              }
              if (value === "" || value === "0") {
                return t("payments.update.errors.birth");
              }
            },
          }),
          date: register("newCard.birth.date", {
            validate: (value: string) => {
              if (getValues("cardId") !== "0") {
                return undefined;
              }
              if (value === "" || value === "0") {
                return t("payments.update.errors.birth");
              }
            },
          }),
        },
      },
      applyToOtherServices: register("applyToOtherServices"),
      onComposition: (e: CompositionEvent, type: CompositionInputType) => {
        if (e.type === "compositionstart") {
          setIsIMEMode(true);
        } else if (e.type === "compositionend") {
          setIsIMEMode(false);
          // Trigger onChange
          if (type === "cardNumber") {
            handleOnChangeNewCardNumber(
              e as unknown as React.ChangeEvent<HTMLInputElement>,
            );
          }
          if (type === "expireDate") {
            handleOnChangeNewCardExpire(
              e as unknown as React.ChangeEvent<HTMLInputElement>,
            );
          }
          if (type === "cvc") {
            handleOnChangeNewCardSecurityCode(
              e as unknown as React.ChangeEvent<HTMLInputElement>,
            );
          }
        }
      },
    },
    errors: {
      newCard: {
        number: errors.newCard?.number?.message,
        expire: errors.newCard?.expire?.message,
        securityCode: errors.newCard?.securityCode?.message,
        birth: {
          year: errors.newCard?.birth?.year?.message,
          month: errors.newCard?.birth?.month?.message,
          date: errors.newCard?.birth?.date?.message,
        },
      },
    },
  };
};
