import { useCallback, useState } from "react";

export default function usePasswordField() {
  const [isRevealedPassword, revealPassword] = useState<boolean>(false);
  const toggleRevealed = useCallback(() => {
    revealPassword((prevState) => !prevState);
  }, []);

  return {
    isRevealedPassword,
    revealPassword,
    toggleRevealed,
  };
}
