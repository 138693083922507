// 支払区分
export type PaymentType = "1" | "2";
export type PaymentTypeName = "サービス利用料" | "割引違約金";

// 支払カード区分
export type PaymentCardType = "1" | "2" | "3" | "4";
export type PaymentCardTypeName =
  | "credit_card"
  | "apple_pay"
  | "au_easy_payment"
  | "d_payment"
  | string;
export const paymentCardTypeMap: {
  [key in PaymentCardType]: PaymentCardTypeName;
} = {
  "1": "credit_card",
  "2": "apple_pay",
  "3": "au_easy_payment",
  "4": "d_payment",
};
export const convertPaymentCardTypeName = (
  type: PaymentCardType,
): PaymentCardTypeName => {
  return paymentCardTypeMap[type];
};

// 請求変更区分
export type ChargeType = "1" | "2" | "3" | "4";
export type ChangeTypeName =
  | "補正"
  | "本紙とのセット割"
  | "読者割"
  | "クーポン割引"
  | string;

// サービス区分
export type ServiceChargesType = "1" | "2" | "3";
export type ServiceChargesTypeName =
  | "billing_settlement"
  | "recurring"
  | "per_visit";

export const serviceChargesTypeMap: {
  [key in ServiceChargesType]: ServiceChargesTypeName;
} = {
  // plan_id, plan_nameは課金決済時のみ存在
  "1": "billing_settlement",
  "2": "recurring",
  "3": "per_visit",
};

export const convertServiceChargesType = (
  type: ServiceChargesType,
): ServiceChargesTypeName => {
  return serviceChargesTypeMap[type];
};

// 請求額変更
export type PaymentDetailChange = {
  changeType: ChargeType;
  changeTypeName: ChangeTypeName;
  changeOrder: number;
  changeAmount: number;
};

// 支払金額明細
type PaymentAmountDetails = {
  price: number;
  changes?: PaymentDetailChange[];
};

export type PaymentDetail = {
  encodedPaymentNo: string;
  paymentType: PaymentType;
  paymentTypeName: PaymentTypeName;
  paymentHistoryDisplayString: string;
  paymentCardType: PaymentCardTypeName;
  salesDate: string;
  amount: number;
  taxRate?: number;
  taxAmount?: number;
  paymentDetail?: PaymentAmountDetails;
  companyName: string;
  invoiceRegistrationNo: string;
  serviceChargesType: ServiceChargesTypeName;
  serviceId: string;
  serviceName: string;
  planId?: string;
  planName?: string;
};
