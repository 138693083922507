import type React from "react";
import type { ReactElement } from "react";
import { createContext, useState } from "react";

import { FormProvider, useForm } from "react-hook-form";

type PersonalEmailChangeFormProviderProps = {
  children: ReactElement;
};

export type PersonalEmailChangeValues = {
  email: string;
};

export type EmailConfirmContextType = [
  string,
  React.Dispatch<React.SetStateAction<string>>,
];

export const PersonalEmailConfirmContext =
  createContext<EmailConfirmContextType>(["", () => {}]);

export const PersonalEmailChangeFormProvider: React.FC<
  PersonalEmailChangeFormProviderProps
> = (props) => {
  const [emailConfirm, setEmailConfirm] = useState("");
  const methods = useForm<PersonalEmailChangeValues>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    shouldUnregister: false,
    defaultValues: {
      email: "",
    },
  });
  return (
    <FormProvider {...methods}>
      <PersonalEmailConfirmContext.Provider
        value={[emailConfirm, setEmailConfirm]}
      >
        {props.children}
      </PersonalEmailConfirmContext.Provider>
    </FormProvider>
  );
};
