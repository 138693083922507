import { Box, ExternalLinkIcon, Link, Typography } from "nikkei-ui";
import { useTranslation } from "react-i18next";
import styles from "./ContractDetailsCheck.module.css";

export const ContractDetailsCheck: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Box className={styles.contractDetailsCheck}>
      <Box>
        <Typography variant="h4" bold data-testid="contract-details-check">
          {t("account_top.title.contract_details_check")}
        </Typography>
      </Box>
      <Box>
        <Link
          href="https://regist.nikkei.com/support/"
          className={styles.link}
          data-testid="link-subscription-support"
          newTab
        >
          <Typography as="span" color="link">
            {t("account_top.link.subscription_support")}
          </Typography>
          <ExternalLinkIcon />
        </Link>
      </Box>
    </Box>
  );
};
