import type React from "react";

export const AccountIcon: React.FC<React.ComponentProps<"svg">> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    aria-label="Icon of Menu"
    aria-hidden="true"
    {...props}
  >
    <path
      d="M14.318 14.4837L13.9084 13.8554L13.568 14.0773V14.4837H14.318ZM14.318 15.361H13.568V15.9946L14.1927 16.1004L14.318 15.361ZM20.5 21.7959V22.5459H21.25V21.7959H20.5ZM3.5 21.7959H2.75V22.5459H3.5V21.7959ZM9.68164 15.361L9.80694 16.1005L10.4316 15.9947V15.361H9.68164ZM9.68164 14.4835H10.4316V14.0772L10.0913 13.8552L9.68164 14.4835ZM12 3.7959C9.23858 3.7959 7 6.03447 7 8.7959H8.5C8.5 6.8629 10.067 5.2959 12 5.2959V3.7959ZM17 8.7959C17 6.03447 14.7614 3.7959 12 3.7959V5.2959C13.933 5.2959 15.5 6.8629 15.5 8.7959H17ZM17 10.9209V8.7959H15.5V10.9209H17ZM14.7276 15.112C16.0941 14.2211 17 12.677 17 10.9209H15.5C15.5 12.1489 14.8682 13.2297 13.9084 13.8554L14.7276 15.112ZM15.068 15.361V14.4837H13.568V15.361H15.068ZM21.25 20.4834C21.25 18.9817 20.4698 17.6595 19.256 16.6588C18.045 15.6605 16.3694 14.9478 14.4433 14.6215L14.1927 16.1004C15.9197 16.393 17.3351 17.0193 18.3018 17.8162C19.2657 18.6109 19.75 19.5405 19.75 20.4834H21.25ZM21.25 21.7959V20.4834H19.75V21.7959H21.25ZM3.5 22.5459H20.5V21.0459H3.5V22.5459ZM2.75 20.4834V21.7959H4.25V20.4834H2.75ZM9.55635 14.6216C7.63033 14.9479 5.95485 15.6606 4.74388 16.6589C3.53014 17.6596 2.75 18.9818 2.75 20.4834H4.25C4.25 19.5405 4.73423 18.611 5.69808 17.8163C6.66471 17.0194 8.08005 16.3931 9.80694 16.1005L9.55635 14.6216ZM8.93164 14.4835V15.361H10.4316V14.4835H8.93164ZM7 10.9209C7 12.6768 7.90574 14.2208 9.27198 15.1117L10.0913 13.8552C9.13167 13.2295 8.5 12.1487 8.5 10.9209H7ZM7 8.7959V10.9209H8.5V8.7959H7Z"
      fill="#003E70"
    />
  </svg>
);
