import type React from "react";

import { Box, Link, PasswordStrengthChecker } from "nikkei-ui";
import Button from "nikkei-ui/components/Buttons/Button";
import { useTranslation } from "react-i18next";

import PasswordField from "@/components/ui/Form/PasswordField/PasswordField";
import { useSecurityPasswordChangeInputFeature } from "@/features/Security/Password/Change/hooks/useSecurityPasswordChangeInputFeature";

import { path } from "@/routes";

export const SecurityPasswordChangeInputFeature: React.FC = () => {
  const { t } = useTranslation();
  const {
    register,
    errorMessage,
    disableButton,
    handleSubmit,
    passwordStrengthScore,
    passwordStrengthMessage,
  } = useSecurityPasswordChangeInputFeature();

  const messages = t("security.edit.password_change.input", {
    returnObjects: true,
  });

  return (
    <>
      <Box className="nid-section-inner">
        <form onSubmit={handleSubmit} noValidate>
          <Box className="mb-6">
            <PasswordField
              label={messages.label.password}
              message={errorMessage.password}
              labelVariant="head"
              variant="newPassword"
              placeholder={messages.placeholder.password}
              trailingIconLabelData={{
                on: messages.icon.reveal_password.on,
                off: messages.icon.reveal_password.off,
              }}
              testId="input-new-password"
              data-testid="input-new-password"
              {...register.password}
            >
              <PasswordStrengthChecker
                score={passwordStrengthScore}
                label={t("password_strength_checker.label")}
                validationText={passwordStrengthMessage}
              />
            </PasswordField>
          </Box>
          <Box className="mb-5">
            <PasswordField
              label={messages.label.password_repeat}
              message={errorMessage.passwordRepeat}
              labelVariant="head"
              variant="newPassword"
              placeholder={messages.placeholder.password_repeat}
              trailingIconLabelData={{
                on: messages.icon.reveal_password.on,
                off: messages.icon.reveal_password.off,
              }}
              testId="input-confirm-password"
              {...register.passwordRepeat}
            />
          </Box>

          <Button size="full" disabled={disableButton} data-testid="submit">
            {messages.button.submit}
          </Button>

          <Box className="nid-item-center mt-6">
            <Link
              className="nid-typography-block"
              to={path.security.root}
              data-testid="button-back"
            >
              {messages.button.back}
            </Link>
          </Box>
        </form>
      </Box>
    </>
  );
};
