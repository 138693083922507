import { getCardBrand } from "../getCardBrand";
import { getCardMaxLength, removeSpace, sanitizeCardNumber } from "./common";
import { CreditCardBlocks } from "./type";

/**
 * formatViewCreditCardNumber
 * @param inputValue
 * @returns string
 */
export const formatViewCreditCardNumber = (inputValue: string): string => {
  // Remove all non-digit characters from the card number
  const sanitizeValue = sanitizeCardNumber(inputValue);

  // Get the card brand
  const brand = getCardBrand(sanitizeValue);

  // Get blocks for the card brand
  let block = CreditCardBlocks[brand];

  if (block.length.length === 2) {
    const maxLength = Math.max(...block.length);
    const currentFormatLength = block.format.reduce((sum, len) => sum + len, 0);
    if (currentFormatLength < maxLength) {
      block = {
        format: [...block.format, maxLength - currentFormatLength],
        length: block.length,
      };
    }
  }

  // Get maximum length card number
  const maxLength = getCardMaxLength(block.format);
  const trimmedValue = sanitizeValue.slice(0, maxLength);

  let formattedValue = "";
  let currentIndex = 0;

  block.format.forEach((blockLength, index) => {
    if (currentIndex < trimmedValue.length) {
      if (index > 0) formattedValue += " ";
      formattedValue += trimmedValue.slice(
        currentIndex,
        currentIndex + blockLength,
      );
      currentIndex += blockLength;
    }
  });

  return formattedValue;
};

/**
 * sanitizeCardNumberForPost
 * @param value string
 * @returns string
 */
export const sanitizeCardNumberForPost = (value: string): string => {
  return removeSpace(value);
};
