import { convertFullWidthDigits, extractDigits } from "./common";

/**
 * formatSecurityCode
 * @param value
 * @returns
 */
export const formatSecurityCode = (value: string) => {
  return extractDigits(convertFullWidthDigits(value));
};
