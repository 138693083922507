import type { PathType } from "@/@types/path";

export const enableServiceContract =
  import.meta.env?.VITE_FEATURE_FLAG_SERVICE_CONTRACT === "true";

export const enableSecurityPasskey =
  import.meta.env?.VITE_FEATURE_FLAG_SECURITY_PASSKEY === "true";

const securityPasswordChangePath = {
  root: "/security/password/change",
  complete: "/security/password/change/complete",
};

const securityMfaPath = {
  root: "/security/mfa",
  backupCode: "/security/mfa/backup-code",
  method: "/security/mfa/method",
  complete: "/security/mfa/complete",
  email: {
    root: "/security/mfa/email",
    confirm: "/security/mfa/email/confirm",
  },
  disable: {
    root: "/security/mfa/disable",
    complete: "/security/mfa/disable/complete",
  },
  authenticator: {
    root: "/security/mfa/authenticator",
    confirm: "/security/mfa/authenticator/confirm",
  },
};

const securityPath = enableSecurityPasskey
  ? {
      root: "/security",
      passkeys: {
        root: "/security/passkeys",
        created: "/security/passkeys/created",
      },
      passwordChange: securityPasswordChangePath,
      mfa: securityMfaPath,
    }
  : {
      root: "/security",
      passwordChange: securityPasswordChangePath,
      mfa: securityMfaPath,
    };

export const path: PathType = {
  root: "/",
  error: {
    root: "/error",
    notFound: "/error/not-found",
  },

  personal: {
    root: "/personal",
    emailChange: {
      root: "/personal/email/change",
      confirm: "/personal/email/change/confirm",
      complete: "/personal/email/change/complete",
    },
    info: {
      root: "/personal/info",
      confirm: "/personal/info/confirm",
      complete: "/personal/info/complete",
    },
    address: {
      root: "/personal/address",
      confirm: "/personal/address/confirm",
      complete: "/personal/address/complete",
    },
    jobInfo: {
      root: "/personal/job",
      confirm: "/personal/job/confirm",
      complete: "/personal/job/complete",
    },
    office: {
      root: "/personal/office",
      confirm: "/personal/office/confirm",
      complete: "/personal/office/complete",
    },
    survey: {
      root: "/personal/survey",
      confirm: "/personal/survey/confirm",
      complete: "/personal/survey/complete",
    },
  },

  payments: {
    root: "/payments",
    services: "/payments/services",
    histories: {
      root: "/payments/histories",
      detail: (id: string) => `/payments/histories/${id}`,
    },
    update: {
      service: (id: string) => `/payments/update/service/${id}`,
      card: (id: string) => `/payments/update/card/${id}`,
      complete: "/payments/update/complete",
    },
    delete: {
      root: (id: string) => `/payments/delete/${id}`,
      complete: "/payments/delete/complete",
    },
    maintenance: "/payments/maintenance",
  },

  security: securityPath,

  services: enableServiceContract
    ? {
        root: "/services",
        service: (id: string) => `/services/${id}`,
        contract: {
          root: (id: string) => `/services/${id}/contract`,
          cancel: {
            root: (id: string) => `/services/${id}/contract/cancel`,
            complete: (id: string) =>
              `/services/${id}/contract/cancel/complete`,
            confirm: (id: string) => `/services/${id}/contract/cancel/confirm`,
          },
          maintenance: (id: string) => `/services/${id}/contract/maintenance`,
        },
      }
    : {
        root: "/services",
        service: (id: string) => `/services/${id}`,
      },

  newsletters: {
    root: "/newsletters",
  },

  withdraw: {
    root: "/withdraw",
    complete: "/withdraw/complete",
  },
};
