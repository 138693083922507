import { ArrowRightIcon, Box, Link, Typography } from "nikkei-ui";

import styles from "./Navigation.module.css";

export type NavigationItemState = {
  destinationAfterPaymentsUpdate: string;
};

interface NavigationItemProps {
  label: string;
  href?: string;
  to?: string;
  variant?: "body1" | "subtitle1";
  testId?: string;
  noBorder?: boolean;
  newTab?: boolean;
  state?: NavigationItemState;
}

export const NavigationItem = ({
  label,
  href,
  to,
  variant = "body1",
  testId,
  noBorder,
  newTab,
  state,
}: NavigationItemProps): JSX.Element => {
  return (
    <Link
      href={href}
      to={to}
      className={noBorder ? "nid-body-nav-item--noborder" : styles.navItem}
      data-testid={testId}
      newTab={newTab}
      state={state}
    >
      <Typography as="span" variant={variant} color="link">
        {label}
      </Typography>
      <Box className="nid-nav-icon">
        <ArrowRightIcon />
      </Box>
    </Link>
  );
};
