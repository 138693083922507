import type * as React from "react";

import { t } from "i18next";

import ArticleTitle from "@/components/ui/ArticleTitle/ArticleTitle";
import { SecurityMfaMethodFeature } from "@/features/Security/Mfa/Method/SecurityMfaMethodFeature";
import { LoginWallProvider } from "../../../../../../packages/nid-common";

const SecurityMfaMethodPage: React.FC = () => {
  return (
    <LoginWallProvider maxAge={600}>
      <ArticleTitle
        title={t("security.mfa.method.title")}
        testId="article-security-mfa-method"
      />
      <SecurityMfaMethodFeature />
    </LoginWallProvider>
  );
};

export default SecurityMfaMethodPage;
