import type React from "react";
import { type ReactElement, useEffect } from "react";

import { FormProvider, useForm } from "react-hook-form";

import { type UserInfoPatch, usePatchUserInfo } from "@/hooks/usePatchUserInfo";
import type { LabeledUserInfo } from "@/hooks/useUserInfo";
import { PageSkeletonLoader, useCountriesMaster } from "nid-common";

type PersonalAddressFormProviderProps = {
  children: ReactElement;
};

export type InputUserInfoValues = {
  countryCode: string;
  domestic: {
    zipCode: string;
    addressCode: string;
    address1: string;
    address2: string;
    tel: string;
  };
  overseas: {
    zipCode: string;
    address1: string;
    address2: string;
    address3: string;
    tel: string;
  };
  inputDone: boolean;
};

export const COUNTRY_JAPAN = "000";
export const COUNTRY_UNKNOWN = "0";

export const buildUserInfoPatch = (
  input: InputUserInfoValues,
  current: LabeledUserInfo,
): UserInfoPatch => {
  const countryFlag = input.countryCode === COUNTRY_JAPAN ? "0" : "1";

  return {
    residenceCountryFlag: countryFlag,
    domesticAddress:
      countryFlag === "0"
        ? {
            address1: input.domestic.address1,
            address2: input.domestic.address2.replace(/\r?\n/g, "　"),
            addressCode:
              input.domestic.addressCode !== "0"
                ? input.domestic.addressCode
                : null,
            zipCode:
              input.domestic.zipCode === "" &&
              (current.domesticAddress?.zipCode ?? "") === ""
                ? undefined
                : input.domestic.zipCode,
            tel: input.domestic.tel,
          }
        : undefined,
    overseasAddress:
      countryFlag === "1"
        ? {
            zipCode: input.overseas.zipCode,
            address1: input.overseas.address1.replace(/\r?\n/g, " "),
            address2: input.overseas.address2.replace(/\r?\n/g, " "),
            address3: input.overseas.address3.replace(/\r?\n/g, " "),
            countryCode: input.countryCode,
            tel: input.overseas.tel,
          }
        : undefined,
  };
};

export const PersonalAddressFormProvider: React.FC<
  PersonalAddressFormProviderProps
> = (props) => {
  const { status, userInfo } = usePatchUserInfo();
  const countriesMaster = useCountriesMaster();
  const methods = useForm<InputUserInfoValues>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    shouldUnregister: false,
    defaultValues: {
      countryCode: COUNTRY_JAPAN,
      domestic: {
        zipCode: "",
        addressCode: "",
        address1: "",
        address2: "",
        tel: "",
      },
      overseas: {
        zipCode: "",
        address1: "",
        address2: "",
        address3: "",
        tel: "",
      },
      inputDone: false,
    },
  });

  useEffect(() => {
    if (status !== "ok" || countriesMaster.status !== "ok") return;
    methods.setValue("domestic", {
      zipCode: userInfo.domesticAddress?.zipCode ?? "",
      addressCode: userInfo.domesticAddress?.addressCode ?? "0",
      address1: userInfo.domesticAddress?.address1 ?? "",
      address2: userInfo.domesticAddress?.address2 ?? "",
      tel: userInfo.domesticAddress?.tel ?? "",
    });

    methods.setValue("overseas", {
      zipCode: userInfo.overseasAddress?.zipCode ?? "",
      address1: userInfo.overseasAddress?.address1 ?? "",
      address2: userInfo.overseasAddress?.address2 ?? "",
      address3: userInfo.overseasAddress?.address3 ?? "",
      tel: userInfo.overseasAddress?.tel ?? "",
    });
    methods.setValue(
      "countryCode",
      userInfo.residenceCountryFlag === "1" &&
        userInfo.overseasAddress?.countryCode
        ? userInfo.overseasAddress?.countryCode
        : COUNTRY_JAPAN,
    );
  }, [status, countriesMaster.status]);

  if (status !== "ok") return <PageSkeletonLoader />;
  return <FormProvider {...methods}>{props.children}</FormProvider>;
};
